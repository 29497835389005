import { PageHeader } from "antd";
import LayoutCss from "layout/layout.module.scss";
import { CandidatesContainer } from "./candidatesContainer/candidatesContainer";

export default function Candidates() {
  return (
    <>
      <PageHeader
        ghost={false}
        title="Candidates"
        className={LayoutCss.appPageHeader}
      />
      <CandidatesContainer />
    </>
  );
}
