import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
} from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ScheduledBillings from "redux/models/scheduledBillings";
import { selectSelectedClientIds } from "redux/slices/globalSelectorSlice";
import K from "utilities/constants";
import { columns } from "./jobColumns";
import { ServerSideDataGrid } from "../../../common/components/serverSideDataGrid/serverSideDataGrid";
import User from "../../../redux/models/user";
import { selectUserId } from "../../../redux/slices/userSlice";
import { debounce } from "lodash";
import moment from "moment";

export default function JobScheduledList() {
  const userId = useSelector(selectUserId);
  const clients = useSelector(selectSelectedClientIds);
  const [search, setSearch] = useState(null);

  const gridRef = useRef(null);
  const [form] = Form.useForm();
  const editIdRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsModalVisible] = useState(false);

  const editRecord = (data) => {
    setIsModalVisible(true);
    editIdRef.current = data.id;
    form.setFieldsValue({
      duplicateAmount: data.originalDuplicateAmount,
      dueDate: moment(data.dueDate),
      isActive: data.isActive === "Yes",
    });
  };

  const tableColumns = useMemo(() => columns(editRecord), []);

  const getServerSideData = useCallback(
    (options) => {
      return ScheduledBillings.getScheduledJobBillings({
        ...options,
        search: search ?? undefined,
        client: clients,
      });
    },
    [clients, search],
  );

  const getConfig = useCallback(() => {
    return User.getConfigrations(
      K.AgGridTable.Keys.JobScheduledBillingsVisibleColumn,
    );
  }, []);

  const saveColumnConfig = useCallback(
    (config) => {
      return User.saveColumnSort({
        usersId: userId,
        configJson: JSON.stringify(config),
        tableName: K.AgGridTable.Keys.JobScheduledBillingsVisibleColumn,
      });
    },
    [userId],
  );

  const onDataFetchFailed = useCallback((err) => console.error(err), []);

  const onConfigUpdated = (config) => {
    gridRef.current.api.applyServerSideTransaction({
      update: [config],
    });

    gridRef.current.api.refreshCells({ force: true });
  };

  const onFinish = async (item) => {
    setLoading(true);
    const body = {
      dueDate: item.dueDate.format(K.DateFormat.Display),
      duplicateAmount: item.duplicateAmount,
      isActive: +item.isActive,
    };
    try {
      const res = await ScheduledBillings.updateJobScheduledBilling(
        body,
        editIdRef.current,
      );
      onConfigUpdated(res.data);

      setIsModalVisible(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onSearchChange = useCallback(
    debounce((e) => {
      setSearch(e.target.value || null);
    }, 500),
    [setSearch],
  );

  return (
    <>
      <Card bodyStyle={{ padding: 16 }}>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify={"space-between"} gutter={[8, 8]}>
            <Col style={{ flex: 1 }}>
              <Row gutter={[8, 8]}>
                <Col style={{ width: "100%", maxWidth: 264 }}>
                  <Input.Search
                    allowClear
                    defaultValue={search}
                    placeholder="Search"
                    onChange={onSearchChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <ServerSideDataGrid
            ref={gridRef}
            getData={getServerSideData}
            getColumnConfig={getConfig}
            saveColumnConfig={saveColumnConfig}
            defaultColumnConfig={tableColumns}
            defaultColDef={K.AgGridTable.DefaultColDef}
            onDataFetchFailed={onDataFetchFailed}
          />
        </Space>
      </Card>

      <Modal
        forceRender
        centered
        open={isVisible}
        closeIcon={<i className="icon-closeable"></i>}
        okText="Update"
        className="s2-theme-style"
        title="Edit Job Scheduled"
        wrapClassName="vertical-center-modal"
        okButtonProps={{ loading: loading }}
        onOk={() => form.submit()}
        onCancel={() => setIsModalVisible(false)}
        afterClose={() => form.resetFields()}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Due Date"
            name="dueDate"
            rules={[{ required: true, message: "Due Date is required." }]}
          >
            <DatePicker format={K.DateFormat.DashUSFormat} />
          </Form.Item>

          <Form.Item
            label="Amount"
            name="duplicateAmount"
            rules={[{ required: true, message: "Amount is required." }]}
          >
            <Input placeholder="Enter amount" />
          </Form.Item>
          <Form.Item label="Active?" name="isActive" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
