import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Tabs,
  Typography,
} from "antd";
import { StopOutlined } from "@ant-design/icons";
import LayoutCss from "layout/layout.module.scss";
import Candidate from "redux/models/candidate";
import UserDetailCard from "common/userDetailCard/userDetailCard";
import ViewActivity from "features/candidates/viewActivity";
import { isPermissionPresent } from "utilities/generalUtility";
import K from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "redux/slices/userSlice";
import { setShouldReinitialize } from "redux/slices/candidateSlice";
import UDF from "redux/models/udf";
import { setCandidateUdf } from "redux/slices/udfSlice";
import styles1 from "../clients/create/clientsJob.module.scss";
import styles from "./candidatesDetails.module.scss";
import { ActivityTab } from "./activityTab";
import { CandidateJobEntryCard } from "./jobCard";

const { TextArea } = Input;
const { Title, Text } = Typography;

export default function CandidatesDetails() {
  const dispatch = useDispatch();
  const jobId = useRef(null);
  const jobDetailRef = useRef(null);
  const { state } = useLocation();
  const { id } = useParams();
  const history = useHistory();
  const [reasonForm] = Form.useForm();
  const [candidateForm] = Form.useForm();
  const [candidateDetail, setCandidateDetail] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [candidateActivity, setCandidateActivity] = useState(null);
  const userSlice = useSelector(selectUser);

  const defaultActiveTab = state?.tab ?? "profile";
  const defaultActiveJobTab = state?.jobTab ?? "details";
  const defaultJobEntryId = state?.candidateJobEntryId;

  const defaultCandidateJobEntry = candidateDetail?.candidateJobEntries?.find(
    (e) => e.id === +defaultJobEntryId,
  );

  const handleCancelReasonModal = () => {
    setIsModalVisible(false);
    reasonForm.resetFields();
  };
  const handleOkReasonModal = () => {
    reasonForm.submit();
    setIsModalVisible(false);
  };

  const getCandidateDetailById = async (candidateId) => {
    try {
      const res = await Candidate.getCandidateDetail(candidateId);
      const { firstName, lastName, phoneNumber: phone, ...rest } = res;
      setCandidateDetail({
        ...rest,
        firstName,
        lastName,
        phone,
        name: [firstName, lastName].join(" "),
      });
      reasonForm.setFieldValue("id", candidateId);
    } catch (error) {
      message.error(error.message);
    }
  };

  const addToBlacklist = async (data) => {
    try {
      const res = await Candidate.addToBlacklist(data);
      setCandidateDetail((prev) => ({
        ...prev,
        isBlacklist: res.isBlacklist,
        blacklistReason: res.blacklistReason,
        id: res.id,
      }));
      reasonForm.resetFields();
    } catch (error) {
      reasonForm.resetFields();
      console.error("error", error);
    }
  };

  const removeToBlacklist = async () => {
    try {
      const res = await Candidate.removeToBlacklist({
        id,
      });
      setCandidateDetail((prev) => ({ ...prev, isBlacklist: res.isBlacklist }));
      reasonForm.resetFields();
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    getCandidateDetailById(id);
  }, [id]);

  const getCandidateUdfs = async (entityKeyId = null) => {
    const candidateUdfs = await UDF.getEditUdfFields({
      clientId: candidateActivity.jobSource.clientsId,
      entityId: K.UDF.Entities.Candidate.id,
      entityKey: K.UDF.Entities.Candidate.key,
      entityKeyId,
      isVisible: 1,
    });

    dispatch(setCandidateUdf(candidateUdfs));
    return candidateUdfs;
  };

  const fetchCandidateDetails = async (jobEntryId, jobSourceId, vacancies) => {
    jobId.current = jobSourceId;
    jobDetailRef.current = { vacancies: vacancies?.at(-1) };
    try {
      const res = await Candidate.getActivity(jobEntryId);

      setCandidateActivity(res);
      dispatch(setShouldReinitialize());
    } catch (err) {
      message.error("Failed to fetch candidate!");
      console.error(err);
    }
  };

  useEffect(() => {
    if (defaultCandidateJobEntry) {
      fetchCandidateDetails(
        defaultCandidateJobEntry.id,
        defaultCandidateJobEntry.jobSource.id,
        defaultCandidateJobEntry.jobSource.vacancies,
      );
    }
  }, [defaultCandidateJobEntry]);

  useEffect(() => {
    window.history.replaceState({}, "");
  }, []);

  return (
    candidateDetail && (
      <>
        <PageHeader
          ghost={false}
          className={LayoutCss.appPageHeader}
          onBack={history.goBack}
          title={candidateDetail.name}
          extra={
            candidateDetail.isBlacklist === 0 ? (
              <Button
                type="ghost"
                icon={<StopOutlined />}
                onClick={() => setIsModalVisible(true)}
              >
                Add to Blacklist
              </Button>
            ) : (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={removeToBlacklist}
                okText="Yes"
                cancelText="No"
              >
                <Button type="ghost" icon={<StopOutlined />}>
                  Remove from Blacklist
                </Button>
              </Popconfirm>
            )
          }
        />
        <div className={styles.candidateDetailsWrapper}>
          <UserDetailCard
            styles={styles}
            userDetail={candidateDetail}
            setCandidateDetail={setCandidateDetail}
            isEditUserInfo
            onMergeCallback={getCandidateDetailById}
          />
          <Card
            className={styles.candidateInfoCard}
            bodyStyle={{ padding: "4px 0 1.5rem" }}
          >
            <Tabs
              defaultActiveKey={defaultActiveTab}
              className="candidates-info-card"
              tabBarGutter={40}
              items={[
                {
                  key: "profile",
                  permission: K.Permissions.ViewCandidateProfile,
                  label: <span className="cic-tabs-label-style">Profile</span>,
                  children: (
                    <div className={styles.blacklistAlert}>
                      <div
                        className={styles.cicTabsContent}
                        style={{ flex: 1 }}
                      >
                        <Title level={4} className={styles.personalInfoHeading}>
                          Personal Info
                        </Title>
                        <Row gutter={24} className="mb-3">
                          <Col xs={24} sm={12} xl={9}>
                            <label className={styles.icLabelStyle}>
                              Full Name
                            </label>
                            <Text className={styles.icTextStyle}>
                              {candidateDetail.name}
                            </Text>
                          </Col>
                        </Row>
                        <Row gutter={24} className="mb-3">
                          <Col xs={24} sm={12} xl={9}>
                            <label className={styles.icLabelStyle}>
                              Home Address
                            </label>
                            <Text
                              title={candidateDetail.location}
                              className={styles.icTextStyle}
                            >
                              {candidateDetail.location}
                            </Text>
                          </Col>
                        </Row>
                        <Row gutter={24} className="mb-3">
                          <Col xs={24} sm={12} xl={9}>
                            <label className={styles.icLabelStyle}>
                              Current Title
                            </label>
                            <Text
                              title={
                                candidateDetail.currentTitle ??
                                K.NullPlaceholder
                              }
                              className={styles.icTextStyle}
                            >
                              {candidateDetail.currentTitle ??
                                K.NullPlaceholder}
                            </Text>
                          </Col>
                        </Row>
                      </div>
                      {candidateDetail.isBlacklist ? (
                        <div>
                          <Alert
                            showIcon
                            type="warning"
                            className="warningAlert"
                            message="Warning: Candidate is blacklisted. "
                            description={`Reason: ${
                              candidateDetail.blacklistReason ??
                              K.NullPlaceholder
                            } `}
                          />
                        </div>
                      ) : null}
                    </div>
                  ),
                },
                {
                  key: "jobs",
                  label: <span className="cic-tabs-label-style">Jobs</span>,
                  permission: K.Permissions.ViewCandidateJobs,
                  children: (
                    <div
                      className={`cic-tabs-content ${styles.cicTabsContent}`}
                    >
                      {candidateDetail?.candidateJobEntries?.map((jobEntry) => (
                        <CandidateJobEntryCard
                          key={jobEntry.id}
                          jobEntry={jobEntry}
                          isActive={jobEntry.id === candidateActivity?.id}
                          onClick={() =>
                            fetchCandidateDetails(
                              jobEntry.id,
                              jobEntry.jobSource.id,
                              jobEntry.jobSource.vacancies,
                            )
                          }
                          styles={styles}
                        />
                      ))}
                    </div>
                  ),
                },
                {
                  key: "activities",
                  label: <span className="cic-tabs-label-style">Activity</span>,
                  permission: K.Permissions.ViewCandidateProfile,
                  children: (
                    <div
                      className={`cic-tabs-content ${styles.cicTabsContent}`}
                    >
                      <ActivityTab candidateId={candidateDetail.id} />
                    </div>
                  ),
                },
              ].filter(({ permission }) =>
                isPermissionPresent(permission, userSlice.roles),
              )}
            />
          </Card>
          <Modal
            className={`s2-theme-style ${styles1.ReasonForMoving}`}
            title="Reason for blacklist"
            open={isModalVisible}
            centered
            onCancel={handleCancelReasonModal}
            closeIcon={<i className={`icon-closeable ${styles1.closeAble}`} />}
            footer={[
              <Button key="1" onClick={handleCancelReasonModal}>
                Cancel
              </Button>,
              <Button key="2" type="primary" onClick={handleOkReasonModal}>
                Submit
              </Button>,
            ]}
          >
            <Form
              form={reasonForm}
              layout="vertical"
              className={styles1.ReasonForMovingFrom}
              onFinish={addToBlacklist}
            >
              <Form.Item name="id" initialValue={id} hidden>
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Reason"
                name="reason"
                className={styles1.rfmFields}
              >
                <TextArea
                  showCount
                  maxLength={500}
                  placeholder="Reason"
                  className={`${styles1.rfmInput} ${styles1.rfmTextArea}`}
                  autoSize={{ minRows: 5, maxRows: 6 }}
                />
              </Form.Item>
            </Form>
          </Modal>
        </div>
        {candidateActivity && (
          <div
            className={`candidate-view-tabs ${styles.candidateViewActivity}`}
          >
            <ViewActivity
              jobId={jobId.current}
              jobDetails={jobDetailRef.current}
              styles={styles1}
              showEditIcon={false}
              candidateForm={candidateForm}
              candidateActivity={candidateActivity}
              setCandidateDetail={setCandidateDetail}
              setCandidateActivity={setCandidateActivity}
              getCandidateUdfs={getCandidateUdfs}
              defaultActiveTab={defaultActiveJobTab}
            />
          </div>
        )}
      </>
    )
  );
}
