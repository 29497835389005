import { CheckOutlined, SwapOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Form, Input, Row } from "antd";
import { useState } from "react";
import styles from "./mergeForm.module.scss";
import { PALETTE } from "../../utilities/palette";

const POSITION = {
  LEFT: "left",
  RIGHT: "right",
};

function Field({
  label,
  field,
  position,
  leftValue,
  rightValue,
  handleFieldClick,
}) {
  return (
    <Row
      align="middle"
      gutter={16}
      style={{ justifyContent: "space-between", marginBottom: "20px" }}
    >
      <Col style={{ width: "140px" }}>
        <strong>{label}</strong>
      </Col>
      <Col style={{ width: "330px" }}>
        <Input
          className={styles.customFormItem}
          value={leftValue || "-"}
          readOnly
          onClick={() => handleFieldClick(field, POSITION.LEFT)}
          style={{
            cursor: "pointer",
            borderColor:
              position === POSITION.LEFT ? PALETTE.SUCCESS : undefined,
            outline: "none",
            boxShadow: "none",
          }}
          suffix={
            position === POSITION.LEFT && (
              <CheckOutlined style={{ color: PALETTE.SUCCESS }} />
            )
          }
        />
      </Col>
      <Col style={{ width: "330px" }}>
        <Input
          className={styles.customFormItem}
          value={rightValue || "-"}
          readOnly
          onClick={() => handleFieldClick(field, POSITION.RIGHT)}
          style={{
            cursor: "pointer",
            borderColor:
              position === POSITION.RIGHT ? PALETTE.SUCCESS : undefined,
            outline: "none",
            boxShadow: "none",
          }}
          suffix={
            position === POSITION.RIGHT && (
              <CheckOutlined style={{ color: PALETTE.SUCCESS }} />
            )
          }
        />
      </Col>
    </Row>
  );
}

export default function MergeForm({ form, leftEntity, rightEntity, onMerge }) {
  const [selectedFields, setSelectedFields] = useState({
    firstName: POSITION.LEFT,
    lastName: POSITION.LEFT,
    email: POSITION.RIGHT,
    mobileNumber: POSITION.LEFT,
    phoneNumber: POSITION.LEFT,
    location: POSITION.LEFT,
    currentTitle: POSITION.LEFT,
    isBlacklist: POSITION.LEFT,
    avatar: POSITION.LEFT,
  });

  const handleFieldClick = (field, side) => {
    setSelectedFields((prevState) => ({
      ...prevState,
      [field]: side,
    }));
  };

  const onFinish = () => {
    const result = Object.keys(selectedFields).reduce((acc, key) => {
      acc[key] =
        selectedFields[key] === POSITION.LEFT
          ? leftEntity[key]
          : rightEntity[key];
      return acc;
    }, {});
    onMerge(result);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={29} align="middle">
        <Col style={{ width: "168px" }} />
        <Col style={{ padding: "0 10px 0 17px" }}>
          <Form.Item label="Source Email" style={{ width: "315px" }}>
            <Input readOnly defaultValue={leftEntity?.email} />
          </Form.Item>
        </Col>
        <Col style={{ padding: 0, width: 22 }}>
          <SwapOutlined
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              marginTop: "5px",
            }}
          />
        </Col>
        <Col style={{ padding: "0 12px 0 10px" }}>
          <Form.Item label="Destination Email" style={{ width: "315px" }}>
            <Input readOnly defaultValue={rightEntity?.email} />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="center" style={{ marginBottom: 24 }}>
        <Col span={6} />
        <Col
          span={9}
          align="middle"
          style={{
            height: "110px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            onClick={() => handleFieldClick("avatar", POSITION.LEFT)}
            style={{
              width: "100px",
              cursor: "pointer",
              border:
                selectedFields.avatar === POSITION.LEFT
                  ? `2px solid ${PALETTE.SUCCESS}`
                  : "none",
              borderRadius: "50%",
            }}
          >
            <Avatar size={96} icon={<UserOutlined />} />
          </div>
        </Col>
        <Col
          span={9}
          align="middle"
          style={{
            height: "110px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            onClick={() => handleFieldClick("avatar", POSITION.RIGHT)}
            style={{
              width: "100px",
              cursor: "pointer",
              border:
                selectedFields.avatar === POSITION.RIGHT
                  ? `2px solid ${PALETTE.SUCCESS}`
                  : "none",
              borderRadius: "50%",
            }}
          >
            <Avatar size={96} icon={<UserOutlined />} />
          </div>
        </Col>
      </Row>

      <Field
        label="First Name"
        field="firstName"
        leftValue={leftEntity?.firstName}
        rightValue={rightEntity?.firstName}
        position={selectedFields.firstName}
        handleFieldClick={handleFieldClick}
      />
      <Field
        label="Last Name"
        field="lastName"
        leftValue={leftEntity?.lastName}
        rightValue={rightEntity?.lastName}
        position={selectedFields.lastName}
        handleFieldClick={handleFieldClick}
      />
      <Field
        label="Email"
        field="email"
        leftValue={leftEntity?.email}
        rightValue={rightEntity?.email}
        position={selectedFields.email}
        handleFieldClick={handleFieldClick}
      />
      <Field
        label="Mobile Number"
        field="mobileNumber"
        leftValue={leftEntity?.mobileNumber}
        rightValue={rightEntity?.mobileNumber}
        position={selectedFields.mobileNumber}
        handleFieldClick={handleFieldClick}
      />
      <Field
        label="Additional Phone"
        field="phoneNumber"
        leftValue={leftEntity?.phoneNumber}
        rightValue={rightEntity?.phoneNumber}
        position={selectedFields.phoneNumber}
        handleFieldClick={handleFieldClick}
      />
      <Field
        label="Home Address"
        field="location"
        leftValue={leftEntity?.location}
        rightValue={rightEntity?.location}
        position={selectedFields.location}
        handleFieldClick={handleFieldClick}
      />
      <Field
        label="Current Title"
        field="currentTitle"
        leftValue={leftEntity?.currentTitle}
        rightValue={rightEntity?.currentTitle}
        position={selectedFields.currentTitle}
        handleFieldClick={handleFieldClick}
      />
      <Field
        label="Blacklist"
        field="isBlacklist"
        leftValue={leftEntity?.isBlacklist ? "Yes" : "No"}
        rightValue={rightEntity?.isBlacklist ? "Yes" : "No"}
        position={selectedFields.isBlacklist}
        handleFieldClick={handleFieldClick}
      />
    </Form>
  );
}
