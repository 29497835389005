import { ServerSideDataGrid } from "../../../../../common/components/serverSideDataGrid/serverSideDataGrid";
import { useCallback, useMemo, useRef, useState } from "react";
import User from "../../../../../redux/models/user";
import { debounce } from "lodash";
import useSearchAndFilter from "../../../../../common/customHook/useSearchAndFilter";
import { useSelector } from "react-redux";
import AgGridUtility from "../../../../../utilities/agGridUtility";
import {
  selectUser,
  selectUserId,
} from "../../../../../redux/slices/userSlice";
import Client from "../../../../../redux/models/client";
import { Card, Col, Input, Row, Space } from "antd";
import { getColumns, DefaultColDef } from "./columns";
import K from "../../../../../utilities/constants";
import {
  camelToSnakeCase,
  isPermissionPresent,
} from "../../../../../utilities/generalUtility";
import { StatusSelector } from "./statusSelector";
import { ExportButton } from "./exportButton/exportButton";
import { UploadCsvButton } from "./uploadScvButton/uploadCsvButton";
import { CreateJobButton } from "./createJobButton/createJobButton";
import { useHistory } from "react-router-dom";

const jobToTableRow = (job) => ({
  id: job.id,
  title: job.title,
  totalOpenings: job.vacancies[0]?.currentOpenings ?? 0,
  jobLocation: job.jobLocation,
  recruitingStartDate: job.recruitingStartDate,
  jobRecruiters: job.jobRecruiters.map((e) => e.recruiter),
  atsLookUp: job.atsLookUp,
  atsLookUpSecondary: job.atsLookUpSecondary,
  jobStatus: job.jobStatus,
  jobDepartment: job.jobDepartment,
  jobGroup: job.jobGroup,
  extAtsId: job.extAtsId,
  jobHiringManagers: job.jobHiringManagers.map((e) => e.hiringManager),
  jobTier: job.jobTier,
  jobTiersTitlesId: job.jobTiersTitlesId,
  extSecondaryAtsId: job.extSecondaryAtsId,
  minPay: job.minPay,
  maxPay: job.maxPay,
  isCancel: job.isCancel,
  isWorkFromHome: job.isWorkFromHome,
});

export const ClientJobsContainer = ({ clientId }) => {
  const history = useHistory();
  const userId = useSelector(selectUserId);
  const userSlice = useSelector(selectUser);

  const [setter, getter] = useSearchAndFilter();

  const searchFilterPrefix = `client_${clientId}_jobs`;
  const getSearchKey = `client_${clientId}_jobs_search`;
  const getStatusKey = `client_${clientId}_jobs_status`;

  const search = getter(getSearchKey);
  const status = getter(getStatusKey);
  const statusId = status?.value;

  const gridRef = useRef(null);

  const [clientJobs, setClientJobs] = useState([]);

  const [exportData, setExportData] = useState({
    jobIds: [],
    isDisabled: true,
  });

  const getClientDetailsJobs = async (body) => {
    try {
      const res = await Client.getClientJobsByServerSidePagination(body);
      return { data: res.jobs.map(jobToTableRow), total: res.totalCount };
    } catch (error) {
      console.error(error);
    }
  };

  const getServerSideData = useCallback(
    (options) => {
      const body = {
        ...options,
        clientId,
        filter: { jobStatusId: statusId ?? null },
        search: search || undefined,
        sortBy: options.sortBy ? camelToSnakeCase(options.sortBy) : undefined,
      };

      return getClientDetailsJobs(body);
    },
    [clientId, search, statusId],
  );

  const getConfig = useCallback(() => {
    return User.getConfigrations(K.AgGridTable.Keys.ClientJobsVisibleColumn);
  }, []);

  const saveColumnConfig = useCallback(
    (config) => {
      return User.saveColumnSort({
        usersId: userId,
        configJson: JSON.stringify(config),
        tableName: K.AgGridTable.Keys.ClientJobsVisibleColumn,
      });
    },
    [userId],
  );

  const onSearchChange = useCallback(
    debounce((e) => {
      setter({ search: e.target.value || null }, searchFilterPrefix);
    }, 500),
    [setter],
  );

  const onStatusChange = (statusId, status) => {
    setter({ status: status }, searchFilterPrefix);
  };

  const onSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    setExportData((prev) => ({
      ...prev,
      isDisabled:
        selectedRows.length === 0 &&
        !event.api.getServerSideSelectionState().selectAll,
      jobIds: selectedRows.map(({ id }) => id),
    }));
  };

  const onDataFetchSuccess = useCallback(
    (data) => {
      setClientJobs(data.rowData);
    },
    [setClientJobs],
  );

  const onJobCreated = (job) => {
    AgGridUtility.onServerSideRowCreated(
      gridRef.current?.api,
      jobToTableRow(job),
    );
  };

  const onJobUpdated = (job) => {
    AgGridUtility.onServerSideRowUpdated(
      gridRef.current?.api,
      jobToTableRow(job),
    );
  };

  const onJobDeleted = (job) => {
    AgGridUtility.onServerSideRowDeleted(gridRef.current?.api, job.id);
  };

  const columns = useMemo(
    () => getColumns(clientId, userSlice.roles, onJobUpdated, onJobDeleted),
    [clientId, userSlice.roles],
  );

  const onCellClicked = (params) => {
    if (params.colDef.field === "actions") {
      return;
    }

    history.push(`/clients/${clientId}/job/${params.data.id}`);
  };

  return (
    <>
      <Card bodyStyle={{ padding: 16 }}>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Row justify={"space-between"} gutter={[8, 8]}>
            <Col style={{ flex: 1 }}>
              <Row gutter={[8, 8]}>
                <Col style={{ width: "100%", maxWidth: 264, minWidth: 200 }}>
                  <Input.Search
                    allowClear
                    placeholder="Search"
                    defaultValue={search}
                    onChange={onSearchChange}
                  />
                </Col>
                <Col style={{ width: "100%", minWidth: 200, maxWidth: 200 }}>
                  <StatusSelector
                    defaultValue={status}
                    onChange={onStatusChange}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={8}>
                <Col>
                  <ExportButton
                    clientId={clientId}
                    jobIds={exportData.jobIds}
                    jobStatus={statusId}
                    disabled={exportData.isDisabled}
                    onSuccess={() => gridRef.current?.api?.deselectAll()}
                  />
                </Col>
                {isPermissionPresent(
                  K.Permissions.CreateJob,
                  userSlice.roles,
                ) && (
                  <Col>
                    <CreateJobButton
                      clientId={clientId}
                      onSuccess={onJobCreated}
                    />
                  </Col>
                )}
                {isPermissionPresent(
                  K.Permissions.UploadJobCSV,
                  userSlice.roles,
                ) && (
                  <Col>
                    <UploadCsvButton
                      clientId={clientId}
                      disabled={!clientJobs.length}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <ServerSideDataGrid
            ref={gridRef}
            rowSelection="multiple"
            onSelectionChanged={onSelectionChanged}
            getData={getServerSideData}
            getColumnConfig={getConfig}
            saveColumnConfig={saveColumnConfig}
            onDataFetchSuccess={onDataFetchSuccess}
            defaultColumnConfig={columns}
            defaultColDef={DefaultColDef}
            rowStyle={{ cursor: "pointer" }}
            onCellClicked={onCellClicked}
            suppressRowClickSelection
          />
        </Space>
      </Card>
    </>
  );
};
