import { Button, Form, InputNumber, Spin, Typography } from "antd";

import K from "utilities/constants";
import { AgGridReact } from "ag-grid-react";
import {
  amountRenderer,
  createdAtRenderer,
  duplicateAmountRenderer,
  isManualHeaderProperties,
  nullRenderer,
} from "./tableColumns";
import { useEffect, useRef } from "react";
import BillingApproval from "redux/models/billingApproval";
import { displayDollar, dollarformatter } from "utilities/tableUtility";

const { Text } = Typography;

const candidateHireTypes = {
  applied_hire: "amount",
  source_hired: "amount",
  cancellations: "amount",
  hiring_event: "amount",
  referral: "referral",
  re_hired: "reHired",
  re_hire_referral: "reHired",
  re_hire_applied: "reHired",
  re_hire_sourced: "reHired",
};
export default function OfferedSalaryCalculations({
  pageStates,
  setPageStates,
  reCalculateBilling,
  salaryForm,
  rowData,
  setRowData,
}) {
  const gridRef = useRef();

  const isManualHeader = {
    ...isManualHeaderProperties,
    checkboxSelection: false,
    headerCheckboxSelection: false,
  };

  const jobBillingColumns = [
    {
      headerName: "Auto/Manual",
      field: "isManual",
      sortable: true,
      ...isManualHeader,
    },
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Billing Type",
      field: "billingTypeName",
      sortable: true,
    },
    {
      headerName: "Candidate Name",
      field: "candidateName",
      sortable: true,
      ...nullRenderer,
    },
    {
      headerName: "Job Title",
      field: "jobTitle",
      sortable: true,
    },
    {
      headerName: "Create Date",
      field: "createdAt",
      sortable: true,
      ...createdAtRenderer,
    },
    {
      headerName: "Original Amount",
      field: "amount",
      sortable: true,
      ...amountRenderer,
    },
    {
      headerName: "Billed Amount",
      field: "duplicateAmount",
      sortable: true,
      ...duplicateAmountRenderer,
    },
    {
      headerName: "ATS",
      field: "atsLookUpName",
      sortable: true,
      ...nullRenderer,
    },

    {
      headerName: "ATS ID",
      field: "extAtsId",
      sortable: true,
      ...nullRenderer,
    },
    {
      headerName: "Job Location",
      field: "jobLocationName",
      sortable: true,
      ...nullRenderer,
    },

    {
      headerName: "Job Department",
      field: "jobDepartmentName",
      sortable: true,
      ...nullRenderer,
    },
    {
      headerName: "Job Group",
      field: "jobGroupName",
      sortable: true,
      ...nullRenderer,
    },
    {
      headerName: "Offered Salary",
      // sortable: true,
      minWidth: 220,

      cellRenderer: (params) => (
        <div style={{ display: "flex", marginTop: "5px" }}>
          {/* <div> */}
          <Form
            layout="horizontal"
            form={salaryForm}
            onFinish={runTimeCalculations}
            scrollToFirstError
          >
            <Form.Item
              style={{ width: 100 }}
              key={params.data.candidateJobEntryId}
              name={params.data.candidateJobEntryId + "-offerSalary"}
            >
              <InputNumber
                placeholder={"Enter salary"}
                min={1}
                formatter={dollarformatter}
              />
            </Form.Item>
            <Form.Item
              hidden={true}
              key={params.data.candidateJobEntryId}
              name={"candidateJobEntryId"}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              hidden
              key={params.data.billingRuleEntryId}
              name={"billingRuleEntryId"}
            >
              <InputNumber />
            </Form.Item>
          </Form>
          &nbsp; &nbsp;
          <Button
            type="primary"
            onClick={() => {
              salaryForm.setFieldsValue({
                candidateJobEntryId: params.data.candidateJobEntryId,
                billingRuleEntryId: params.data.billingRuleEntryId,
              });
              salaryForm.submit();
            }}
          >
            Update
          </Button>
        </div>
      ),
    },
    {
      headerName: "Updated Bill Rate",
      field: "updatedBill",
      sortable: true,

      cellRenderer: (params) =>
        [undefined, 0].includes(params.data.updatedBill)
          ? K.NullPlaceholder
          : displayDollar(params.data.updatedBill),
    },
    {
      headerName: "Submit",
      sortable: true,
      cellRenderer: (params) => (
        <div style={{ display: "flex", marginTop: "5px" }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              reCalculateBilling(
                params.data.candidateJobEntryId,
                params.data.billingRuleEntryId,
                params.data.enteredSalary,
              );
              setPageStates((prev) => ({
                ...prev,
                loading: false,
                isOfferedSalaryModalVisible: false,
              }));
            }}
          >
            Submit
          </Button>
        </div>
      ),
    },
  ];
  useEffect(() => {
    setPageStates((prev) => {
      return { ...prev, loading: false };
    });
  }, [pageStates.checkedRows]);

  const runTimeCalculations = async ({
    candidateJobEntryId,
    billingRuleEntryId,
    ...rest
  }) => {
    try {
      const salary = rest[`${candidateJobEntryId}-offerSalary`];
      const response = await BillingApproval.submitRunTimeCalculation(
        billingRuleEntryId,
        candidateJobEntryId,
        salary,
      );

      const updatedRowData = [...rowData].map((item) => {
        if (item.candidateJobEntryId === candidateJobEntryId) {
          return {
            ...item,
            enteredSalary: salary,
            updatedBill: response?.find(
              (element) =>
                item.billingRuleEntryId === element.billingRuleEntryId &&
                item.candidateJobEntryId === candidateJobEntryId,
            )?.data.result[candidateHireTypes[item.hireType]],
          };
        }
        return item;
      });

      setRowData([...updatedRowData]);
    } catch (error) {
      console.log("Error ", error);
    }
  };

  return (
    <>
      <Spin className="section-spinner" spinning={pageStates.loading}>
        <Text>
          You have selected one or more candidates that have a percentage-based
          hiring fee and the offered salary has not been entered. Review the
          list below and recalculate the billable rate if the offered salary is
          available.
        </Text>

        <div className="ag-theme-alpine s2-theme-style" style={{ height: 200 }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={jobBillingColumns}
            ref={gridRef}
            defaultColDef={K.AgGridTable.DefaultColDef}
          />
        </div>
      </Spin>
    </>
  );
}
