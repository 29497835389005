import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import UserSelectOption from "common/components/userSelect/userSelect";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Client from "redux/models/client";
import User from "redux/models/user";
import { selectUdf, setJobSourceUdf } from "redux/slices/udfSlice";
import K from "utilities/constants";
import { convertDateStringToMoment } from "utilities/dateUtility";
import {
  customUserHandleSearch,
  generateUdfSetFieldValues,
  generateUdfValuesObject,
  getIsVisibleLookups,
  noTrailingSpaceAllowedRule,
  removeUdfKeysFromObject,
} from "utilities/generalUtility";
import DisplayUDF from "../userDefinedFields/displayUdf";
import { lookupTablesForJobs } from "../../../../config/lookupTables";
import LookupTable from "../../../../redux/models/lookupTable";
import UDF from "../../../../redux/models/udf";
import { selectConfigration } from "../../../../redux/slices/configrationSlice";

const { Option } = Select;

export default function JobForm({ form, clientId, editData, ...props }) {
  const dispatch = useDispatch();
  const lookupTables = useSelector(selectConfigration).lookup;

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [jobTiers, setJobTiers] = useState([]);
  const udfSlice = useSelector(selectUdf);
  const [users, setUsers] = useState({ "Hiring Manager": [], Recruiter: [] });

  const shouldUpdate = (prevValues, currValues) => {
    if (prevValues.jobTiersId !== currValues.jobTiersId && !editData)
      form.resetFields(["jobTiersTitlesId"]);
    return prevValues.jobTiersId !== currValues.jobTiersId;
  };

  const getJobUdfs = async (entityKeyId = null) => {
    const jobUdfs = await UDF.getEditUdfFields(
      {
        clientId: clientId,
        entityId: K.UDF.Entities.JobSource.id,
        entityKey: K.UDF.Entities.JobSource.key,
        entityKeyId: entityKeyId,
        isVisible: 1,
      },
      false,
    );

    dispatch(setJobSourceUdf(jobUdfs));
  };

  const onFinish = async (values) => {
    const udfValues = generateUdfValuesObject(
      udfSlice[K.Redux.JobSourceUdf],
      values,
    );
    const updatedValues = removeUdfKeysFromObject(
      values,
      udfSlice[K.Redux.JobSourceUdf],
    );

    values = { ...updatedValues, udfValues: udfValues };

    props.onFinish?.(values);
  };

  const validateStartingFields = (fromValue, to) => {
    const toValue = form.getFieldValue(to);
    if (!fromValue && toValue) {
      return Promise.reject("Enter Min Pay!");
    } else if (parseInt(fromValue) > parseInt(toValue)) {
      return Promise.reject("Value should be less than `Max Pay` field");
    } else {
      return Promise.resolve();
    }
  };
  const validateEndingFields = (toValue, from) => {
    const fromValue = form.getFieldValue(from);
    if (!toValue && fromValue) {
      return Promise.reject("Enter Max Pay!");
    } else if (parseInt(fromValue) > parseInt(toValue)) {
      return Promise.reject("Value should be greater than `Min Pay` field");
    } else {
      return Promise.resolve();
    }
  };

  const formatter = (value) =>
    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const getClientTiers = async () => {
    try {
      const res = await Client.getTiersById(clientId, false);
      setJobTiers(res);
    } catch (err) {
      console.error(err);
    }
  };

  const getClientHiringManager = async () => {
    try {
      const body = { clientIds: [clientId] };
      const res = await User.getClientHiringManager(body, false);
      setUsers((prev) => ({ ...prev, ["Hiring Manager"]: res }));
    } catch (err) {
      console.error(err);
    }
  };

  const getClientRecruiter = async () => {
    try {
      const body = {
        client_ids: [clientId] ?? [],
      };
      const recruiter = await Client.getRecruiterByClientsId(body, false);

      setUsers((prev) => ({ ...prev, Recruiter: recruiter }));
    } catch (err) {
      console.error(err);
    }
  };

  const fetchData = async () => {
    try {
      setIsDataLoading(true);

      await Promise.all([
        getClientHiringManager(),
        getClientTiers(),
        getClientRecruiter(),
        getJobUdfs(editData?.id),
        ...lookupTablesForJobs.map(async (item) => {
          let apiEndPoint = item.isClient
            ? item.apiEndPoint + `/${clientId}`
            : item.apiEndPoint;

          await dispatch(
            LookupTable.getData(item.reduxKey, apiEndPoint, [], false, false),
          );
        }),
      ]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (editData) {
      const udfEditData = generateUdfSetFieldValues(
        udfSlice[K.Redux.JobSourceUdf],
      );
      form.setFieldsValue({
        title: editData?.title,
        jobTiersId: editData?.jobTier.id,
        jobTiersTitlesId: editData?.jobTier?.jobTierTitle?.find(
          (item) => item?.jobTitle?.id === editData?.jobTiersTitlesId,
        )?.jobTitle?.id,
        managerId: editData?.jobHiringManagers?.map((item) => item.id),
        jobRecruiters: editData?.jobRecruiters?.map((item) => item.id),
        jobLocationsId: editData?.jobLocation?.id,
        recruitingStartDate: convertDateStringToMoment(
          editData?.recruitingStartDate,
        ),
        atsLookupId: editData?.atsLookUp?.id,
        atsLookupSecondaryId: editData?.atsLookUpSecondary?.id,
        extAtsId: editData?.extAtsId,
        extSecondaryAtsId: editData?.extSecondaryAtsId,
        jobStatusesId: editData?.jobStatus?.id,
        jobDepartmentsId: editData?.jobDepartment?.id,
        jobGroupsId: editData?.jobGroup?.id,
        jobOriginalOpenings: editData?.vacancies?.map(
          (item) => item.totalOpenings,
        ),
        maxPay: editData?.maxPay,
        minPay: editData?.minPay,
        isWorkFromHome: editData?.isWorkFromHome,
        ...udfEditData,
      });
    }
  }, [editData, udfSlice]);

  return (
    <Spin spinning={isDataLoading}>
      <Form
        disabled={isDataLoading}
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          isWorkFromHome: false,
        }}
      >
        <Row gutter={28}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                { required: true, message: "Please enter job title!" },
                noTrailingSpaceAllowedRule(),
              ]}
            >
              <Input placeholder="Enter Job Title" className="modalInput" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Billing Tier"
              name="jobTiersId"
              rules={[{ required: true, message: "Please select job tier!" }]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="Billing Tier"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {jobTiers.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item shouldUpdate={shouldUpdate}>
              {({ getFieldValue }) => {
                const jobTierTitles =
                  jobTiers
                    .find(({ id }) => id === getFieldValue("jobTiersId"))
                    ?.jobTierTitle?.sort((a, b) =>
                      a.job_title.name.localeCompare(b.job_title.name),
                    ) || [];
                return (
                  <Form.Item
                    label="Associated Title"
                    name="jobTiersTitlesId"
                    className="mb-0"
                    rules={[
                      {
                        required: true,
                        message: "Please select tier associated title",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      placeholder="Select Tier Associated Title"
                      disabled={!getFieldValue("jobTiersId")}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      {jobTierTitles.map(({ job_title }, index) => (
                        <Option key={index} value={job_title.id}>
                          {job_title.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Job Hiring Manager(s)"
              name="managerId"
              rules={[
                {
                  required: true,
                  message: "Please select job hiring manager(s)",
                },
              ]}
            >
              <Select
                showArrow
                showSearch
                allowClear
                mode="multiple"
                optionFilterProp="label"
                optionLabelProp="title"
                placeholder="Select Job Hiring Manager(s)"
                filterOption={customUserHandleSearch}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {users["Hiring Manager"].map(({ id, name, email, clients }) => (
                  <Option key={id} value={id} title={name}>
                    <UserSelectOption userEmail={email} clients={clients}>
                      {name}
                    </UserSelectOption>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label="Job Recruiter(s)"
              name="jobRecruiters"
              rules={[
                {
                  required: true,
                  message: "Please select job recruiter(s)",
                },
              ]}
            >
              <Select
                showArrow
                allowClear
                showSearch={true}
                mode="multiple"
                optionFilterProp="children"
                optionLabelProp="title"
                placeholder="Select Job Recruiter(s)"
                filterOption={customUserHandleSearch}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {users["Recruiter"].map(({ id, name, email, clients }) => (
                  <Option key={id} value={id} title={name}>
                    <UserSelectOption userEmail={email} clients={clients}>
                      {name}
                    </UserSelectOption>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* @Client Lookup */}
          <Col xs={24} sm={12}>
            <Form.Item
              label="Job Location"
              className="formItemLocation"
              name="jobLocationsId"
              rules={[
                {
                  required: true,
                  message: "Please select job location",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select Job Location"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                optionFilterProp="children"
              >
                {getIsVisibleLookups(
                  lookupTables.jobLocation.filter(
                    (lookup) =>
                      parseInt(lookup.clientId) === parseInt(clientId),
                  ),
                  editData?.jobLocation.id,
                ).map((item) => (
                  <Option
                    key={item.id}
                    value={item.id}
                    disabled={item.isVisible === 1 ? false : true}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label={
                <div>
                  <Tooltip
                    title="Starting point for the calculation of the time to paramenters"
                    color={K.TooltipColor}
                    placement="top"
                  >
                    <i className="icon-info-circle"></i>
                  </Tooltip>
                  &nbsp;Recruiting Start Date
                </div>
              }
              name="recruitingStartDate"
              rules={[
                {
                  required: true,
                  message: "Please select start date",
                },
              ]}
            >
              <DatePicker
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                format={K.DateFormat.DashUSFormat}
                // disabledDate={disableFutureDate}
                className="datePickerModal"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label="ATS"
              name="atsLookupId"
              rules={[
                {
                  required: true,
                  message: "Please select ATS",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select ATS"
                optionFilterProp="children"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {getIsVisibleLookups(
                  lookupTables.ats,
                  editData?.atsLookUp?.id,
                ).map((item) => (
                  <Option
                    key={item.id}
                    value={item.id}
                    disabled={item.isVisible === 1 ? false : true}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label="ATS ID"
              name="extAtsId"
              rules={[noTrailingSpaceAllowedRule()]}
            >
              <Input placeholder="Enter ATS ID" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item label="Secondary ATS" name="atsLookupSecondaryId">
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder="Select Secondary ATS"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {getIsVisibleLookups(
                  lookupTables.ats,
                  editData?.atsLookUpSecondary?.id,
                ).map((item) => (
                  <Option
                    key={item.id}
                    value={item.id}
                    disabled={item.isVisible === 1 ? false : true}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label="Secondary ATS ID"
              name="extSecondaryAtsId"
              rules={[noTrailingSpaceAllowedRule()]}
            >
              <Input placeholder="Enter Secondary ATS ID" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label="Job Status"
              name="jobStatusesId"
              rules={[
                {
                  required: true,
                  message: "Please select job status.",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Select Job Status"
                optionFilterProp="children"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {getIsVisibleLookups(
                  lookupTables.jobStatus,
                  editData?.jobStatus?.id,
                ).map((item) => (
                  <Option
                    key={item.id}
                    value={item.id}
                    disabled={item.isVisible === 1 ? false : true}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* @Client Lookup */}
          <Col xs={24} sm={12}>
            <Form.Item label="Job Department" name="jobDepartmentsId">
              <Select
                showSearch
                allowClear
                placeholder="Select Job Departments"
                optionFilterProp="children"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {getIsVisibleLookups(
                  lookupTables.jobDepartment.filter(
                    (lookup) =>
                      parseInt(lookup.clientId) === parseInt(clientId),
                  ),
                  editData?.jobDepartment?.id,
                ).map((item) => (
                  <Option
                    key={item.id}
                    value={item.id}
                    disabled={item.isVisible === 1 ? false : true}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* @Client Lookup */}
          <Col xs={24} sm={12}>
            <Form.Item label="Job Group" name="jobGroupsId">
              <Select
                showSearch
                allowClear
                placeholder="Select Job Groups"
                optionFilterProp="children"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {getIsVisibleLookups(
                  lookupTables.jobGroup.filter(
                    (lookup) =>
                      parseInt(lookup.clientId) === parseInt(clientId),
                  ),
                  editData?.jobGroup?.id,
                ).map((item) => (
                  <Option
                    key={item.id}
                    value={item.id}
                    disabled={item.isVisible === 1 ? false : true}
                  >
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {!editData && (
            <Col xs={24} sm={12}>
              <Form.Item
                label="Job Original Openings"
                name="jobOriginalOpenings"
                rules={[
                  {
                    required: true,
                    message: "Please enter job openings!",
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  className="modalInput w-100"
                  placeholder="Enter Openings"
                />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} sm={12}>
            <Form.Item
              label="Min Pay"
              name="minPay"
              rules={[
                { required: true, message: "Please enter min pay" },
                () => ({
                  validator(_, value) {
                    return validateStartingFields(value, "maxPay");
                  },
                }),
              ]}
            >
              <InputNumber
                min={0}
                className="modalInput w-100"
                formatter={formatter}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Max Pay"
              name="maxPay"
              rules={[
                { required: true, message: "Please enter max pay" },
                () => ({
                  validator(_, value) {
                    return validateEndingFields(value, "minPay");
                  },
                }),
              ]}
            >
              <InputNumber
                min={0}
                className="modalInput w-100"
                formatter={formatter}
              />
            </Form.Item>
          </Col>
          {udfSlice[K.Redux.JobSourceUdf]?.map((item) => {
            return (
              <Col xs={24} sm={12} key={item.id}>
                <DisplayUDF
                  isFormItem={true}
                  title={item.label}
                  fieldTypeId={item.fieldTypeId}
                  udfId={item.id}
                  isRequired={item.isRequired}
                  form={form}
                  formItemName={item.label}
                  options={
                    item.udfMultiValueFieldTypeVal.length
                      ? item.udfMultiValueFieldTypeVal
                      : []
                  }
                />
              </Col>
            );
          })}
          <Col span={24}>
            <Form.Item
              name="isWorkFromHome"
              valuePropName="checked"
              className="checkboxModal"
            >
              <Checkbox>WFH</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}
