import { Button, Divider, Modal, Typography } from "antd";
import DragUpload from "../../../../../../common/components/uploadComponent/dragger";
import SampleCSV from "../../../../../../assets/Source2_Cadidate_upload_Rich_File.xlsx";
import { useState } from "react";

export const UploadCsvButton = ({ clientId, disabled }) => {
  const [open, setOpen] = useState(false);
  const [csvFile, setCSVFile] = useState(null);

  const onRemove = () => {
    setCSVFile(null);
  };

  const onCancel = () => {
    onRemove();
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" disabled={disabled} onClick={() => setOpen(true)}>
        Upload CSV File
      </Button>

      <Modal
        title="Upload CSV File"
        centered
        className="s2-theme-style"
        onCancel={onCancel}
        okButtonProps={{
          disabled: !csvFile,
        }}
        destroyOnClose
        open={open}
      >
        <DragUpload
          setCSVFile={setCSVFile}
          onRemove={onRemove}
          isCandidateCsv={true}
          clientId={clientId}
          accept=".xlsx"
        />
        <div className="d-flex mt-3 justify-content-center">
          <Typography.Link
            href={SampleCSV}
            download="Source2_Cadidate_upload_Rich_File.xlsx"
          >
            Click here to download sample excel file
          </Typography.Link>
        </div>
        <Divider />
      </Modal>
    </>
  );
};
