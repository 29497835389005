import NetworkCall from "network/networkCall";
import Request from "network/request";
import {
  addLookupData,
  deleteLookupData,
  editLookupData,
  getLookupData,
} from "redux/slices/configrationSlice";
import K from "utilities/constants";
import {
  camelCaseKeys,
  camelCaseKeysRecursively,
} from "utilities/generalUtility";

export default class LookupTable {
  static getData(
    reduxKey,
    apiKey,
    selectedKey = [],
    dropdown = false,
    loading = true,
  ) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(
        Request.getLookupTableData(apiKey),
        loading,
      );

      const payload = {
        data: dropdown
          ? res.data
              .filter((item) => {
                return (
                  selectedKey.map((i) => i.id).includes(item.id) ||
                  item.is_visible === 1
                );
              })
              .map((obj) => camelCaseKeys(obj))
          : res.data.map((obj) => camelCaseKeys(obj)),
        table: reduxKey,
      };

      dispatch(getLookupData(payload));
    };
  }

  static addData(reduxKey, apiKey, body) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(
        Request.addLookupData(apiKey, body),
        false,
      );
      const payload = {
        data: camelCaseKeys(res.data),
        table: reduxKey,
      };
      dispatch(addLookupData(payload));
    };
  }

  static editData(reduxKey, apiKey, id, body) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(
        Request.editLookupData(apiKey, id, body),
        false,
      );

      const payload = {
        data: camelCaseKeys(res.data),
        table: reduxKey,
      };
      console.log(payload);
      dispatch(editLookupData(payload));
    };
  }

  static addClientData(reduxKey, apiKey, body, clientId) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(
        Request.addClientLookupData(apiKey, clientId, body),
        false,
      );

      const payload = {
        data: camelCaseKeys(res.data),
        table: reduxKey,
      };
      dispatch(addLookupData(payload));
    };
  }

  static editClientData(reduxKey, apiKey, id, body, clientId) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(
        Request.editClientLookupData(apiKey, id, clientId, body),
        false,
      );
      const payload = {
        data: camelCaseKeys(res.data),
        table: reduxKey,
      };
      dispatch(editLookupData(payload));
    };
  }

  static deleteAction(reduxKey, apiKey, id) {
    return async (dispatch) => {
      await NetworkCall.fetch(Request.deleteLookupData(apiKey, id));
      const payload = {
        id,
        table: reduxKey,
      };
      dispatch(deleteLookupData(payload));
    };
  }

  static async getDesignationData() {
    const res = await NetworkCall.fetch(
      Request.getLookupTableData(K.Network.URL.Lookup.UserDesignation),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getJobDepartmentByMultipleClient(body, loader = true) {
    const res = await NetworkCall.fetch(
      Request.getJobDepartmentByMultipleClient(body),
      loader,
    );
    return camelCaseKeysRecursively(res.data);
  }
  static async getJobLocationByMultipleClient(body, loader = true) {
    const res = await NetworkCall.fetch(
      Request.getJobLocationByMultipleClient(body),
      loader,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getLookupHistory(body) {
    const res = await NetworkCall.fetch(Request.getLookupHistory(body));
    return camelCaseKeysRecursively(res.data);
  }

  static async getJobStatuses(loading = true) {
    const res = await NetworkCall.fetch(
      Request.getLookupTableData(K.Network.URL.Lookup.JobStatus),
      loading,
    );
    return camelCaseKeysRecursively(res.data);
  }
}
