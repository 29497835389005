import { ServerSideDataGrid } from "../../../../common/components/serverSideDataGrid/serverSideDataGrid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import BillingApproval from "../../../../redux/models/billingApproval";
import User from "../../../../redux/models/user";
import K from "../../../../utilities/constants";
import { useSelector } from "react-redux";
import { selectUserId } from "../../../../redux/slices/userSlice";
import { jobBilling } from "../tableColumns";
import { PaginationSizeSelector } from "common/tableComponents/pagination/paginationSizeSelector";
import {
  calculateTableHeight,
  getBillHistory,
  onFilterChanged,
  sameMonthWarning,
  differentCurrencyWarning,
} from "./billingComponentsHelper";
import { debounce } from "lodash";

export const JobBillingsTable = ({
  clientId,
  pageStates,
  setPageStates,
  setRowData,
}) => {
  const userId = useSelector(selectUserId);
  const gridRef = useRef(null);

  const [height, setHeight] = useState(310);

  const tableColumns = useMemo(() => jobBilling, []);

  const getConfig = useCallback(() => {
    return User.getConfigrations(
      K.AgGridTable.Keys.SubmittedJobBillingsVisibleColumn,
    );
  }, []);

  const getJobServerSideData = useCallback(
    (options) => {
      return BillingApproval.getJobSubmittedBillings(clientId, {
        ...options,
        client: clientId,
      });
    },
    [clientId],
  );

  useEffect(() => {
    if (pageStates.jobLoading) {
      gridRef?.current?.api?.deselectAll();
      gridRef?.current?.api?.refreshServerSide();
    }
    setPageStates((prev) => ({ ...prev, jobLoading: false }));
  }, [pageStates.jobLoading]);

  useEffect(() => {
    if (pageStates.updatedJobRow?.id) {
      gridRef?.current?.api?.applyServerSideTransaction({
        update: [pageStates.updatedJobRow],
      });

      setPageStates((prev) => ({ ...prev, updatedJobRow: null }));
    }
  }, [pageStates.updatedJobRow]);

  const saveColumnConfig = useCallback(
    (config) => {
      return User.saveColumnSort({
        usersId: userId,
        configJson: JSON.stringify(config),
        tableName: K.AgGridTable.Keys.SubmittedJobBillingsVisibleColumn,
      });
    },
    [userId],
  );

  const onDataFetchFailed = useCallback((err) => console.error(err), []);
  const onDataFetchSuccess = useCallback(
    (data) => setHeight(calculateTableHeight(data.rowData)),
    [],
  );

  const onJobRowClicked = async (event, selectedClient) => {
    const history = await getBillHistory({
      type: "JOB",
      billingId: event.data.id,
    });

    setPageStates((prev) => ({
      ...prev,
      selectedClient,
      isEditMode: false,
      isModalVisible: true,
      isClientBillingRow: false,
      selectedRecord: event.data,
      conversionHistory: history,
    }));
  };

  const onSelectionChanged = debounce(async (event) => {
    const selectedRows = event.api.getServerSideSelectionState();

    const res = await BillingApproval.getJobBillingsTotal(
      clientId,
      selectedRows,
    );

    const {
      total,
      isSameMonth,
      isSameType,
      missedOfferSalaryBillings,
      totalCount,
    } = res.data;

    setPageStates((prev) => ({
      ...prev,
      checkedRows: {
        ...pageStates.checkedRows,
        jobTotal: total,
        jobSelectAll: selectedRows.selectAll,
        jobSelectedNodes: selectedRows.toggledNodes,
        offerSalaryMissingRows: missedOfferSalaryBillings.length,
        isSameTypeJobs: totalCount === 0 || !isSameType,
      },
    }));
    setRowData(missedOfferSalaryBillings);

    if (!isSameType) differentCurrencyWarning();
    if (isSameMonth) sameMonthWarning();
  }, 500);

  return (
    <ServerSideDataGrid
      minHeight={310}
      height={height}
      ref={gridRef}
      getData={getJobServerSideData}
      getColumnConfig={getConfig}
      saveColumnConfig={saveColumnConfig}
      defaultColumnConfig={tableColumns}
      defaultColDef={K.AgGridTable.DefaultColDef}
      onDataFetchFailed={onDataFetchFailed}
      pagination
      paginationPageSize={25}
      cacheBlockSize={25}
      rowSelection="multiple"
      suppressRowClickSelection
      onFilterChanged={(event) => onFilterChanged(event.api, setHeight)}
      onPaginationChanged={(event) => onFilterChanged(event.api, setHeight)}
      onDataFetchSuccess={onDataFetchSuccess}
      onSelectionChanged={onSelectionChanged}
      onRowClicked={(event) => {
        onJobRowClicked(event, {
          id: pageStates.listing.id,
          name: pageStates.listing.name,
        });
      }}
      components={{
        PaginationSizeSelector: (props) => (
          <PaginationSizeSelector
            {...props}
            gridRef={gridRef}
            getData={getJobServerSideData}
          />
        ),
      }}
      statusBar={{
        statusPanels: [
          {
            statusPanel: "PaginationSizeSelector",
            align: "left",
          },
        ],
      }}
    />
  );
};
