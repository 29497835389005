import { Empty, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import LookupTable from "../../../../../redux/models/lookupTable";

export const StatusSelector = (props) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDropdownVisibleChange = async (open) => {
    if (open && !options.length) {
      try {
        setLoading(true);

        const data = await LookupTable.getJobStatuses(false);

        setOptions(data.map(({ id, name }) => ({ value: id, label: name })));
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Select
      showArrow
      showSearch={true}
      allowClear
      placeholder="Select Job Status"
      options={options}
      style={{ width: "100%" }}
      notFoundContent={
        loading ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      onDropdownVisibleChange={handleDropdownVisibleChange}
      {...props}
    />
  );
};
