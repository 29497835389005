import { useEffect, useState } from "react";
import { Alert, Checkbox, Divider, Form, Input, Modal, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LookupTable from "redux/models/lookupTable.js";
import tabsConfig from "config/tabsConfig";
import { noTrailingSpaceAllowedRule } from "utilities/generalUtility";

import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import { customFormatSettingTime } from "utilities/dateUtility";
import Scrollbars from "react-custom-scrollbars-2";
import K from "utilities/constants";
import { selectUser } from "redux/slices/userSlice";

const { Title, Paragraph } = Typography;
export default function LookupTableModal({
  isVisible,
  setIsModalVisible,
  editData,
  setEditData,
  title,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const userSlice = useSelector(selectUser);

  const params = new URLSearchParams(window.location.search);
  const currentKey = params.get("tab");
  const selectedTab = tabsConfig[currentKey];

  const afterModalClose = () => {
    form.resetFields();
    setEditData({});
    setHistory([]);
  };
  const addLookUpData = async (name, isVisible) => {
    setIsLoading(true);

    try {
      await dispatch(
        LookupTable.addData(selectedTab.reduxKey, selectedTab.apiEndPoint, {
          name: name,
          isVisible: isVisible ? 0 : 1,
          userName: userSlice.details?.name,
          type: selectedTab.table,
        }),
      );
      setIsLoading(false);
      setIsModalVisible(false);

      form.resetFields();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getHistory = async () => {
    const payload = {
      type: selectedTab.table,
      entityId: editData.id, //look table row id
    };
    try {
      const res = await LookupTable.getLookupHistory(payload);
      setHistory(res);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (editData.id) {
      form.setFieldsValue({
        name: editData?.name,
        isVisible: editData?.isVisible ? 0 : 1,
      });
      getHistory();
    }
  }, [form, editData]);

  const editLookUpData = async (name, isVisible) => {
    const dataWasChanged =
      name !== editData.name || !!isVisible === !!editData.isVisible;
    setIsLoading(true);

    try {
      dataWasChanged &&
        (await dispatch(
          LookupTable.editData(
            selectedTab.reduxKey,
            selectedTab.apiEndPoint,
            editData.id,
            {
              name: name,
              isVisible: isVisible ? 0 : 1,
              type: selectedTab.table,
              userName: userSlice.details?.name,
            },
          ),
        ));

      setIsLoading(false);
      setIsModalVisible(false);

      form.resetFields();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const onFinish = (values) => {
    if (Object.keys(editData).length) {
      editLookUpData(values.name, values.isVisible);
    } else {
      addLookUpData(values.name, values.isVisible);
    }
  };

  return (
    <Modal
      forceRender
      centered
      open={isVisible}
      closeIcon={<i className="icon-closeable"></i>}
      okText={Object.keys(editData).length ? "Update" : "Add"}
      className="s2-theme-style"
      wrapClassName="vertical-center-modal"
      title={
        Object.keys(editData).length ? `Edit ${title}` : `Add New ${title}`
      }
      okButtonProps={{ loading: isLoading }}
      onOk={form.submit}
      afterClose={afterModalClose}
      onCancel={() => setIsModalVisible(false)}
      destroyOnClose
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Name is required." },
            noTrailingSpaceAllowedRule(),
          ]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
        <Form.Item
          className="reason-field"
          name={"isVisible"}
          valuePropName="checked"
        >
          <Checkbox>Archived </Checkbox>
        </Form.Item>
      </Form>
      <div className="settingHistoryWrap">
        <Scrollbars style={{ height: 270 }}>
          {history.length ? (
            history.map((item, i, source) => (
              <div key={item.id} className="settingHistoryDetail">
                <div className="settingAmountHistory">
                  <div className="clientName">
                    <Title level={5}>
                      {i === history?.length - 1 ? "Created By" : "Updated By"}:
                    </Title>
                    <Paragraph>{item.userName}</Paragraph>
                  </div>
                  <div className="clientName">
                    <Title level={5}>
                      {i === history?.length - 1
                        ? "Created Date"
                        : "Updated Date"}
                      :
                    </Title>
                    <Paragraph>
                      {customFormatSettingTime(item.createdAt, true)}
                    </Paragraph>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <EmptyPlaceholder />
          )}
        </Scrollbars>
      </div>
    </Modal>
  );
}
