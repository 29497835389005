import { useState } from "react";
import { Select } from "antd";

export const PaginationSizeSelector = ({ api }) => {
  const [pageSize, setPageSize] = useState(api.paginationGetPageSize());

  const pageSizes = [25, 50, 75, 100];

  const handleChangePageSize = (inputValue) => {
    const value = Number(inputValue);
    setPageSize(value);
    api.paginationSetPageSize(value);
    api.setCacheBlockSize(value);
  };

  return (
    <Select
      value={pageSize}
      style={{ height: 40, minWidth: 100 }}
      onChange={handleChangePageSize}
    >
      {pageSizes.map((pageSize, i) => {
        return (
          <Select.Option key={i} value={pageSize}>
            {pageSize}
          </Select.Option>
        );
      })}
    </Select>
  );
};
