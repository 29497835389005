import { Card, Col, Input, Row, Space } from "antd";
import { ServerSideDataGrid } from "../../../common/components/serverSideDataGrid/serverSideDataGrid";
import { Columns, DefaultColDef } from "./columns";
import { useCallback } from "react";
import Candidate from "../../../redux/models/candidate";
import User from "../../../redux/models/user";
import K from "../../../utilities/constants";
import { useSelector } from "react-redux";
import { selectUserId } from "../../../redux/slices/userSlice";
import { useHistory } from "react-router-dom";
import useSearchAndFilter from "../../../common/customHook/useSearchAndFilter";
import { debounce } from "lodash";
import { selectSelectedClientIds } from "../../../redux/slices/globalSelectorSlice";

const searchFilterPrefix = "global_candidate";
const getSearchKey = "global_candidate_search";

export const CandidatesContainer = () => {
  const history = useHistory();

  const clients = useSelector(selectSelectedClientIds);
  const userId = useSelector(selectUserId);

  const [setter, getter] = useSearchAndFilter();

  const search = getter(getSearchKey);

  const onSearchChange = useCallback(
    debounce((e) => {
      setter({ search: e.target.value || null }, searchFilterPrefix);
    }, 500),
    [setter],
  );

  const getServerSideData = useCallback(
    (options) => {
      return Candidate.v2.get({
        ...options,
        client: clients,
        search: search || undefined,
      });
    },
    [clients, search],
  );

  const getConfig = useCallback(() => {
    return User.getConfigrations(K.AgGridTable.Keys.Candidates);
  }, []);

  const saveColumnConfig = useCallback(
    (config) => {
      return User.saveColumnSort({
        usersId: userId,
        configJson: JSON.stringify(config),
        tableName: K.AgGridTable.Keys.Candidates,
      });
    },
    [userId],
  );

  return (
    <Card bodyStyle={{ padding: 16 }}>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row justify={"space-between"} gutter={[8, 8]}>
          <Col style={{ flex: 1 }}>
            <Row gutter={[8, 8]}>
              <Col style={{ width: "100%", maxWidth: 264 }}>
                <Input.Search
                  allowClear
                  defaultValue={search}
                  placeholder="Search"
                  onChange={onSearchChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <ServerSideDataGrid
          getData={getServerSideData}
          getColumnConfig={getConfig}
          saveColumnConfig={saveColumnConfig}
          defaultColumnConfig={Columns}
          defaultColDef={DefaultColDef}
          rowStyle={{ cursor: "pointer" }}
          onRowClicked={(event) =>
            history.push(`/candidates/detail/${event.data.id}`)
          }
        />
      </Space>
    </Card>
  );
};
