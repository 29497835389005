import {
  nullRenderer,
  tooltipTagRenderer,
} from "../billingCommissions/submitToBilling/tableColumns";
import { displayDollar, stringSorting } from "../../utilities/tableUtility";
import { Tag } from "antd";
import { convertIntoDashUSFormat } from "../../utilities/dateUtility";
import K from "../../utilities/constants";
import { ServerSideDataGrid } from "../../common/components/serverSideDataGrid/serverSideDataGrid";

const payRenderer = (params) => displayDollar(params.value);

const changeFormat = (params) => convertIntoDashUSFormat(params.value, false);

const tagRenderer = (params, key, color) =>
  params.data[key].map((item, i) => (
    <Tag key={i} color={color}>
      {item.name}
    </Tag>
  ));

const jobStatus = (params) => (params.data.isCancel === 1 ? "Yes" : "No");

const tooltipJobStatus = (params) => (
  <div className="client-list-tooltip">
    {params.data.isCancel === 1 ? "Yes" : "No"}
  </div>
);
const tooltipWorkFormHome = (params) => (
  <div className="client-list-tooltip">
    {params.data.isWorkFromHome === 1 ? "Yes" : "No"}
  </div>
);
const tooltipChangeFormat = (params) => (
  <div className="client-list-tooltip">
    {" "}
    {convertIntoDashUSFormat(params.value, false)}
  </div>
);
const tooltipPayRenderer = (params) => (
  <div className="client-list-tooltip"> {displayDollar(params.value)}</div>
);

const tooltipAtsId = (params) => {
  return (
    <div className="client-list-tooltip">
      {params.data.extAtsId ?? K.NullPlaceholder}
    </div>
  );
};

const workFormHome = (params) =>
  params.data.isWorkFromHome === 1 ? "Yes" : "No";

const atsId = (params) => {
  return params.data.extAtsId ?? K.NullPlaceholder;
};
const jobDepartment = (params) => params.data.jobDepartment?.name ?? "N/A";
const jobGroup = (params) => params.data.jobGroup?.name ?? "N/A";

export const Columns = [
  {
    headerName: "ID",
    field: "id",
    suppressMovable: false,
    sortable: true,
    suppressColumnsToolPanel: true,
  },
  {
    headerName: "Recruiter",
    field: "searchRecruiters",
    sortable: true,
    cellRenderer: (params) => tagRenderer(params, "jobRecruiters", "#0070C0"),
    tooltipField: "searchRecruiters",
    tooltipComponent: (params) => tooltipTagRenderer(params, "jobRecruiters"),
  },
  {
    headerName: "Job Title",
    field: "title",
    tooltipField: "title",
    sortable: true,
    suppressMovable: false,
  },
  {
    headerName: "Current Openings",
    field: "currentOpenings",
    sortable: true,
    tooltipField: "currentOpenings",
  },
  {
    headerName: "Job Location",
    field: "jobLocation.name",
    sortable: true,
    tooltipField: "jobLocation.name",
  },
  {
    headerName: "Job Department",
    field: "jobDepartment",
    sortable: true,
    tooltipField: "jobDepartment.name",
    getQuickFilterText: jobDepartment,
    // tooltipComponent: tooltipJobDepartment,
    ...nullRenderer,
    comparator: (a, b) => stringSorting(a, b, "name"),
    cellRenderer: jobDepartment,
  },
  {
    headerName: "Job Group",
    field: "jobGroup",
    sortable: true,
    tooltipField: "jobGroup.name",
    getQuickFilterText: jobGroup,
    ...nullRenderer,
    comparator: (a, b) => stringSorting(a, b, "name"),
    cellRenderer: jobGroup,
  },
  {
    headerName: "Job Hiring Manager",
    field: "searchHiringManagers",
    sortable: true,
    tooltipField: "searchHiringManagers",
    tooltipComponent: (params) =>
      tooltipTagRenderer(params, "jobHiringManagers"),
    cellRenderer: (params) =>
      tagRenderer(params, "jobHiringManagers", "#BDD7EE"),
  },
  {
    headerName: "Recruiting Start Date",
    field: "recruitingStartDate",
    sortable: true,
    suppressMovable: false,
    tooltipField: "recruitingStartDate",
    tooltipComponent: tooltipChangeFormat,
    cellRenderer: changeFormat,
  },
  {
    headerName: "Max Pay",
    field: "maxPay",
    sortable: true,
    suppressMovable: false,
    tooltipField: "maxPay",
    tooltipComponent: tooltipPayRenderer,
    cellRenderer: payRenderer,
  },
  {
    headerName: "Min Pay",
    field: "minPay",
    sortable: true,
    suppressMovable: false,
    tooltipField: "minPay",
    tooltipComponent: tooltipPayRenderer,
    cellRenderer: payRenderer,
  },
  {
    headerName: "Tier",
    field: "jobTier",
    sortable: true,
    tooltipField: "jobTier",
  },
  {
    headerName: "ATS ID",
    field: "extAtsId",
    sortable: true,
    tooltipField: "extAtsId",
    cellRenderer: atsId,
    tooltipComponent: tooltipAtsId,
  },
  {
    headerName: "Job Status",
    field: "jobStatus.name",
    sortable: true,
    tooltipField: "jobStatus.name",
  },

  {
    headerName: "WFH?",
    field: "isWorkFromHome",
    sortable: true,
    tooltipField: "isWorkFromHome",
    tooltipComponent: tooltipWorkFormHome,
    cellRenderer: workFormHome,
  },
  {
    headerName: "Cancelled?",
    field: "isCancel",
    sortable: true,
    tooltipField: "isCancel",
    tooltipComponent: tooltipJobStatus,
    cellRenderer: jobStatus,
  },
];

export const DefaultColDef = {
  ...ServerSideDataGrid.DefaultColDef,
  sortable: false,
};
