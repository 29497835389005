import NetworkCall from "network/networkCall";
import Request from "network/request";
import { camelCaseKeysRecursively } from "utilities/generalUtility";

export default class ScheduledBillings {
  static async getScheduledJobBillings(params) {
    return await NetworkCall.fetch(Request.v2.getScheduledJobBillings(params));
  }

  static async getClientScheduledBillings(clientsId) {
    return await NetworkCall.fetch(
      Request.v2.getScheduledClientBillings(clientsId),
    );
  }

  static updateJobScheduledBilling(body, id) {
    return NetworkCall.fetch(
      Request.updateJobScheduledBilling(body, id),
      false,
    );
  }

  static updateClientScheduledBilling(body, id) {
    return NetworkCall.fetch(
      Request.updateClientScheduledBilling(body, id),
      false,
    );
  }

  static async getScheduledBillingByCandidateId(id) {
    const res = await NetworkCall.fetch(
      Request.getScheduledBillingByCandidateId(id),
    );
    return camelCaseKeysRecursively(res.data);
  }
}
