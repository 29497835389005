import { Collapse as AntCollapse } from "antd";
import styles from "./collapse.module.scss";

export const Collapse = (props) => {
  return (
    <AntCollapse
      expandIconPosition={"end"}
      className={styles.collapse}
      {...props}
    />
  );
};

Collapse.Panel = AntCollapse.Panel;
