import {
  DatePicker,
  Divider,
  Form,
  Modal,
  Radio,
  Typography,
  message,
} from "antd";
import K from "utilities/constants";
import Export from "../../../../redux/models/export";
import { useState } from "react";

const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

export default function ExportToCSV({
  open,
  clientId,
  jobIds,
  jobStatus,
  onSuccess,
  onCancel,
}) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSuccess = () => {
    onSuccess?.();
  };

  const handleExportCancel = () => {
    onCancel?.();
  };

  const shouldUpdate = (prevValues, curValues) =>
    prevValues.exportType !== curValues.exportType;

  const onFinish = async (values) => {
    const payload = {
      clientId,
      jobIds: jobIds,
      jobStatus: jobStatus,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...(values.exportType === "candidate" &&
        values.range && {
          dateType: values.dateType,
        }),
      range: values.range
        ? [
            values.range[0].format(K.DateFormat.Response),
            values.range[1].format(K.DateFormat.Response),
          ]
        : undefined,
    };

    try {
      setLoading(true);
      values.exportType === "candidate"
        ? await Export.getReportCandidateJobEntries(payload, false)
        : await Export.getReportJobsVacancies(payload, false);

      values.exportType === "candidate" &&
        message.success(
          "Candidate export request successfully received! Check your email shortly",
        );
      handleSuccess();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      centered
      className="s2-theme-style"
      title="Export"
      onOk={form.submit}
      onCancel={handleExportCancel}
      okButtonProps={{ loading }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          exportType: "job",
          dateType: "application_date",
        }}
      >
        <Form.Item className="billing-radios" name="exportType">
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={[
              { label: "Export Job", value: "job" },
              { label: "Export Candidate", value: "candidate" },
            ]}
          />
        </Form.Item>
        <Divider />
        <Form.Item noStyle shouldUpdate={shouldUpdate}>
          {({ getFieldValue }) => (
            <Paragraph>
              {getFieldValue("exportType") !== "job"
                ? `Please enter date range to export jobs details within entered range. Leave the
          range field blank to export all available data of selected jobs.`
                : `Please enter recruiting start date range to export job candidates within entered range. Leave the
          range field blank to export all available candidates of selected jobs.`}
            </Paragraph>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate={shouldUpdate}>
          {({ getFieldValue }) =>
            getFieldValue("exportType") === "candidate" ? (
              <>
                <Form.Item label="Date Type" name="dateType">
                  <Radio.Group
                    options={[
                      { label: "Application Date", value: "application_date" },
                      { label: "Sourced Date", value: "sourced_date" },
                      { label: "Both", value: "both" },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="Range (Leave blank to export all available data)"
                  name="range"
                >
                  <RangePicker format={K.DateFormat.DashUSFormat} />
                </Form.Item>
              </>
            ) : (
              <Form.Item
                label="Recruiting Start Date Range (Leave blank to export all available data)"
                name="range"
              >
                <RangePicker format={K.DateFormat.DashUSFormat} />
              </Form.Item>
            )
          }
        </Form.Item>
      </Form>
    </Modal>
  );
}
