import { createSlice } from "@reduxjs/toolkit";
import K from "utilities/constants";

const { Redux } = K;

const initialState = {
  [Redux.Listing]: [],
  [Redux.ShouldUpdate]: false,
  [Redux.ShouldReinitialize]: false,
  uploadedCandidate: [],
};

export const candidateSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    setCandidateList: (state, action) => {
      state[Redux.Listing] = action.payload;
    },
    updateCandidateList: (state, action) => {
      state[Redux.ShouldUpdate] = !state[Redux.ShouldUpdate];
      const currentList = Array.isArray(state[Redux.Listing])
        ? state[Redux.Listing]
        : [];

      state[Redux.Listing] = [...currentList, action.payload];
    },
    removeFromCandidateList: (state, action) => {
      state[Redux.ShouldUpdate] = !state[Redux.ShouldUpdate];

      state[Redux.Listing] = Array.isArray(state[Redux.Listing])
        ? state[Redux.Listing].filter((item) => item.id !== action.payload)
        : [];
    },
    editCandidateList: (state, action) => {
      state[Redux.ShouldUpdate] = !state[Redux.ShouldUpdate];
      state[Redux.Listing] = state[Redux.Listing].map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            candidate: action.payload.candidate,
            status: action.payload.status,
            subStatus: action.payload.subStatus,
            candidateStatus: action.payload.candidateStatus,
            isConsiderable: action.payload.isConsiderable,
          };
        } else {
          return item;
        }
      });
    },
    setShouldReinitialize: (state) => {
      state[Redux.ShouldReinitialize] = !state[Redux.ShouldReinitialize];
    },
    setShouldUpdate: (state) => {
      state[Redux.ShouldUpdate] = !state[Redux.ShouldUpdate];
    },
    setUploadCandidate: (state, action) => {
      state.uploadedCandidate = action.payload;
    },
  },
});

export const selectCandidates = (state) => state.candidates;
export const {
  setCandidateList,
  editCandidateList,
  updateCandidateList,
  setShouldReinitialize,
  setShouldUpdate,
  setUploadCandidate,
  removeFromCandidateList,
} = candidateSlice.actions;

export default candidateSlice.reducer;
