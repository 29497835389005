import { Alert, Divider, Form, Modal, Typography, message } from "antd";
import { useState } from "react";

import "../clients/create/jobCard.scss";
import Candidate from "redux/models/candidate";
import { useDispatch } from "react-redux";
import { removeFromCandidateList } from "../../redux/slices/candidateSlice";

const { Text } = Typography;

export default function DeleteCandidate({
  candidateActivity,
  deleteCandidateModal,
  setDeleteCandidateModal,
  setJobData,
  onActivityModalClose,
  setTotalCount,
}) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const deleteCancelHandler = () => {
    form.resetFields();
    setDeleteCandidateModal((prev) => {
      return { ...prev, showModal: false, deleteResponse: null };
    });
  };

  const afterModalClose = () => {
    form.resetFields();
    setDeleteCandidateModal((prev) => {
      return { ...prev, showModal: false, deleteResponse: null };
    });
  };

  const deleteCandidate = async () => {
    try {
      setLoading(true);
      await Candidate.deleteCandiate(candidateActivity.id);

      onActivityModalClose();
      //close delete modal
      setDeleteCandidateModal((prev) => {
        return { ...prev, showModal: false, deleteResponse: null };
      });
      //update canddiate listing
      setJobData((prev) => {
        return { ...prev, shouldUpdateListing: !prev.shouldUpdateListing };
      });
      dispatch(removeFromCandidateList(candidateActivity.id));
      setTotalCount((prev) => prev - 1);
      message.success("Candidate deleted successfully");
    } catch (error) {
      setDeleteCandidateModal((prev) => {
        return { ...prev, deleteResponse: error.message };
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async () => {
    await deleteCandidate();
  };

  return (
    <Modal
      centered
      width={568}
      okText="Delete Candidate"
      title="Are you sure?"
      className="s2-theme-style modal-overflow-auto"
      wrapClassName="vertical-center-modal"
      open={deleteCandidateModal.showModal}
      closeIcon={<i className="icon-closeable"></i>}
      okButtonProps={{ loading }}
      onOk={() => form.submit()}
      onCancel={deleteCancelHandler}
      afterClose={afterModalClose}
      destroyOnClose
    >
      <Form form={form} onFinish={onFinish}>
        <Divider style={{ margin: 0, marginBottom: 10 }} />
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <strong>
              <font style={{ fontSize: 26, color: "#278dc0" }}>
                {[
                  candidateActivity.candidate.firstName,
                  candidateActivity.candidate.lastName,
                ].join(" ")}
              </font>
            </strong>
          </div>
          <div>
            <strong>Email : </strong>
            <font> {candidateActivity.candidate.email}</font>
          </div>
          <div>
            <strong>Location : </strong>
            <font> {candidateActivity.candidate.location}</font>
          </div>
        </div>
        {deleteCandidateModal.deleteResponse && (
          <>
            <Divider style={{ margin: 0, marginTop: 30 }} />
            <div style={{ marginTop: 20 }}>
              <Alert
                style={{ whiteSpace: "break-spaces" }}
                showIcon
                type="error"
                message={
                  <>
                    <strong>Error :&nbsp; </strong>
                    <Text>{deleteCandidateModal.deleteResponse}</Text>
                  </>
                }
              />
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
}
