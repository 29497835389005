import { PageHeader } from "antd";
import LayoutCss from "../../../layout/layout.module.scss";
import { ClientsContainer } from "./clientsContainer/clientsContainer";

export const Clients = () => {
  return (
    <>
      <PageHeader
        ghost={false}
        title="Clients"
        className={LayoutCss.appPageHeader}
      />
      <ClientsContainer />
    </>
  );
};
