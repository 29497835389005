import { Form, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Candidate from "redux/models/candidate";
import { selectConfigration } from "redux/slices/configrationSlice";
import K from "utilities/constants";
import { getIsVisibleLookups } from "utilities/generalUtility";
import LookupTable from "../../redux/models/lookupTable";

export default function ChangeCandidateStatus({
  selectedData,
  setSelectedData,
  isModalVisible,
  setIsModalVisible,
  setRefreshTable,
}) {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const lookupTables = useSelector(selectConfigration).lookup;
  const dispatch = useDispatch();

  const getCandidateStatus = async () => {
    try {
      await dispatch(
        LookupTable.getData(
          K.Redux.CandidateStatus,
          K.Network.URL.Lookup.CandidateStatus,
        ),
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCandidateStatus();
  }, []);

  const handleSuccess = () => {
    setSelectedData({ candidateJobEntryIds: [] });

    setIsModalVisible((prev) => ({
      ...prev,
      isCandidateStatusChangeModalVisible: false,
    }));
  };

  const handleCancel = () => {
    setIsModalVisible((prev) => ({
      ...prev,
      isCandidateStatusChangeModalVisible: false,
    }));
  };

  const afterModalClose = () => {
    form.resetFields();
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const payload = {
        candidateJobEntryIds: selectedData.candidateJobEntryIds,
        candidateStatusId: values.candidateStatusId,
      };

      const res = await Candidate.changeCandidateStatusInBulk(payload);
      handleSuccess();
      setRefreshTable((prev) => !prev);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      centered
      className="s2-theme-style"
      title="Change Candidate Status"
      open={isModalVisible.isCandidateStatusChangeModalVisible}
      onOk={form.submit}
      onCancel={handleCancel}
      afterClose={afterModalClose}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Select Candidate Status"
          name="candidateStatusId"
          rules={[
            { required: true, message: "Please Select Candidate Status" },
          ]}
        >
          <Select
            showSearch
            allowClear
            optionFilterProp="label"
            placeholder="Select Candidate Status"
            options={getIsVisibleLookups(
              lookupTables[K.Redux.CandidateStatus],
              null,
            ).map(({ id, name, isVisible }) => ({
              value: id,
              label: name,
              disabled: isVisible === 1 ? false : true,
            }))}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
