import { Col, Row, Tag, Typography } from "antd";
import DisplayEditableDate from "common/components/candidateDateComponents/displayEditableDate";
import EditDate from "common/components/candidateDateComponents/editDate";
import UDFDisplayEditableDate from "common/components/candidateDateComponents/udfDisplayEditable";
import UDFEditComponent from "common/components/candidateDateComponents/udfEditComponent";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUdf } from "redux/slices/udfSlice";
import K from "utilities/constants";
import { convertIntoDashUSFormat } from "utilities/dateUtility";
import { displayUdfFieldValues } from "utilities/generalUtility";
import { displayDollar } from "utilities/tableUtility";

const { Title, Paragraph } = Typography;

export default function CandidateDetails({
  styles,
  candidateActivity,
  setCandidateActivity,
  getCandidateUdfs,
}) {
  const [editDates, setEditDates] = useState({});
  const [udfEdit, setUdfEdit] = useState({});
  const [udfShouldLoad, setUdfShouldLoad] = useState(false);

  const udfSlice = useSelector(selectUdf);

  useEffect(() => {
    setEditDates({});
  }, [candidateActivity]);

  useEffect(() => {
    getCandidateUdfs(candidateActivity.id);
  }, [udfShouldLoad]);

  return (
    <div className={styles.viewActivityFrom + " viewActivityCol"}>
      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Email:
          </Title>
          <Paragraph className={styles.viewActivityValue}>
            {candidateActivity.candidate.email}
          </Paragraph>
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Additional Phone:
          </Title>
          <Paragraph className={styles.viewActivityValue}>
            {candidateActivity.candidate.phoneNumber ?? K.NullPlaceholder}
          </Paragraph>
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Mobile Number:
          </Title>
          <Paragraph className={styles.viewActivityValue}>
            {candidateActivity.candidate.mobileNumber}
          </Paragraph>
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Recruiters:
          </Title>
          <div className={styles.viewActivityTags}>
            {candidateActivity.candidateJobRecruiters.map(({ name }, i) => (
              <Tag key={i} color="#0070C0">
                {name}
              </Tag>
            ))}
          </div>
        </Col>
      </Row>
      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Home Address:
          </Title>
          <Paragraph className={styles.viewActivityValue}>
            {candidateActivity.candidate.location ?? K.NullPlaceholder}
          </Paragraph>
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Current Title:
          </Title>
          <Paragraph className={styles.viewActivityValue}>
            {candidateActivity.candidate.currentTitle ?? K.NullPlaceholder}
          </Paragraph>
        </Col>
      </Row>
      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Department:
          </Title>
          <Paragraph className={styles.viewActivityValue}>
            {candidateActivity.jobSource?.jobDepartment?.name ??
              K.NullPlaceholder}
          </Paragraph>
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            ATS Candidate ID:
          </Title>
          <Paragraph className={styles.viewActivityValue}>
            {candidateActivity.atsLookupId ?? K.NullPlaceholder}
          </Paragraph>
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Desired Salary:
          </Title>
          <Paragraph className={styles.viewActivityValue}>
            {displayDollar(candidateActivity.desireSalary)}
          </Paragraph>
        </Col>

        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Offered Salary:
          </Title>
          <Paragraph className={styles.viewActivityValue}>
            {displayDollar(candidateActivity.salary)}
          </Paragraph>
        </Col>
      </Row>
      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Interview Confirmation:
          </Title>
          {editDates.interviewConfirmation ? (
            <EditDate
              dateKey="interviewConfirmation"
              setEditDates={setEditDates}
              dateString={candidateActivity.interviewConfirmation}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              isOtherItem={true}
              itemType={K.EditComponentType.CheckBox}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="interviewConfirmation"
              setEditDates={setEditDates}
              dateString={candidateActivity.interviewConfirmation}
              isOtherItem={true}
              candidateActivity={candidateActivity}
            />
          )}

          {/* <Paragraph className={styles.viewActivityValue}>
            {candidateActivity.interviewConfirmation ? "Yes" : "No"}
          </Paragraph> */}
        </Col>

        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Sourced Date:
          </Title>
          {editDates.sourcedDate ? (
            <EditDate
              dateKey="sourcedDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.sourcedDate}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="sourcedDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.sourcedDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            First Contact Date:
          </Title>
          {editDates.firstContactedDate ? (
            <EditDate
              dateKey="firstContactedDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.firstContactedDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="firstContactedDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.firstContactedDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Second Contact Date:
          </Title>
          {editDates.secondContactedDate ? (
            <EditDate
              dateKey="secondContactedDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.secondContactedDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="secondContactedDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.secondContactedDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Third Contact Date:
          </Title>
          {editDates.thirdContactedDate ? (
            <EditDate
              dateKey="thirdContactedDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.thirdContactedDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="thirdContactedDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.thirdContactedDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Not Interested Date:
          </Title>
          {editDates.notIntrestedDate ? (
            <EditDate
              dateKey="notIntrestedDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.notIntrestedDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="notIntrestedDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.notIntrestedDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Interested Date:
          </Title>
          {editDates.intrestedDate ? (
            <EditDate
              dateKey="intrestedDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.intrestedDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="intrestedDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.intrestedDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
        <Col xm={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Applied Date:
          </Title>
          {editDates.applicationDate ? (
            <EditDate
              dateKey="applicationDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.applicationDate}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="applicationDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.applicationDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Review Complete Date:
          </Title>
          {editDates.reviewCompleteDate ? (
            <EditDate
              dateKey="reviewCompleteDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.reviewCompleteDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="reviewCompleteDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.reviewCompleteDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>

        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            S2 Interview Contacted Date
          </Title>
          {editDates.s2InterviewContactedDate ? (
            <EditDate
              dateKey="s2InterviewContactedDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.s2InterviewContactedDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="s2InterviewContactedDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.s2InterviewContactedDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            S2 Interview Scheduled Date:
          </Title>
          {editDates.s2InterviewScheduledDate ? (
            <EditDate
              dateKey="s2InterviewScheduledDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.s2InterviewScheduledDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="s2InterviewScheduledDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.s2InterviewScheduledDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>

        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Phone Screen Complete Date:
          </Title>
          {editDates.phoneScreenCompleteDate ? (
            <EditDate
              dateKey="phoneScreenCompleteDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.phoneScreenCompleteDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="phoneScreenCompleteDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.phoneScreenCompleteDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xm={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Date Submitted:
          </Title>
          {editDates.dateSubmitted ? (
            <EditDate
              dateKey="dateSubmitted"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.dateSubmitted}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="dateSubmitted"
              setEditDates={setEditDates}
              dateString={candidateActivity.dateSubmitted}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Submitted Feedback Received Date:
          </Title>
          {editDates.dateSubmittedFeedbackReceived ? (
            <EditDate
              dateKey="dateSubmittedFeedbackReceived"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.dateSubmittedFeedbackReceived}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="dateSubmittedFeedbackReceived"
              setEditDates={setEditDates}
              dateString={candidateActivity.dateSubmittedFeedbackReceived}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Scheduling Interview Date:
          </Title>
          {editDates.schedulingDate ? (
            <EditDate
              dateKey="schedulingDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.schedulingDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="schedulingDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.schedulingDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Interview Date:
          </Title>
          {editDates.interviewDate ? (
            <EditDate
              dateKey="interviewDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.interviewDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="interviewDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.interviewDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>
      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Interview Time:
          </Title>
          {editDates.interviewTime ? (
            <EditDate
              isTime={true}
              dateKey="interviewTime"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.interviewTime}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              isTime={true}
              styles={styles}
              dateKey="interviewTime"
              setEditDates={setEditDates}
              dateString={candidateActivity.interviewTime}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Interview Feedback Received Date:
          </Title>
          {editDates.interviewFeedbackReceived ? (
            <EditDate
              dateKey="interviewFeedbackReceived"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.interviewFeedbackReceived}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="interviewFeedbackReceived"
              setEditDates={setEditDates}
              dateString={candidateActivity.interviewFeedbackReceived}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>
      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Second Interview Date:
          </Title>
          {editDates.secondInterviewDate ? (
            <EditDate
              dateKey="secondInterviewDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.secondInterviewDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="secondInterviewDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.secondInterviewDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Second Interview Time:
          </Title>
          {editDates.secondInterviewTime ? (
            <EditDate
              isTime={true}
              dateKey="secondInterviewTime"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.secondInterviewTime}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              isTime={true}
              styles={styles}
              dateKey="secondInterviewTime"
              setEditDates={setEditDates}
              dateString={candidateActivity.secondInterviewTime}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Third Interview Date:
          </Title>
          {editDates.thirdInterviewDate ? (
            <EditDate
              dateKey="thirdInterviewDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.thirdInterviewDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="thirdInterviewDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.thirdInterviewDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Third Interview Time:
          </Title>
          {editDates.thirdInterviewTime ? (
            <EditDate
              isTime={true}
              dateKey="thirdInterviewTime"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.thirdInterviewTime}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              isTime={true}
              styles={styles}
              dateKey="thirdInterviewTime"
              setEditDates={setEditDates}
              dateString={candidateActivity.thirdInterviewTime}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>
      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Offer Approval Date:
          </Title>
          {editDates.offerApprovalDate ? (
            <EditDate
              dateKey="offerApprovalDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.offerApprovalDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="offerApprovalDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.offerApprovalDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Offer Extended Date:
          </Title>
          {editDates.offerExtendedDate ? (
            <EditDate
              dateKey="offerExtendedDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.offerExtendedDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="offerExtendedDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.offerExtendedDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>
      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Offer Accepted Date:
          </Title>
          {editDates.offerAcceptedDate ? (
            <EditDate
              dateKey="offerAcceptedDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.offerAcceptedDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="offerAcceptedDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.offerAcceptedDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Pending Start Date:
          </Title>
          {editDates.pendingStartDate ? (
            <EditDate
              dateKey="pendingStartDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.pendingStartDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="pendingStartDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.pendingStartDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>
      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Withdrew Date:
          </Title>
          {editDates.withdrewDate ? (
            <EditDate
              dateKey="withdrewDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.withdrewDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="withdrewDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.withdrewDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Declined Date:
          </Title>
          {editDates.declinedDate ? (
            <EditDate
              dateKey="declinedDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.declinedDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="declinedDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.declinedDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Declined Offer Rescinded Date:
          </Title>
          {editDates.declinedOfferRescindedDate ? (
            <EditDate
              dateKey="declinedOfferRescindedDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.declinedOfferRescindedDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="declinedOfferRescindedDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.declinedOfferRescindedDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Withdrew Unresponsive Date:
          </Title>
          {editDates.declinedUnresponsiveDate ? (
            <EditDate
              dateKey="declinedUnresponsiveDate"
              setEditDates={setEditDates}
              candidateJobEntryId={candidateActivity.id}
              setCandidateActivity={setCandidateActivity}
              dateString={candidateActivity.declinedUnresponsiveDate}
              jobId={candidateActivity.jobsourceId}
            />
          ) : (
            <DisplayEditableDate
              styles={styles}
              dateKey="declinedUnresponsiveDate"
              setEditDates={setEditDates}
              dateString={candidateActivity.declinedUnresponsiveDate}
              candidateActivity={candidateActivity}
            />
          )}
        </Col>
      </Row>

      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Hired Date:
          </Title>
          <Paragraph className={styles.viewActivityValue}>
            {convertIntoDashUSFormat(candidateActivity.hireDate, false)}
          </Paragraph>
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Start Date:
          </Title>
          <Paragraph className={styles.viewActivityValue}>
            {convertIntoDashUSFormat(candidateActivity.startDate, false)}
          </Paragraph>
        </Col>
      </Row>
      <Row gutter={50}>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Term Date:
          </Title>
          <Paragraph className={styles.viewActivityValue}>
            {convertIntoDashUSFormat(candidateActivity.termDate, false)}
          </Paragraph>
        </Col>
        <Col xs={24} sm={12} lg={12}>
          <Title level={5} className={styles.viewActivityLabel}>
            Outside Retention Period Date:
          </Title>
          <Paragraph className={styles.viewActivityValue}>
            {convertIntoDashUSFormat(
              candidateActivity.outsideRetentionPeriodDate,
              false,
            )}
          </Paragraph>
        </Col>
      </Row>
      <Row gutter={50}>
        {udfSlice[K.Redux.CandidateUdf].length > 0 &&
          displayUdfFieldValues(udfSlice[K.Redux.CandidateUdf])?.map(
            (item, index) => {
              return (
                <>
                  {/* <Col xs={24} sm={12} lg={12}>
                    <Title level={5} className={styles.viewActivityLabel}>
                      {`${item.label}:`}
                    </Title>

                    <Paragraph className={styles.viewActivityValue}>
                      {item.value !== null && item.value !== ""
                        ? item.value
                        : "N/A"}
                    </Paragraph>
                  </Col>
                  {(index + 1) % 2 === 0 && (
                    <div
                      style={{
                        width: "100%",
                        clear: "both",
                        paddingBottom: 20,
                      }}
                    ></div>
                  )} */}

                  {udfEdit[item.id] ? (
                    <Col xs={24} sm={12} lg={12}>
                      <UDFEditComponent
                        styles={styles}
                        item={item}
                        setUdfEdit={setUdfEdit}
                        setUdfShouldLoad={setUdfShouldLoad}
                        candidateJobEntryId={candidateActivity.id}
                      />
                    </Col>
                  ) : (
                    <Col xs={24} sm={12} lg={12}>
                      <UDFDisplayEditableDate
                        index={index}
                        item={item}
                        styles={styles}
                        setUdfEdit={setUdfEdit}
                        candidateActivity={candidateActivity}
                      />
                    </Col>
                  )}
                </>
              );
            },
          )}
      </Row>
    </div>
  );
}
