import { Button } from "antd";
import styles from "../clientsJob.module.scss";
import { useState } from "react";
import { AddCandidateModal } from "./addCandidateModal";
import Candidate from "../../../../redux/models/candidate";
import { useDispatch } from "react-redux";
import { updateCandidateList } from "../../../../redux/slices/candidateSlice";

export const AddCandidateButton = ({
  disabled,
  getStatusBreakdown,
  getCandidateUdf,
  setTotalCount,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    try {
      setLoading(true);

      const res = await Candidate.create(values);
      setOpen(false);
      dispatch(updateCandidateList(res));
      setTotalCount((prev) => prev + 1);
      getStatusBreakdown();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className={styles.addCandidate + " btn-add-candidate"}
        disabled={disabled}
      >
        Add Candidate
      </Button>

      {open && (
        <AddCandidateModal
          open={open}
          loading={loading}
          onCancel={() => setOpen(false)}
          onFinish={onFinish}
          getCandidateUdf={getCandidateUdf}
        />
      )}
    </>
  );
};
