import { useSelector } from "react-redux";
import "../submitToBilling.scss";
import "../billing-collapse.scss";
import { selectUserId } from "../../../../redux/slices/userSlice";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { clientBilling } from "../tableColumns";
import User from "../../../../redux/models/user";
import K from "../../../../utilities/constants";
import BillingApproval from "../../../../redux/models/billingApproval";
import { ServerSideDataGrid } from "../../../../common/components/serverSideDataGrid/serverSideDataGrid";
import { PaginationSizeSelector } from "../../../../common/tableComponents/pagination/paginationSizeSelector";
import {
  calculateTableHeight,
  getBillHistory,
  onFilterChanged,
  differentCurrencyWarning,
} from "./billingComponentsHelper";
import { debounce } from "lodash";

export const ClientBillingsTable = ({
  clientId,
  pageStates,
  setPageStates,
}) => {
  const userId = useSelector(selectUserId);
  const gridRef = useRef(null);

  const [height, setHeight] = useState(322);

  const tableColumns = useMemo(() => clientBilling, []);

  const getConfig = useCallback(() => {
    return User.getConfigrations(
      K.AgGridTable.Keys.SubmittedClientBillingsVisibleColumn,
    );
  }, []);

  const getClientServerSideData = useCallback(
    (options) => {
      return BillingApproval.getClientsSubmittedBillings(clientId, {
        ...options,
        client: clientId,
      });
    },
    [clientId],
  );

  useEffect(() => {
    if (pageStates.clientLoading) {
      gridRef?.current?.api?.deselectAll();
      gridRef?.current?.api?.refreshServerSide();
    }
    setPageStates((prev) => ({ ...prev, clientLoading: false }));
  }, [pageStates.clientLoading]);

  useEffect(() => {
    if (pageStates.updatedClientRow?.id) {
      gridRef?.current?.api?.applyServerSideTransaction({
        update: [pageStates.updatedClientRow],
      });

      setPageStates((prev) => ({ ...prev, updatedClientRow: null }));
    }
  }, [pageStates.updatedClientRow]);

  const saveColumnConfig = useCallback(
    (config) => {
      return User.saveColumnSort({
        usersId: userId,
        configJson: JSON.stringify(config),
        tableName: K.AgGridTable.Keys.SubmittedClientBillingsVisibleColumn,
      });
    },
    [userId],
  );

  const onDataFetchFailed = useCallback((err) => console.error(err), []);
  const onDataFetchSuccess = useCallback(
    (data) => setHeight(calculateTableHeight(data.rowData)),
    [],
  );

  const onClientRowClicked = async (event, selectedClient) => {
    const history = await getBillHistory({
      type: "CLIENT",
      billingId: event.data.id,
    });
    setPageStates((prev) => ({
      ...prev,
      selectedClient,
      isEditMode: false,
      isModalVisible: true,
      isClientBillingRow: true,
      selectedRecord: event.data,
      conversionHistory: history,
    }));
  };

  const onSelectionChanged = debounce(async (event) => {
    const selectedRows = event.api.getServerSideSelectionState();

    const res = await BillingApproval.getClientBillingsTotal(
      clientId,
      selectedRows,
    );

    const { total, isSameType, totalCount } = res.data;

    setPageStates((prev) => ({
      ...prev,
      checkedRows: {
        ...pageStates.checkedRows,
        clientTotal: total,
        clientSelectAll: selectedRows.selectAll,
        clientSelectedNodes: selectedRows.toggledNodes,
        isSameTypeClients: totalCount === 0 || !isSameType,
      },
    }));

    if (!isSameType) differentCurrencyWarning();
  }, 500);

  return (
    <ServerSideDataGrid
      minHeight={322}
      height={height}
      ref={gridRef}
      getData={getClientServerSideData}
      getColumnConfig={getConfig}
      saveColumnConfig={saveColumnConfig}
      defaultColumnConfig={tableColumns}
      defaultColDef={K.AgGridTable.DefaultColDef}
      onDataFetchFailed={onDataFetchFailed}
      pagination
      paginationPageSize={25}
      cacheBlockSize={25}
      rowSelection="multiple"
      suppressRowClickSelection
      onFilterChanged={(event) => onFilterChanged(event.api, setHeight)}
      onPaginationChanged={(event) => onFilterChanged(event.api, setHeight)}
      onRowClicked={(event) => {
        onClientRowClicked(event, {
          id: pageStates.listing.id,
          name: pageStates.listing.name,
        });
      }}
      onDataFetchSuccess={onDataFetchSuccess}
      onSelectionChanged={onSelectionChanged}
      components={{
        PaginationSizeSelector: (props) => (
          <PaginationSizeSelector
            {...props}
            gridRef={gridRef}
            getData={getClientServerSideData}
          />
        ),
      }}
      statusBar={{
        statusPanels: [
          {
            statusPanel: "PaginationSizeSelector",
            align: "left",
          },
        ],
      }}
    />
  );
};
