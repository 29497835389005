import {
  Button,
  Card,
  Divider,
  Form,
  message,
  Modal,
  Spin,
  Typography,
} from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import BillingApproval from "redux/models/billingApproval";
import { selectSelectedClientIds } from "redux/slices/globalSelectorSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { isPermissionPresent } from "utilities/generalUtility";
import { displayDollar } from "utilities/tableUtility";
import BillingDetails from "./billingDetails";
import CreateInvoice from "./createInvoice";
import OfferedSalaryCalculations from "./offeredSalaryCalculation";
import "./submitToBilling.scss";
import "./billing-collapse.scss";
import { JobBillingsTable } from "./billingComponents/JobBillingsTable";
import { ClientBillingsTable } from "./billingComponents/ClientBillingsTable";
import { Collapse } from "../../../common/components/collapse/collapse";

const { Title } = Typography;
const {
  Invoice: { Type },
} = K;
const keys = {
  jobTotal: 0,
  clientTotal: 0,
  isSameTypeJobs: true,
  isSameTypeClients: true,
  selectedJobBillingRows: [],
  selectedClientBillingRows: [],
  selectedJobBillingRowsData: [],
  jobSelectAll: false,
  clientSelectAll: false,
  jobSelectedNodes: [],
  clientSelectedNodes: [],
  offerSalaryMissingRows: [],
};

export default function BillingComponent({ clientId }) {
  const inputRef = useRef(null);
  const invoicePayloadRef = useRef(null);
  const [form] = Form.useForm();
  //this form ref is used in offered Salary

  const [salaryForm] = Form.useForm();
  const [isConverting, setIsConverting] = useState(false);

  const [pageStates, setPageStates] = useState({
    listing: [],
    isEditMode: false,
    checkedRows: { ...keys },
    selectedClient: null,
    selectedRecord: null,
    isModalVisible: false,
    conversionHistory: [],
    isClientBillingRow: false,
    isDueDateModalVisible: false,
    isOfferedSalaryModalVisible: false,
    selectedClientIndex: null,
    jobLoading: false,
    clientLoading: false,
    updatedClientRow: null,
    updatedJobRow: null,
    shouldUpdae: false,
  });
  const selectedClientIds = useSelector(selectSelectedClientIds);
  const userSlice = useSelector(selectUser);

  const [rowData, setRowData] = useState([]);
  const [isOpen, setIsOpen] = useState(
    selectedClientIds[0] === clientId || userSlice.clients[0].id === clientId,
  );
  const [loading, setLoading] = useState(false);

  const toggleEdit = () => {
    setPageStates((prev) => ({ ...prev, isEditMode: !prev.isEditMode }));
  };

  const convert = async () => {
    const billingType = pageStates.isClientBillingRow ? Type.Client : Type.Job;
    const payload = {
      from: "CAD",
      to: "USD",
      amount: pageStates.selectedRecord.amount,
      billingApprovalId: pageStates.selectedRecord.id,
      type: billingType,
      edit: false,
      isIssueCredit:
        Math.sign(pageStates.selectedRecord.dublicateAmount) === -1 ? 1 : 0,
    };
    setIsConverting(true);

    try {
      const res = await BillingApproval.currencyConversion(payload);
      const history = await getBillHistory({
        type: billingType,
        billingId: res.id,
      });
      updateListing(res, history);
      message.success("Conversion Complete");
      setIsConverting(false);
    } catch (err) {
      setIsConverting(false);
      console.error(err);
    }
  };
  const editAmount = async () => {
    const payload = {
      edit: true,
      dublicateAmount: +inputRef.current.value,
      currency: pageStates.selectedRecord.convertToCurrency,
    };
    try {
      const res = pageStates.isClientBillingRow
        ? await BillingApproval.editClientSubmittedBilling({
            ...payload,
            clientSubmittedBillId: pageStates.selectedRecord.id,
          })
        : await BillingApproval.editJobSubmittedBilling({
            ...payload,
            jobSubmittedBillId: pageStates.selectedRecord.id,
          });
      const history = await getBillHistory({
        type: pageStates.isClientBillingRow ? Type.Client : Type.Job,
        billingId: res.id,
      });
      updateListing(res, history);
    } catch (err) {
      message.error("Failed to edit amount.");
      console.error(err);
    }
    toggleEdit();
  };

  const getBillHistory = async (payload) => {
    try {
      return await BillingApproval.getSubmittedBillHistory(payload);
    } catch (err) {
      console.error(err);
    }
  };

  const handleInvoice = (billType, index) => {
    if (pageStates.checkedRows.offerSalaryMissingRows > 0) {
      setPageStates((prev) => ({
        ...prev,
        isOfferedSalaryModalVisible: true,
        selectedClientIndex: index,
      }));
    } else {
      generateInvoice(billType, index);
    }
  };

  const generateInvoice = async (billType, index) => {
    invoicePayloadRef.current = {
      index,
      clientId,
      type: billType,
      submittalBillIds:
        Type.Client === billType
          ? pageStates.checkedRows.clientSelectedNodes
          : pageStates.checkedRows.jobSelectedNodes,
      selectAll:
        Type.Client === billType
          ? pageStates.checkedRows.clientSelectAll
          : pageStates.checkedRows.jobSelectAll,
    };

    form.resetFields();
    setPageStates((prev) => ({ ...prev, isDueDateModalVisible: true }));
  };

  const updateListing = (record, history) => {
    const updatedRecord = {
      ...record,
      duplicateAmount: record.dublicateAmount,
    };
    setPageStates((prev) => {
      const { ...rest } = prev;
      return {
        ...prev,
        updatedClientRow: rest.isClientBillingRow && updatedRecord,
        updatedJobRow: !rest.isClientBillingRow && updatedRecord,
        selectedRecord: updatedRecord,
        conversionHistory: history,
      };
    });
  };

  const reCalculateBilling = async (
    candidateJobEntryId,
    billingRuleEntryId,
    salary,
  ) => {
    try {
      await BillingApproval.submitReCalculateBilling(
        billingRuleEntryId,
        candidateJobEntryId,
        salary,
      );
      setPageStates((prev) => ({
        ...prev,
        jobLoading: true,
      }));

      const selectedJobRowsData = res[0].submittedJobBills.filter((dt) =>
        pageStates?.checkedRows.selectedJobBillingRows.includes(dt.id),
      );

      const selectedJobRows = selectedJobRowsData?.map(({ id }) => id) ?? [];

      const updatedCheckedRowsData = {
        ...pageStates?.checkedRows,
        selectedJobBillingRowsData: selectedJobRowsData,
        selectedJobBillingRows: selectedJobRows,
      };

      setPageStates((prev) => ({
        ...prev,
        listing: res[0],
        checkedRows: { ...updatedCheckedRowsData },
        jobLoading: false,
        isOfferedSalaryModalVisible: false,
      }));
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const onCancelOfferSalary = () => {
    const updatedRowData = rowData.map((item) => {
      return {
        ...item,
        enteredSalary: null,
        updatedBill: null,
      };
    });

    setRowData([...updatedRowData]);
  };

  const fetchClientTitle = () => {
    return userSlice.clients.find((item) => item.id === clientId).name;
  };

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Collapse
        defaultActiveKey={selectedClientIds[0] || userSlice.clients[0].id}
        expandIconPosition="right"
      >
        <Collapse.Panel
          header={fetchClientTitle()}
          key={clientId}
          className="billing-approval-header"
          onClick={toggleCollapse}
        >
          <Spin
            onClick={(e) => e.stopPropagation()}
            spinning={pageStates.clientLoading}
            className="billing-component"
          >
            <Card
              bodyStyle={{ padding: 0 }}
              headStyle={{ padding: 0 }}
              className={
                "clientBillingCard  table-pagination collapse-card " +
                LayoutCss.appCard
              }
              bordered={false}
              title="Client Billing"
              extra={
                <div className="clientBilingBtn">
                  <Title level={5} className="mb-0">
                    Total:&nbsp;
                    {displayDollar(pageStates.checkedRows?.clientTotal)}
                  </Title>

                  {isPermissionPresent(
                    K.Permissions.CreateInvoices,
                    userSlice.roles,
                  ) && (
                    <Button
                      type="primary"
                      className={LayoutCss.appHeaderBtn}
                      onClick={() => {
                        generateInvoice(Type.Client, pageStates.listing.id);
                      }}
                      disabled={pageStates.checkedRows?.isSameTypeClients}
                    >
                      Create Invoice
                    </Button>
                  )}
                </div>
              }
            >
              <ClientBillingsTable
                clientId={clientId}
                pageStates={pageStates}
                setPageStates={setPageStates}
              />
            </Card>
          </Spin>

          <Divider />

          <Spin
            onClick={(e) => e.stopPropagation()}
            spinning={pageStates.jobLoading}
            className="billing-component"
          >
            <Card
              bodyStyle={{ padding: 0 }}
              headStyle={{ padding: 0 }}
              title="Job Billing"
              extra={
                <div className="clientBilingBtn">
                  <Title className="mb-0" level={5}>
                    Total:&nbsp;
                    {displayDollar(pageStates.checkedRows?.jobTotal)}
                  </Title>
                  {isPermissionPresent(
                    K.Permissions.CreateInvoices,
                    userSlice.roles,
                  ) && (
                    <Button
                      type="primary"
                      className={LayoutCss.appHeaderBtn}
                      onClick={() =>
                        handleInvoice(Type.Job, pageStates?.listing.id)
                      }
                      disabled={pageStates.checkedRows?.isSameTypeJobs}
                    >
                      Create Invoice
                    </Button>
                  )}
                </div>
              }
              className={
                "clientBillingCard table-pagination collapse-card " +
                LayoutCss.appCard
              }
            >
              <JobBillingsTable
                clientId={clientId}
                pageStates={pageStates}
                setPageStates={setPageStates}
                setRowData={setRowData}
              />
            </Card>
          </Spin>
        </Collapse.Panel>
      </Collapse>

      <Modal
        title="Create Invoice"
        className="s2-theme-style"
        open={pageStates.isDueDateModalVisible}
        destroyOnClose={true}
        width={invoicePayloadRef.current?.type === Type.Client ? 500 : 1200}
        onOk={form.submit}
        okText={"Create Invoice"}
        okButtonProps={{ loading }}
        onCancel={() =>
          setPageStates((prev) => ({ ...prev, isDueDateModalVisible: false }))
        }
      >
        <CreateInvoice
          form={form}
          invoicePayloadRef={invoicePayloadRef}
          pageStates={pageStates}
          setLoading={setLoading}
          setPageStates={setPageStates}
        />
      </Modal>
      <Modal
        centered
        width={1200}
        className="clientBillingModal modal-overflow-auto"
        title={"WARNING: OFFERED SALARY MISSING"}
        footer={false}
        open={pageStates.isOfferedSalaryModalVisible}
        onOk={() =>
          setPageStates((prev) => ({
            ...prev,
            isOfferedSalaryModalVisible: false,
          }))
        }
        onCancel={() => {
          setPageStates((prev) => ({
            ...prev,
            isOfferedSalaryModalVisible: false,
          }));
          salaryForm.resetFields();
          onCancelOfferSalary();
        }}
        closeIcon={
          <span className="closeIcon">
            <i className="icon-closeable"></i>
          </span>
        }
      >
        <OfferedSalaryCalculations
          reCalculateBilling={reCalculateBilling}
          pageStates={pageStates}
          salaryForm={salaryForm}
          rowData={rowData}
          setRowData={setRowData}
          setPageStates={setPageStates}
        />
      </Modal>
      <Modal
        centered
        width={572}
        className="clientBillingModal modal-overflow-auto"
        title={pageStates.selectedClient?.name}
        footer={false}
        open={pageStates.isModalVisible}
        onOk={() =>
          setPageStates((prev) => ({ ...prev, isModalVisible: false }))
        }
        onCancel={() =>
          setPageStates((prev) => ({ ...prev, isModalVisible: false }))
        }
        closeIcon={
          <span className="closeIcon">
            <i className="icon-closeable"></i>
          </span>
        }
      >
        <BillingDetails
          convert={convert}
          isConverting={isConverting}
          pageStates={pageStates}
          inputRef={inputRef}
          editAmount={editAmount}
          toggleEdit={toggleEdit}
          duplicateAmount={pageStates.selectedRecord?.duplicateAmount}
        />
      </Modal>
    </>
  );
}
