import { AgGridReact } from "ag-grid-react";
import { Card, Input, message, PageHeader, Select } from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Job from "redux/models/job";
import User from "redux/models/user";
import { selectGlobalSelector } from "redux/slices/globalSelectorSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";

import { filterColumnListing } from "utilities/tableUtility";
import LookupTable from "redux/models/lookupTable";
import { selectConfigration } from "redux/slices/configrationSlice";
import { camelToSnakeCase } from "utilities/generalUtility";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";
import { Columns } from "./columns";
import { debounce } from "lodash";

const { Search } = Input;
const { Lookup } = K.Network.URL;
const sortDict = {
  asc: "ASC",
  desc: "DESC",
};

const searchFilterPrefix = "global_jobs";
const getSearchKey = "global_jobs_search";
const getFilterKey = "global_jobs_status";

export default function Jobs() {
  const gridRef = useRef();

  const [refreshTable, setRefreshTable] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const globalSelector = useSelector(selectGlobalSelector);
  const userDetails = useSelector(selectUser).details;
  const lookupTables = useSelector(selectConfigration).lookup;
  const [setter, getter] = useSearchAndFilter();

  const statusRef = useRef(getter(getFilterKey));
  const searchRef = useRef(getter(getSearchKey));

  const [columnDefs, setColumnDefs] = useState(Columns);
  const search = getter(getSearchKey);

  const onChange = useCallback(
    debounce((e) => {
      searchRef.current = e.target.value;
      setter({ search: e.target.value }, searchFilterPrefix);
      setRefreshTable((prev) => !prev);
    }, 500),
    [setter],
  );

  const onStatusChange = (newValue) => {
    statusRef.current = newValue;
    setter({ status: newValue }, searchFilterPrefix);
    setRefreshTable((prev) => !prev);
  };

  const rowClickedHandler = (event) => {
    const { id, clientsId } = event.data;
    history.push(`/clients/${clientsId}/job/${id}`);
  };

  const updateColumns = async (event) => {
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    if (shouldUpdate)
      try {
        await User.saveColumnSort({
          usersId: userDetails.id,
          tableName: K.AgGridTable.Keys.GlobalJobsVisibleColumn,
          configJson: JSON.stringify(cols),
        });
        message.success("Columns order saved successfully");
      } catch (err) {
        console.error(err);
      }
    else setShouldUpdate(true);
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userDetails.id,
        tableName: K.AgGridTable.Keys.GlobalJobsVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigurations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.GlobalJobsVisibleColumn,
      );
      const jsonRes = JSON.parse(res.config);
      const parsed = jsonRes.map((item) => {
        const column = Columns.find((i) => i.field === item.field);
        return { ...item, ...column };
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  const getLookupData = async () => {
    try {
      await dispatch(LookupTable.getData(K.Redux.JobStatus, Lookup.JobStatus));
    } catch (error) {
      console.error(error);
    }
  };
  const getJobsByClientIds = async (body) => {
    try {
      const res = await Job.getByClientIdsWithServerSide(body);

      const tableData = res.jobs.map((el) => {
        let searchRecruiters = "",
          searchHiringManagers = "";
        const jobRecruiters = el.jobRecruiters.map((obj) => {
          searchRecruiters += obj.recruiter.name;
          return obj.recruiter;
        });
        const jobHiringManagers = el.jobHiringManagers.map((obj) => {
          searchHiringManagers += obj.hiringManager.name;
          return obj.hiringManager;
        });
        return {
          id: el.id,
          title: el.title,
          clientsId: el.clientsId,
          currentOpenings: el.vacancies[0]?.currentOpenings ?? 0,
          jobLocation: el.jobLocation,
          jobDepartment: el.jobDepartment,
          recruitingStartDate: el.recruitingStartDate,
          jobRecruiters,
          jobStatus: el.jobStatus,
          jobHiringManagers,
          jobTier: el.jobTier.name,
          minPay: el.minPay,
          maxPay: el.maxPay,
          extAtsId: el.extAtsId,
          jobGroup: el.jobGroup,
          isWorkFromHome: el.isWorkFromHome,
          searchRecruiters,
          searchHiringManagers,
        };
      });
      return { jobs: tableData, totalCount: res.totalCount };
    } catch (err) {
      console.error(err);
    }
  };

  const datasource = useCallback(
    {
      async getRows(params) {
        const { startRow, endRow } = params.request;

        const pageSize = endRow - startRow;
        const page = startRow / pageSize + 1;

        const body = {
          page,
          pageSize,
          clientIds: globalSelector.selectedClients,
          search: getter(getSearchKey),
          filter: {
            jobStatusId:
              statusRef.current === undefined ? null : statusRef.current,
          },
          sortBy: camelToSnakeCase(params.request.sortModel[0]?.colId ?? "id"),
          sortOrder:
            params.request.sortModel.length > 0
              ? sortDict[params.request.sortModel[0]["sort"]]
              : "asc",
        };
        try {
          const data = await getJobsByClientIds(body);

          params.success({
            rowData: data.jobs,
            rowCount: data.totalCount,
          });
        } catch (err) {
          params.fail();
        }
      },
    },
    [refreshTable, globalSelector.selectedClients],
  );
  const onGridReady = (params) => {
    params.api.setServerSideDatasource(datasource);
  };

  useEffect(() => {
    (async () => {
      await Promise.all([getColumnsConfigurations(), getLookupData()]);
    })();
  }, [globalSelector.selectedClients]);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Jobs"
        className={LayoutCss.appPageHeader}
      />
      <Card
        className={`${LayoutCss.appCard} ${LayoutCss.rolesPermissionCard}`}
        extra={
          <div
            className={"candidateSearch " + LayoutCss.appListingCardRolesTable}
          >
            <Search
              allowClear
              placeholder="Search"
              defaultValue={search}
              onChange={onChange}
              className={LayoutCss.appListingCardRolesTableSearch}
            />

            <Select
              showArrow
              showSearch={true}
              allowClear
              value={getter(getFilterKey)}
              optionFilterProp="label"
              placeholder="Select Job Status"
              className={`commision-select ${LayoutCss.appListingCardStatusSelect1}`}
              options={lookupTables.jobStatus.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              style={{ width: 200 }}
              onChange={onStatusChange}
            />
          </div>
        }
      >
        <div
          className="ag-theme-alpine s2-theme-style"
          style={{
            height: 735,
          }}
        >
          <AgGridReact
            {...K.AgGridTable.DefaultProps}
            ref={gridRef}
            pagination={true}
            paginationPageSize={25}
            cacheBlockSize={25}
            onGridReady={onGridReady}
            rowModelType="serverSide"
            serverSideDatasource={datasource}
            columnDefs={columnDefs}
            onRowClicked={rowClickedHandler}
            onColumnMoved={updateColumns}
            defaultColDef={K.AgGridTable.DefaultColDef}
            onColumnPinned={onColumnVisible}
            quickFilterText={getter(getSearchKey)}
            onColumnVisible={onColumnVisible}
            loadingCellRenderer={false}
          />
        </div>
      </Card>
    </>
  );
}
