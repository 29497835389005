import { message, Tooltip } from "antd";
import { useState } from "react";
import K from "../../../../../../utilities/constants";
import Client from "../../../../../../redux/models/client";
import { EditJobModal } from "./editJobModal";
import { EditOutlined } from "@ant-design/icons";

export const EditJobButton = ({ clientId, job, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);

    values.recruitingStartDate = values.recruitingStartDate.format(
      K.DateFormat.ResponseWithTime,
    );

    const body = {
      ...values,
      jobSourceId: job.id,
      clientsId: clientId,
    };

    try {
      const res = await Client.editClientJobs(body);

      setOpen(false);
      onSuccess?.(res);
      message.success("Job Updated Successfully");
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title={"Edit"}>
        <EditOutlined
          onClick={() => setOpen(true)}
          style={{ color: "#1890ff" }}
        />
      </Tooltip>

      {open && (
        <EditJobModal
          clientId={clientId}
          job={job}
          open={open}
          loading={loading}
          onCancel={() => setOpen(false)}
          onFinish={onFinish}
        />
      )}
    </>
  );
};
