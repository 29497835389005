import { CloseOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Grid,
  Layout,
  Menu,
  Tooltip,
  Typography,
} from "antd";
import Logo from "assets/images/logo.svg";
import FavIcon from "assets/images/favicon.svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import User from "redux/models/user";
import { selectUser } from "redux/slices/userSlice";
import {
  hasAccessToRoute,
  isPermissionPresent,
} from "utilities/generalUtility";
import LayoutCss from "./layout.module.scss";
import navigations from "./navigations";
import styles from "./sider.module.scss";
import SiderPopover from "./siderPopover";

const { SubMenu } = Menu;
const { Title } = Typography;
const { useBreakpoint } = Grid;

export default function Sider({ collapsed, setCollapsed }) {
  const { Sider } = Layout;
  const histroy = useHistory();
  const location = useLocation();
  const screens = useBreakpoint();
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const [openKeys, setOpenKeys] = useState([location.pathname.split("/")[1]]);
  const userSlice = useSelector(selectUser);

  const onOpenChange = (keys) => {
    setOpenKeys([...keys]);
  };

  const toggleSider = () => {
    setCollapsed(!collapsed);
  };
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  const onBreakpoint = (broken) => {
    if (broken) {
      setCollapsedWidth(0);
    } else {
      setCollapsedWidth(80);
    }
  };

  const handleMenuClick = ({ key }) => {
    const actions = {
      1: () => histroy.push("/profile"),
      2: () => User.logoutCall(),
    };
    actions[key]();
  };
  const items = [
    {
      key: 1,
      label: "Profile",
    },
    {
      key: 2,
      label: "Log Out",
    },
  ];

  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={
          screens["lg"] === undefined || screens["lg"]
            ? `${LayoutCss.siderWrap} siderWrap`
            : `${LayoutCss.siderWrap}
              ${LayoutCss.sidebarFixed}
              ${styles.sidebarFixed}
              siderWrap sidebarFixed`
        }
        width={250}
        onCollapse={onCollapse}
        breakpoint={"lg"}
        onBreakpoint={onBreakpoint}
        collapsedWidth={collapsedWidth}
      >
        <Button
          shape="circle"
          size="small"
          className={styles.collapsedButton}
          onClick={toggleSider}
          icon={<i className="icon-arrow-right"></i>}
        />

        {screens["lg"] === undefined || screens["lg"] ? (
          <>
            <div className="ant-layout-sider-logo">
              <Link to={userSlice.homeRoute}>
                <img src={collapsed ? FavIcon : Logo} alt="" />
              </Link>
            </div>
            <SiderPopover />
          </>
        ) : (
          <Button
            size="large"
            type="primary"
            className={styles.siderCloseBtn}
            onClick={toggleSider}
          >
            <CloseOutlined />
          </Button>
        )}

        <Menu
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={["/" + location.pathname.split("/")[1]]}
          className={LayoutCss.siderMenu}
        >
          {navigations.map((navigation) => {
            const hasAccessToNavigation = hasAccessToRoute(
              navigation,
              userSlice.roles,
              userSlice.clients,
              userSlice.details.isSuperAdmin,
            );

            if (!hasAccessToNavigation) {
              return null;
            }

            if ((navigation.children?.length ?? 0) > 0) {
              const subMenuKey = navigation.children[0].path.split("/")[1];
              return (
                <SubMenu
                  key={subMenuKey}
                  icon={navigation.icon}
                  title={navigation.name}
                  className={LayoutCss.siderMenuHasSubMenu}
                >
                  {navigation.children.map((subNavigation) => {
                    const hasAccessToSubNavigation = hasAccessToRoute(
                      subNavigation,
                      userSlice.roles,
                      userSlice.clients,
                      userSlice.details.isSuperAdmin,
                    );

                    return hasAccessToSubNavigation ? (
                      <Menu.Item
                        key={subNavigation.path}
                        icon={subNavigation.icon}
                        className={
                          location.pathname.includes(subNavigation.path)
                            ? LayoutCss.activeMenuLink
                            : ""
                        }
                      >
                        <Link to={subNavigation.path}>
                          {subNavigation.name}
                        </Link>
                      </Menu.Item>
                    ) : null;
                  })}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item
                  key={navigation.path}
                  icon={navigation.icon}
                  className="menu-list-item"
                >
                  <Link to={navigation.path}>{navigation.name}</Link>
                </Menu.Item>
              );
            }
          })}
        </Menu>
        <Dropdown
          arrow
          trigger={["click"]}
          placement="top"
          menu={{ items, onClick: handleMenuClick }}
        >
          <div
            style={{ cursor: "pointer" }}
            className={styles.siderProfile + " siderProfile"}
          >
            <Avatar size={46} icon={<UserOutlined />} />
            <div>
              <Title level={5}>{userSlice.details?.name}</Title>
              <Tooltip title={userSlice.details?.email}>
                <p className="siderEllipsis">{userSlice.details?.email}</p>
              </Tooltip>
            </div>
            <DownOutlined style={{ fontSize: 14, color: "#fff" }} />
          </div>
        </Dropdown>
      </Sider>
      {screens["lg"] === undefined || screens["lg"] ? null : (
        <div className="open-sider-overlay" onClick={toggleSider}></div>
      )}
    </>
  );
}
