import JobForm from "../../jobForm";
import { Form, Modal } from "antd";

export const EditJobModal = ({
  clientId,
  job,
  open,
  loading,
  onCancel,
  onFinish,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      centered
      width={632}
      open={open}
      okText="Submit"
      onOk={form.submit}
      okButtonProps={{ loading }}
      onCancel={onCancel}
      closeIcon={<i className="icon-closeable"></i>}
      className="s2-theme-style modal-overflow-auto"
      title="Edit Job"
    >
      <JobForm
        form={form}
        editData={job}
        clientId={clientId}
        onFinish={onFinish}
      />
    </Modal>
  );
};
