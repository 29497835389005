import NetworkCall from "network/networkCall";
import Request from "network/request";
import {
  setSelectedClient,
  editClientData,
  addClientData,
  editClientDataInfo,
} from "redux/slices/clientSlice";
import {
  camelCaseKeys,
  camelCaseKeysRecursively,
} from "utilities/generalUtility";

export default class Client {
  static getById(clientId) {
    return async (dispatch, getState) => {
      const selectedClient = getState().clients.selectedClient;
      if (selectedClient?.id !== clientId) {
        const res = await NetworkCall.fetch(Request.getClientById(clientId));
        dispatch(setSelectedClient(camelCaseKeysRecursively(res.data)));
      }
    };
  }

  static editBillingUnit(body) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(
        Request.editClientBillingUnit(body),
        false,
      );
      dispatch(editClientData(camelCaseKeysRecursively(res.data)));
    };
  }
  static addBillingUnit(body) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(Request.addBillingUnit(body), false);
      dispatch(addClientData(camelCaseKeysRecursively(res.data)));
    };
  }

  static editClientInformation(body) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(Request.editClientInformation(body));
      dispatch(editClientDataInfo(camelCaseKeysRecursively(res.data)));
    };
  }

  static async create(data) {
    const res = await NetworkCall.fetch(Request.createClient(data));
    return res;
  }

  static async getClientJobs(clientId) {
    const res = await NetworkCall.fetch(Request.getClientJobsById(clientId));
    return camelCaseKeysRecursively(res.data);
  }

  static async getClientJobsByServerSidePagination(body) {
    const res = await NetworkCall.fetch(
      Request.getClientJobsWithServerSidePagination(body),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getJobBillingRules(clientId) {
    const res = await NetworkCall.fetch(
      Request.getJobBillingRulesById(clientId),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getClientBillingRules(clientId) {
    const res = await NetworkCall.fetch(
      Request.getClientBillingRulesById(clientId),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async addClientLevelBillingRules(body) {
    const res = await NetworkCall.fetch(
      Request.addClientLevelBillingRules(body),
      false,
    );
    return res;
  }

  static async addJobLevelBillingRules(body) {
    const res = await NetworkCall.fetch(
      Request.addJobLevelBillingRules(body),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async updateTierDetails(body) {
    const res = await NetworkCall.fetch(Request.updateTierDetails(body), false);
    return camelCaseKeysRecursively(res.data);
  }

  static async addTier(values) {
    const res = await NetworkCall.fetch(Request.addTier(values));
    return res;
  }

  static async addClientJobs(values) {
    const res = await NetworkCall.fetch(
      Request.addClientJobsById(values),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async editClientJobs(values) {
    const res = await NetworkCall.fetch(
      Request.editClientJobs({ ...values }),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async uploadCSV(body) {
    // const formData = new FormData();
    // formData.append("file", file);
    return await NetworkCall.fetch(Request.uploadCSV(body), false);
  }

  static async uploadCandidate(data) {
    return await NetworkCall.fetch(Request.uploadCSV(data), false);
  }

  static async getTiersById(clientId, loading = true) {
    const res = await NetworkCall.fetch(
      Request.getClientTiersById(clientId),
      loading,
    );
    return res.data.map((obj) => camelCaseKeys(obj));
  }

  static async getAllClientName(clientId) {
    const res = await NetworkCall.fetch(Request.getAllClientName());
    return res.data.map((obj) => camelCaseKeys(obj));
  }

  static async getDepartments() {
    const res = await NetworkCall.fetch(Request.getClientDepartments());
    return camelCaseKeysRecursively(res.data);
  }

  static async testBillingRules(values) {
    const res = await NetworkCall.fetch(Request.testClientBillingRules(values));
    return camelCaseKeysRecursively(res.data);
  }

  static async abortClientBillingRule(clientBillingRuleId) {
    const res = await NetworkCall.fetch(
      Request.abortClientBillingRule(clientBillingRuleId),
    );
    return camelCaseKeysRecursively(res);
  }

  static async abortJobBillingRule(jobBillingRuleId) {
    const res = await NetworkCall.fetch(
      Request.abortJobBillingRule(jobBillingRuleId),
    );
    return camelCaseKeysRecursively(res);
  }

  static async editUpcomingClientBillingRule(values) {
    const res = await NetworkCall.fetch(
      Request.editUpcomingClientBillingRule(values),
      false,
    );
    return res;
  }

  static async editUpcomingJobBillingRule(values) {
    const res = await NetworkCall.fetch(
      Request.editUpcomingJobBillingRule(values),
      false,
    );
    return res;
  }
  static async getRecruiterByClientsId(values, loader = true) {
    const res = await NetworkCall.fetch(
      Request.getRecruiterByClientsId(values),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async editBillingRuleStartDate(payload, loader = true) {
    await NetworkCall.fetch(
      Request.editClientBillingRuleStartDate(payload),
      loader,
    );
  }
  static async editExistigJobLevelBillingRuleDate(body) {
    const res = await NetworkCall.fetch(
      Request.editExistingJobLevelBillingRuleDate(body),
      false,
    );
    return camelCaseKeysRecursively(res.data);
  }
  static async createAttachment(body) {
    const res = await NetworkCall.fetch(Request.createAttachment(body), false);
    return camelCaseKeysRecursively(res.data);
  }
  static async getAttachment(body) {
    const res = await NetworkCall.fetch(Request.getAttachment(body), false);
    return camelCaseKeysRecursively(res.data);
  }
  static async uploadAttachement(file, folder) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("folderName", folder);
    const res = await NetworkCall.fetch(Request.uploadFile(formData), false);

    return camelCaseKeysRecursively(res.data);
  }
  static async getPreSignedUrl(url) {
    const res = await NetworkCall.fetch(Request.getPreSignedUrl(url), false);
    return camelCaseKeysRecursively(res);
  }

  static async deleteFileFromS3(url) {
    const res = await NetworkCall.fetch(Request.deleteFileFromS3(url), false);
    return camelCaseKeysRecursively(res);
  }
  static async deleteAttachement(id) {
    const res = await NetworkCall.fetch(Request.deleteAttachment(id), false);
    return camelCaseKeysRecursively(res);
  }

  static async addClientClass(body) {
    const res = await NetworkCall.fetch(Request.addClientClass(body), false);
    return camelCaseKeysRecursively(res);
  }

  static async editClientClass(body) {
    const res = await NetworkCall.fetch(Request.addClientClass(body), false);
    return camelCaseKeysRecursively(res);
  }

  static async getClientClassList(body) {
    const res = await NetworkCall.fetch(Request.getClientClassList(body));
    return camelCaseKeysRecursively(res.data);
  }

  static async getAllRecruiterForExcelUpload(body) {
    const res = await NetworkCall.fetch(
      Request.getAllRecruitersForExcelUpload(body),
    );
    return camelCaseKeysRecursively(res);
  }

  static async getAllClassForExcelUpload(body) {
    const res = await NetworkCall.fetch(
      Request.getAllClassForExcelUpload(body),
    );
    return camelCaseKeysRecursively(res);
  }

  static v2 = {
    get: async (params, useLoading = true) => {
      return NetworkCall.fetch(Request.v2.getClients(params), useLoading);
    },
  };
}
