import { ThunderboltTwoTone } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Spin,
  Tooltip,
  Upload,
} from "antd";
import UserSelectOption from "common/components/userSelect/userSelect";

import { lookupTablesForCandidates } from "config/lookupTables";
import DisplayUDF from "features/clients/details/userDefinedFields/displayUdf";
import { useEffect, useRef, useState } from "react";
import { PatternFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Candidate from "redux/models/candidate";
import LookupTable from "redux/models/lookupTable";
import { selectConfigration } from "redux/slices/configrationSlice";
import { selectUdf } from "redux/slices/udfSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import {
  arrayToOptions,
  customUserHandleSearch,
  generateUdfSetFieldValues,
  generateUdfValuesObject,
  getIsVisibleLookups,
  noTrailingSpaceAllowedRule,
  removeUdfKeysFromObject,
  snakeToCamel,
  validateMobileNumber,
} from "utilities/generalUtility";
import {
  editCandidateList,
  updateCandidateList,
} from "redux/slices/candidateSlice";
import { debounce } from "lodash";

const intialStatus = [
  { label: "Candidates - Needs Review", value: "Candidates-Needs Review" },
  { label: "Prospect - Sourced", value: "Prospect-Sourced" },
];

const hireTypes = arrayToOptions(K.CandidateType.getAll());

export default function AddJobCandidate({
  form,
  styles,
  addCandidateCancel,
  setIsLoading,
  candidateActivity,
  getCandidateUdf,
  isDataLoading,
  setIsDataLoading,
  ...props
}) {
  const { Option } = Select;

  const dispatch = useDispatch();
  const userSlice = useSelector(selectUser);
  const uploadFilePath = useRef(null);
  const { clientId, jobId } = useParams();
  const [isDisabled, setIsDisabled] = useState(false);
  const [userListing, setUserListing] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [blacklistReason, setBlacklistReason] = useState(null);

  const lookupTables = useSelector(selectConfigration).lookup;
  const udfSlice = useSelector(selectUdf);

  const editCandidateId = candidateActivity?.candidate?.id;
  const editCandidateEntryId = candidateActivity?.id;

  const isOfferedSalaryDisabled =
    editCandidateId && candidateActivity
      ? [
          K.CandidateStatus.Prospects,
          K.CandidateStatus.Candidates,
          K.CandidateStatus.S2Interview,
          K.CandidateStatus.Submitted,
          K.CandidateStatus.ClientInterview,
        ].includes(candidateActivity.status.name)
      : true;

  const beforeUpload = async (file, fileList) => {
    setIsUploading(true);
    try {
      const res = await Candidate.uploadCV(file, K.S3Folders.CV);
      uploadFilePath.current = res.filePath;
      setIsUploading(false);
    } catch (err) {
      message.error("Failed to upload candidate's Resume/CV!");
      console.error(err);
      setIsUploading(false);
    }
    return false;
  };

  const onRemove = async () => {
    try {
      await Candidate.deleteCV(uploadFilePath.current);
    } catch (err) {
      console.error(err);
    }
  };

  const onFinish = async (values) => {
    if (values.sourcedDate) {
      values.sourcedDate = values.sourcedDate.format(K.DateFormat.Response);
    } else if (values.applicationDate) {
      values.applicationDate = values.applicationDate.format(
        K.DateFormat.Response,
      );
    }
    values.notes = null;
    values.clientsId = +clientId;
    values.jobSourcesId = +jobId;

    const udfValues = generateUdfValuesObject(
      udfSlice[K.Redux.CandidateUdf],
      values,
    );
    const updatedValues = removeUdfKeysFromObject(
      values,
      udfSlice[K.Redux.CandidateUdf],
    );
    values = { ...updatedValues, udfValues: udfValues };

    const body = {
      ...values,
      uploadedCvPath: uploadFilePath.current,
      id: editCandidateId,
      candidateEntryId: editCandidateEntryId,
    };

    props?.onFinish(body);
  };

  const fetchCandidateData = async (email) => {
    try {
      const res = await Candidate.getByEmail(email, false);
      setBlacklistReason(res);
      if (Object.keys(res).length) {
        form.setFieldsValue(res);
        setIsDisabled(true);
      } else {
        form.resetFields([
          "firstName",
          "lastName",
          "location",
          "phoneNumber",
          "mobileNumber",
          "currentTitle",
        ]);
        setIsDisabled(false);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const formatter = (value) =>
    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const onChange = debounce(async () => {
    if (!editCandidateId) {
      const fieldName = "email";
      try {
        setIsDataLoading(true);
        const values = await form.validateFields([fieldName]);
        await fetchCandidateData(values[fieldName]);
      } catch (err) {
        console.error(err);
      } finally {
        setIsDataLoading(false);
      }
    }
  }, 500);

  const getJobRecruiters = async () => {
    try {
      const res = await Candidate.getJobRecruiterByJobId(jobId, false);
      setUserListing(res);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsDataLoading(true);
        await Promise.all([
          ...lookupTablesForCandidates.map(async (item) => {
            await dispatch(
              LookupTable.getData(
                item.reduxKey,
                item.apiEndPoint,
                [],
                false,
                false,
              ),
            );
          }),
          getJobRecruiters(),
          getCandidateUdf(editCandidateEntryId, false),
        ]);
      } catch (err) {
        console.error(err);
      } finally {
        setIsDataLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (candidateActivity) {
      const udfEditData = generateUdfSetFieldValues(
        udfSlice[K.Redux.CandidateUdf],
      );

      form.setFieldsValue({
        ...candidateActivity.candidate,
        salary: candidateActivity.salary,
        desireSalary: candidateActivity.desireSalary,
        position: candidateActivity.position,
        candidateStatusesId: candidateActivity.candidateStatusesId,
        candidateJobRecruiters: candidateActivity.candidateJobRecruiters.map(
          ({ id }) => id,
        ),
        interviewConfirmation: candidateActivity.interviewConfirmation
          ? true
          : false,
        atsLookupId: candidateActivity.atsLookupId,
        hireType: candidateActivity.hireType,
        sourceId: candidateActivity.sourcesId,
        ...udfEditData,
      });
    }
  }, []);

  const suggestAnEmail = async () => {
    // const res = await Candidate.suggestAnEmail(jobId);

    const randomNumber = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    const dummyEmail = `prospect.${Date.now()}.${randomNumber}.${
      userSlice.details?.id
    }@no-email.com`;

    form.setFieldsValue({ email: dummyEmail });
    onChange();
  };

  return (
    <Spin spinning={isDataLoading}>
      <Form
        form={form}
        disabled={isDataLoading}
        layout="vertical"
        initialValues={{ interviewConfirmation: false }}
        className={styles.addCandidateFrom}
        onFinish={onFinish}
      >
        {blacklistReason?.isBlacklist === 1 ? (
          <Alert
            showIcon
            type="warning"
            className="warningAlert"
            message="Warning: Candidate is blacklisted. "
            description={`Reason: ${
              blacklistReason.blacklistReason ?? K.NullPlaceholder
            } `}
          />
        ) : null}

        <Row gutter={28}>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className={styles.addCandidateLabel}
              label="Candidate Type"
              name="hireType"
              shouldUpdate={true}
              rules={[
                { required: true, message: "Candidate Type is required." },
              ]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                placeholder="Select Candidate Type"
                className={styles.addCandidateInputFields}
                options={hireTypes}
                disabled={editCandidateId && candidateActivity.statusId === 9}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.hireType !== curValues.hireType
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  className={styles.addCandidateLabel}
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Enter a valid email format!",
                    },
                    {
                      required: true,
                      message: "Candidate email is required!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    onChange={onChange}
                    addonAfter={
                      getFieldValue("hireType") &&
                      snakeToCamel(getFieldValue("hireType")) ===
                        K.CandidateHireType.SourceHired ? (
                        <Tooltip title="Autofill email for prospect candidate">
                          <ThunderboltTwoTone
                            style={{ cursor: "pointer" }}
                            onClick={suggestAnEmail}
                          />
                        </Tooltip>
                      ) : null
                    }
                    className={styles.addCandidateInputFields}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Divider className={styles.rowDivider} />
        <Row gutter={28}>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className={styles.addCandidateLabel}
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: "First Name is required." },
                noTrailingSpaceAllowedRule(),
              ]}
            >
              <Input
                disabled={isDisabled || isDataLoading}
                placeholder="Enter First Name"
                className={styles.addCandidateInputFields}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className={styles.addCandidateLabel}
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: "Last Name is required." },
                noTrailingSpaceAllowedRule(),
              ]}
            >
              <Input
                disabled={isDisabled || isDataLoading}
                placeholder="Enter Last Name"
                className={styles.addCandidateInputFields}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={28}>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className={styles.addCandidateLabel}
              label="Mobile Number"
              name="mobileNumber"
              rules={[
                {
                  validator: (_, value) => {
                    console.log("value", value);
                    if (value === "" || !value) {
                      return Promise.resolve();
                    } else if (validateMobileNumber(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Please enter correct number");
                    }
                  },
                },
              ]}
            >
              <PatternFormat
                disabled={isDisabled || isDataLoading}
                placeholder="Enter Mobile Number"
                customInput={Input}
                format={K.PhoneFormat.USNational}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className={styles.addCandidateLabel}
              label="Additional Phone"
              name="phoneNumber"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value || validateMobileNumber(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Please enter correct number");
                    }
                  },
                },
              ]}
            >
              <PatternFormat
                disabled={isDisabled || isDataLoading}
                placeholder="Enter Additional Phone"
                customInput={Input}
                format={K.PhoneFormat.USNational}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className={styles.addCandidateLabel}
              label="Home Address"
              name="location"
            >
              <Input
                disabled={isDisabled || isDataLoading}
                placeholder="Enter Home Address"
                className={styles.addCandidateInputFields}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className={styles.addCandidateLabel}
              label="Current Title"
              name="currentTitle"
              rules={[noTrailingSpaceAllowedRule()]}
            >
              <Input
                disabled={isDisabled || isDataLoading}
                placeholder="Enter Current Title"
                className={styles.addCandidateInputFields}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider className={`${styles.rowDivider} ${styles.rowDivideLine}`} />
        <Row gutter={28}>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className={styles.addCandidateLabel}
              label="Desired Salary"
              name="desireSalary"
            >
              <InputNumber
                placeholder="Enter Desired Salary"
                min={0}
                formatter={formatter}
                className={styles.addCandidateInputFields}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className={styles.addCandidateLabel}
              label="Offered Salary"
              name="salary"
            >
              <InputNumber
                placeholder="Enter Offered Salary"
                min={0}
                formatter={formatter}
                disabled={isOfferedSalaryDisabled || isDataLoading}
                className={styles.addCandidateInputFields}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={28}>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className={styles.addCandidateLabel}
              label="ATS Candidate ID"
              name="atsLookupId"
              rules={[noTrailingSpaceAllowedRule()]}
            >
              <Input placeholder="Enter ATS Candidate ID" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className={styles.addCandidateLabel}
              label="Candidate Status"
              name="candidateStatusesId"
              rules={[{ required: true, message: "Select Status" }]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                placeholder="Select Candidate Status"
                className={styles.addCandidateInputFields}
                options={getIsVisibleLookups(
                  lookupTables[K.Redux.CandidateStatus],
                  candidateActivity?.candidateStatusesId,
                ).map(({ id, name, isVisible }) => ({
                  value: id,
                  label: name,
                  disabled: isVisible === 1 ? false : true,
                }))}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={28}>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className={styles.addCandidateLabel}
              label="Source"
              name="sourceId"
              rules={[{ required: true, message: "Source is required." }]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                placeholder="Select Source"
                className={styles.addCandidateInputFields}
                options={getIsVisibleLookups(
                  lookupTables[K.Redux.Source],
                  editCandidateId ? candidateActivity?.sourcesId : null,
                ).map(({ id, name, isVisible }) => ({
                  value: id,
                  label: name,
                  disabled: isVisible === 1 ? false : true,
                }))}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" xs={24} sm={12}>
            <Form.Item
              className={styles.addCandidateLabel}
              label="Assigned Recruiter"
              name="candidateJobRecruiters"
              rules={[
                { required: true, message: "Select Candidate Job Recruiters" },
              ]}
            >
              <Select
                placeholder="Select Recruiters"
                showArrow
                allowClear
                optionLabelProp="title"
                showSearch={true}
                mode="multiple"
                filterOption={customUserHandleSearch}
                className={styles.addCandidateInputFields}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {userListing?.map(({ recruiter }) => (
                  <Option
                    value={recruiter?.id}
                    title={recruiter?.name}
                    key={recruiter?.id}
                  >
                    <UserSelectOption
                      userEmail={recruiter?.email}
                      clients={recruiter?.clients}
                    >
                      {recruiter?.name}
                    </UserSelectOption>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={28}>
          {!editCandidateId && (
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                className={styles.addCandidateLabel}
                label="Initial Status"
                name="initialStatus"
                rules={[
                  { required: true, message: "Initial Status is required." },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="label"
                  placeholder="Select Initial Status"
                  className={styles.addCandidateInputFields}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  options={intialStatus}
                />
              </Form.Item>
            </Col>
          )}

          {!editCandidateId ? (
            <Col className="gutter-row" xs={24} sm={12}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.initialStatus !== curValues.initialStatus
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("initialStatus") ===
                  "Candidates-Needs Review" ? (
                    <Form.Item
                      className={styles.addCandidateLabel}
                      label="Applied Date"
                      name="applicationDate"
                      rules={[
                        {
                          required: true,
                          message: "Applied Date is required.",
                        },
                      ]}
                    >
                      <DatePicker
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        className={styles.addCandidateInputFields}
                        format={K.DateFormat.DashUSFormat}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      className={styles.addCandidateLabel}
                      label="Sourced Date"
                      name="sourcedDate"
                      rules={[
                        {
                          required: true,
                          message: "Sourced Date is required.",
                        },
                      ]}
                    >
                      <DatePicker
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        className={styles.addCandidateInputFields}
                        format={K.DateFormat.DashUSFormat}
                      />
                    </Form.Item>
                  )
                }
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <Row gutter={28}>
          {udfSlice[K.Redux.CandidateUdf]?.map((item) => {
            return (
              <Col xs={24} sm={12}>
                <DisplayUDF
                  isFormItem={true}
                  title={item.label}
                  fieldTypeId={item.fieldTypeId}
                  udfId={item.id}
                  form={form}
                  isRequired={item.isRequired}
                  formItemName={item.label}
                  options={
                    item.udfMultiValueFieldTypeVal.length
                      ? item.udfMultiValueFieldTypeVal
                      : []
                  }
                />
              </Col>
            );
          })}
        </Row>
        <div className={styles.addNewCandidateCheckbox}>
          <Form.Item name="interviewConfirmation" valuePropName="checked">
            <Checkbox
              style={{ lineHeight: "32px" }}
              className={styles.jobFairCheckbox}
            >
              Interview Confirmation
            </Checkbox>
          </Form.Item>
          <Upload
            maxCount={1}
            defaultFileList={[]}
            onRemove={onRemove}
            beforeUpload={beforeUpload}
            className={styles.uploadCv}
            accept=".doc, .docx, .odt, .pdf"
          >
            <Button loading={isUploading} className={styles.uploadButton}>
              <i className={`icon-upload ${styles.uploadResume}`}></i>
              Attach Resume/CV
            </Button>
          </Upload>
        </div>
      </Form>
    </Spin>
  );
}
