class AgGridUtility {
  static onServerSideRowCreated(gridApi, row) {
    if (!gridApi) return;

    if (gridApi.getDisplayedRowCount() < gridApi.paginationGetPageSize()) {
      gridApi.applyServerSideTransaction({ add: [row] });
      return;
    }

    gridApi.setRowCount(gridApi.getModel().getRowCount() + 1);
  }

  static onServerSideRowUpdated(gridApi, row) {
    if (!gridApi) return;

    gridApi.applyServerSideTransaction({ update: [row] });

    gridApi.refreshCells({ force: true });
  }

  static onServerSideRowDeleted(gridApi, id) {
    if (!gridApi) return;

    gridApi.applyServerSideTransaction({
      remove: [{ id: id }],
    });
  }
}

export default AgGridUtility;
