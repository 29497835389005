import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  Form,
  Input,
  message,
  Skeleton,
  Space,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import Candidate from "redux/models/candidate";
import { formatCommentTime } from "utilities/dateUtility";
import { Scrollbars } from "react-custom-scrollbars-2";
import { EditCommentView } from "./EditCommentView/editCommentView";

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

export default function CandidateComments({
  styles,
  userData,
  candidateActivity,
}) {
  const [comments, setComments] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [selectedComment, setSelectedComment] = useState(null);

  const addCommentsOnCandidate = async () => {
    try {
      const res = await Candidate.addComment({
        userId: userData.id,
        candidateId: candidateActivity.id,
        comment: userInput,
      });
      setComments([...res.data]);
      setUserInput("");
      message.success("Comment added successfully!");
    } catch (err) {
      message.error("Failed to add comment!");
      console.error(err);
    }
  };

  const getComments = async () => {
    try {
      const res = await Candidate.getComments(candidateActivity.id);
      setComments(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getComments();
  }, [candidateActivity]);

  const onCommentUpdated = (comment) => {
    setComments((prev) => prev.map((c) => (c.id === comment.id ? comment : c)));
  };

  return (
    <>
      {!comments.length ? (
        <>
          <div className={styles.userCommentSkeleton}>
            <div className={styles.commentSkeletonWrap}>
              <Space className={styles.commentSkeleton}>
                <Skeleton.Avatar />
                <Skeleton.Input size="small" block />
                <Skeleton.Input size="small" block />
              </Space>
            </div>
            <div
              className={`${styles.commentSkeletonWrap} ${styles.secondComment}`}
            >
              <Space
                className={`${styles.commentSkeleton} ${styles.secondCommentSkeleton}`}
              >
                <Skeleton.Avatar />
                <Skeleton.Input size="small" block />
                <Skeleton.Input size="small" block />
              </Space>
            </div>
          </div>
          <Title level={5} className={styles.noComments}>
            No comments yet
          </Title>
        </>
      ) : (
        <>
          <Scrollbars style={{ height: 300 }}>
            {comments.map((item) =>
              selectedComment?.id !== item.id ? (
                <div key={item.id}>
                  <div className={styles.clientCommentDetail}>
                    <div className={styles.clientInfo}>
                      <Avatar icon={<UserOutlined />} />
                      <Title level={5}>
                        {item?.recrutor?.name || "Deleted user"}
                      </Title>
                      <span
                        className={styles.commentDate}
                        style={{ marginTop: 4 }}
                      >
                        {formatCommentTime(item.createdAt)}
                      </span>
                      {(userData.isSuperAdmin ||
                        item.userId === userData.id) && (
                        <Button
                          className={styles.editBtn}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "5px",
                          }}
                          type="link"
                          disabled={false}
                          onClick={() => setSelectedComment(item)}
                          icon={<i className="icon-edit"></i>}
                        />
                      )}
                    </div>
                  </div>
                  <Paragraph className={styles.clientComment}>
                    {item.comment}
                  </Paragraph>
                  {item.editedAt && (
                    <span
                      className={`${styles.commentDate} ${styles.editCommentDate}`}
                    >
                      Edited at {formatCommentTime(item.editedAt)} by{" "}
                      {item?.commentEditor?.name || "deleted user"}
                    </span>
                  )}
                  <Divider className={styles.divider} />
                </div>
              ) : (
                <EditCommentView
                  selectedComment={selectedComment}
                  userData={userData}
                  onUpdated={onCommentUpdated}
                  candidateActivity={candidateActivity}
                  setSelectedComment={setSelectedComment}
                />
              ),
            )}
          </Scrollbars>
        </>
      )}

      <Form.Item
        className={styles.commentSection}
        extra={
          <Button
            type="link"
            disabled={!userInput.length}
            className={styles.sendButton}
            onClick={addCommentsOnCandidate}
          >
            <i className={"icon-send " + styles.sendIcon}></i>
          </Button>
        }
      >
        <TextArea
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          disabled={candidateActivity.isConsiderable === 0}
          className={styles.addComments}
          placeholder="Add Comments"
          autoSize={{ minRows: 3, maxRows: 4 }}
          showCount
          maxLength={500}
        />
      </Form.Item>
    </>
  );
}
