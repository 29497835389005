import { valueFormatter } from "../../../../utilities/tableUtility";
import K from "../../../../utilities/constants";
import { Tag } from "antd";

const accountManagersToString = ({ data }) => {
  return data.accountManagers.map((i) => i.name).join(", ");
};

const accountMangersCellRenderer = ({ data }) => (
  <>
    {data.accountManagers.map((i) => (
      <Tag key={i.id} color="#0070C0">
        {i.name}
      </Tag>
    ))}
  </>
);

export const Columns = [
  {
    headerName: "ID",
    field: "id",
    suppressColumnsToolPanel: true,
  },
  {
    headerName: "Name",
    field: "name",
    sort: "asc",
    tooltipField: "name",
  },
  {
    headerName: "Corporate Address",
    field: "address",
    tooltipField: "address",
  },

  {
    headerName: "Client Service Leader",
    field: "serviceLeaderName",
    tooltipField: "serviceLeaderName",
    cellRenderer: ({ value }) => <Tag color="#0070C0">{value}</Tag>,
  },
  {
    headerName: "Account Manager",
    field: "accountManagers",
    sortable: false,
    valueGetter: accountManagersToString,
    tooltipValueGetter: accountManagersToString,
    cellRenderer: accountMangersCellRenderer,
  },
  {
    headerName: "ATS",
    field: "atsName",
    tooltipField: "atsName",
  },
  {
    headerName: "Stage",
    field: "stageName",
    tooltipField: "stageName",
  },
  {
    headerName: "Original Contract Date",
    field: "originalContractDate",
    tooltipField: "originalContractDate",
  },
];

export const DefaultColDef = {
  ...K.AgGridTable.DefaultColDef,
  valueFormatter: valueFormatter(),
  sortable: true,
};
