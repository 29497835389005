import { Button } from "antd";
import LayoutCss from "../../../layout/layout.module.scss";

export const columns = (onEdit) => [
  {
    headerName: "ID",
    field: "id",
    sortable: true,
    lockVisible: true,
  },
  {
    headerName: "Client",
    field: "clientName",
    sortable: true,
    tooltipField: "clientName",
  },
  {
    headerName: "Description",
    field: "title",
    tooltipField: "title",
    sortable: true,
    minWidth: 300,
  },
  {
    headerName: "Billing Type Name",
    field: "billingTypeName",
    tooltipField: "billingTypeName",
    sortable: true,
    minWidth: 200,
  },
  {
    headerName: "Currency",
    field: "currency",
    tooltipField: "currency",
    sortable: true,
  },
  {
    headerName: "Billed Amount",
    field: "duplicateAmount",
    tooltipField: "duplicateAmount",
    sortable: true,
  },
  {
    headerName: "Due Date",
    field: "dueDate",
    tooltipField: "dueDate",
    sortable: true,
  },
  {
    headerName: "Active?",
    field: "isActive",
    tooltipField: "isActive",
    sortable: true,
  },
  {
    headerName: "Repeat",
    field: "isInfinite",
    sortable: true,
    minWidth: 200,
    tooltipField: "isInfinite",
  },
  {
    headerName: "Actions",
    field: "actions",
    sortable: false,
    resizable: false,
    headerClass: "text-center",
    lockVisible: true,
    pinned: "right",
    suppressMovable: true,
    lockPinned: true,
    width: 150,
    cellStyle: { textAlign: "center" },
    cellRenderer: (params) => (
      <Button
        type="link"
        style={{ color: "#1890ff" }}
        className={LayoutCss.appListingCardActions}
        onClick={() => onEdit(params.data)}
      >
        Edit
      </Button>
    ),
  },
];
