import K from "../../../utilities/constants";
import { valueFormatter } from "../../../utilities/tableUtility";

export const Columns = [
  {
    headerName: "ID",
    field: "id",
    lockVisible: true,
  },
  {
    headerName: "First Name",
    field: "firstName",
    tooltipField: "firstName",
  },
  {
    headerName: "Last Name",
    field: "lastName",
    tooltipField: "lastName",
  },
  {
    headerName: "Email",
    field: "email",
    tooltipField: "email",
  },
  {
    headerName: "Mobile Number",
    field: "mobileNumber",
    tooltipField: "mobileNumber",
  },
  {
    headerName: "Candidate Type",
    field: "candidateType",
    sortable: false,
    tooltipField: "candidateType",
    headerTooltip: "Candidate Type",
  },
  {
    headerName: "Candidate ATS ID",
    field: "atsLookupId",
    sortable: false,
    tooltipField: "atsLookupId",
    headerTooltip: "Candidate ATS ID",
  },
  {
    headerName: "Current Title",
    field: "currentTitle",
    tooltipField: "currentTitle",
  },
  {
    headerName: "Blacklist ?",
    field: "isBlacklistDisplay",
    tooltipField: "isBlacklistDisplay",
  },
];

export const DefaultColDef = {
  ...K.AgGridTable.DefaultColDef,
  valueFormatter: valueFormatter(),
  sortable: true,
};
