import { Button, message } from "antd";
import { useState } from "react";
import styles from "../../jobList.module.scss";
import K from "../../../../../../utilities/constants";
import Client from "../../../../../../redux/models/client";
import { CreateJobModal } from "./createJobModal";

export const CreateJobButton = ({ clientId, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);

    values.clientsId = +clientId;
    values.recruitingStartDate = values.recruitingStartDate.format(
      K.DateFormat.ResponseWithTime,
    );
    values.isWorkFromHome = +values.isWorkFromHome;

    try {
      const res = await Client.addClientJobs(values);
      setOpen(false);
      onSuccess?.(res);
      message.success("Job Created Successfully");
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setOpen(true)}
        className={styles.jobListHeaderBtn}
      >
        Create Job
      </Button>

      {open && (
        <CreateJobModal
          clientId={clientId}
          open={open}
          loading={loading}
          onCancel={() => setOpen(false)}
          onFinish={onFinish}
        />
      )}
    </>
  );
};
