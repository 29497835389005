import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import NetworkCall from "network/networkCall";
import K from "utilities/constants";
import {
  camelCaseKeys,
  camelCaseKeysRecursively,
  redirectToLogin,
} from "utilities/generalUtility";
import Request from "network/request";
import { updateUserDetail } from "redux/slices/userSlice";
import * as Sentry from "@sentry/react";

export default class User {
  static async loginCall(email, password, remember) {
    const res = await NetworkCall.fetch(
      Request.loginUser(email, password),
      false,
    );
    const user = camelCaseKeys(res);
    let encryptedUser = CryptoJS.AES.encrypt(
      JSON.stringify({ apiToken: user.apiToken }),
      K.Cookie.Key.EncryptionKey,
    );
    Cookies.set(K.Cookie.Key.User, encryptedUser, {
      path: "/",
      domain: K.Network.URL.Client.BaseHost,
      expires: remember ? 365 : "",
    });
    return user;
  }

  static logoutCall(error = "") {
    Cookies.remove(K.Cookie.Key.User, {
      path: "/",
      domain: K.Network.URL.Client.BaseHost,
    });
    Sentry.setUser(null);
    redirectToLogin(error);
  }

  //Forgot password
  static async forgotPassword(email, clientHost) {
    const user = await NetworkCall.fetch(
      Request.forgotPassword(email, clientHost),
    );
    return user;
  }

  //Reset password
  static async resetPassword(newPassword, token, remember) {
    const res = await NetworkCall.fetch(
      Request.resetPassword(newPassword, token),
      true,
    );
    const user = camelCaseKeys(res);
    /*
    const encryptedUser = CryptoJS.AES.encrypt(
      JSON.stringify({ apiToken: user.apiToken }),
      K.Cookie.Key.EncryptionKey
    );
    Cookies.set(K.Cookie.Key.User, encryptedUser, {
      path: "/",
      domain: K.Network.URL.Client.BaseHost,
      expires: remember ? 365 : "",
    });
    */
    return user;
  }

  static async changePassword(encryptedPasswords) {
    return await NetworkCall.fetch(
      Request.changePassword(encryptedPasswords),
      false,
    );
  }

  static async getAll(data) {
    const res = await NetworkCall.fetch(Request.getAllUsers(data));
    return res.data.map((obj) => camelCaseKeys(obj));
  }

  static async getbyRole(roleId) {
    const users = await NetworkCall.fetch(Request.getUserByRoleId(roleId));
    return users;
  }

  static async getbyDesignation(desId) {
    const res = await NetworkCall.fetch(Request.getUserByDesignationId(desId));
    return camelCaseKeysRecursively(res.data);
  }

  static async createUser(data) {
    const res = await NetworkCall.fetch(Request.createUser(data));
    return res;
  }

  static async updateUser(data) {
    const res = await NetworkCall.fetch(Request.updateUser(data));
    return res;
  }
  static async getUserDetailById(id) {
    const res = await NetworkCall.fetch(Request.getUserDetailById(id));
    return camelCaseKeysRecursively(res.data);
  }

  static async handleUserActive(body) {
    const res = await NetworkCall.fetch(Request.handleUserActive(body));
    return camelCaseKeysRecursively(res.data);
  }
  static async getLoggedInUserDetails() {
    const res = await NetworkCall.fetch(Request.getLoggedInUserDetails());
    return camelCaseKeysRecursively(res.data);
  }

  static editProfile(data) {
    return async (dispatch) => {
      const res = await NetworkCall.fetch(Request.editUserProfile(data));
      dispatch(updateUserDetail(camelCaseKeysRecursively(res.data)));
    };
  }

  static async uploadImage(file, folder) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("folderName", folder);
    return await NetworkCall.fetch(Request.uploadFile(formData), false);
  }

  static async getLogs(body) {
    const res = await NetworkCall.fetch(Request.getLogs(body));
    return camelCaseKeysRecursively(res.data);
  }

  static async saveColumnSort(body) {
    return await NetworkCall.fetch(Request.saveTableColumnSorting(body), false);
  }

  static async saveTableVisibleColumn(body) {
    return await NetworkCall.fetch(Request.saveTableVisibleColumn(body), false);
  }

  static async getConfigrations(key) {
    const res = await NetworkCall.fetch(
      Request.getUserConfigrations(key),
      false,
    );
    return camelCaseKeys(res.data);
  }

  static async saveDashboardVisibleCharts(body) {
    return await NetworkCall.fetch(
      Request.saveDashboardVisibleCharts(body),
      false,
    );
  }

  static async getUserPlanTimeLine(id) {
    const res = await NetworkCall.fetch(Request.getUserPlanTimelines(id));
    return camelCaseKeysRecursively(res.data);
  }
  //Get Hiring manager
  static async getClientHiringManager(body, loader = true) {
    const res = await NetworkCall.fetch(
      Request.getClientHiringManager(body),
      loader,
    );
    return camelCaseKeysRecursively(res.data);
  }

  // Helpers
  static getUserObjectFromCookies() {
    let cookieUser = Cookies.get(K.Cookie.Key.User);
    let bytes = cookieUser
      ? CryptoJS.AES.decrypt(cookieUser, K.Cookie.Key.EncryptionKey)
      : "{}";
    try {
      let utfBytes = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(utfBytes);
    } catch (error) {
      console.error("error", error);
      return this.logoutCall("User unauthorized");
    }
  }

  static isTokenAvailable() {
    return this.getUserObjectFromCookies().apiToken ? true : false;
  }

  static getToken() {
    return this.getUserObjectFromCookies().apiToken ?? "";
  }

  static getId() {
    return this.getUserObjectFromCookies().id ?? "";
  }

  static getName() {
    return this.getUserObjectFromCookies().name ?? "";
  }

  static getEmail() {
    return this.getUserObjectFromCookies().email ?? "";
  }

  static async deleteUser(userId) {
    const res = await NetworkCall.fetch(Request.deleteUser(userId));
    return res;
  }

  //Get Class manager
  static async getClientClassManager(body, loader = true) {
    const res = await NetworkCall.fetch(
      Request.getClientClassManager(body),
      loader,
    );
    return camelCaseKeysRecursively(res.data);
  }
}
