import { displayDollar } from "../../utilities/tableUtility";
import { checkNullPlaceHolder } from "../../utilities/generalUtility";
import K from "../../utilities/constants";
import { Card, Divider, Tag, Typography } from "antd";

const { Title } = Typography;

const tagColors = {
  Prospect: "#D9D9D9",
  Candidates: "#F6A2E6",
  Submitted: "#BDD7EE",
  Offer: "#99FF99",
  Withdrew: "#F9B367",
  Declined: "#FF9999",
  Hired: "#00CC66",
  Terminated: "#FF9999",
  "S2 Interview": "#33CCFF",
  "Client Interview": "#CC99FF",
  "No Show to Day 1": "#FF9999",
  "Within Guarantee": "#FFB66D",
  "Within Retention Period": "#FFB66D",
};

const getSubStatusColor = (job) => {
  const colorHexValue =
    job.status.name === "Terminated"
      ? tagColors[job.subStatus.name]
      : tagColors[job.status.name];
  return colorHexValue;
};

export const CandidateJobEntryCard = ({
  jobEntry,
  isActive,
  onClick,
  styles,
}) => {
  return (
    <Card
      key={jobEntry.id}
      className={styles.jobsCardsWrapper}
      bodyStyle={{ width: "100%", background: isActive && "#f7f6ff" }}
      onClick={onClick}
    >
      <div className={styles.jobCardContentWrapper}>
        <div className={styles.jobWrapper}>
          <Title
            title={jobEntry.jobSource.title}
            level={3}
            className={`${styles.jobTitle} hover-underlined`}
          >
            {jobEntry.jobSource.title}
          </Title>

          <div className={styles.locationsList}>
            Client : {jobEntry.jobSource.client?.name}
          </div>
          <div className={styles.locationsList}>
            Location: {jobEntry.jobSource.jobLocation?.name}
          </div>
          <div className={styles.locationsList}>
            Job ATS ID: {checkNullPlaceHolder(jobEntry.jobSource.extAtsId)}
          </div>
          <div className={styles.locationsList}>
            <span className={`${styles.tagesStyle} ${styles.fullTime}`}>
              Desired Salary: {displayDollar(jobEntry.desireSalary)}
            </span>
          </div>
          <div className={styles.locationsList}>
            <span className={`${styles.tagesStyle} ${styles.fullTime}`}>
              Candidate Type:&nbsp;
              {checkNullPlaceHolder(K.CandidateType[jobEntry.hireType])}
            </span>
          </div>
          <div className={styles.tagesListWrapper}>
            <Tag
              className="tagTextColor"
              color={tagColors[jobEntry.status.name]}
            >
              {jobEntry.status.name}
            </Tag>
            <Divider type="vertical" />
            <Tag className="tagTextColor" color={getSubStatusColor(jobEntry)}>
              {jobEntry.subStatus.name}
            </Tag>
          </div>
        </div>
      </div>
    </Card>
  );
};
