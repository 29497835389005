import { Button, Form, Input, message } from "antd";
import { useState } from "react";
import Candidate from "../../../redux/models/candidate";
import styles from "./styles.module.scss";

export const EditCommentView = ({
  selectedComment,
  userData,
  candidateActivity,
  onUpdated,
  setSelectedComment,
}) => {
  const [comment, setComment] = useState(selectedComment.comment);

  const updateComment = async () => {
    try {
      const res = await Candidate.v2.editComment(selectedComment.id, {
        comment,
        updatedBy: userData.id,
      });

      onUpdated(res.data);
      setComment("");
      message.success(res.message);
    } catch (err) {
      console.error(err);
    } finally {
      setSelectedComment(null);
    }
  };

  return (
    <Form.Item
      className={styles.editContainer}
      extra={
        <div className={styles.buttonContainer}>
          <Button onClick={() => setSelectedComment(null)}>Cancel</Button>
          <Button
            className={"ant-btn ant-btn-primary"}
            disabled={comment === selectedComment?.comment || !comment.length}
            onClick={updateComment}
          >
            Save
          </Button>
        </div>
      }
    >
      <Input.TextArea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        disabled={candidateActivity.isConsiderable === 0}
        className={styles.addComments}
        placeholder="Edit Comment"
        autoSize={{ minRows: 3, maxRows: 4 }}
        showCount
        maxLength={500}
      />
    </Form.Item>
  );
};
