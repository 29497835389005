import { PageHeader, Space } from "antd";
import LayoutCss from "layout/layout.module.scss";
import { useSelector } from "react-redux";
import { selectAccessibleClientsBySelection } from "redux/slices/userSlice";
import "./submitToBilling.scss";
import BillingComponent from "./billingComponent";

export default function SubmitToBilling() {
  const clients = useSelector(selectAccessibleClientsBySelection);

  return (
    <>
      <PageHeader
        ghost={false}
        title="Billing Approval"
        className={`${LayoutCss.appPageHeader} ${LayoutCss.pageHeader}`}
      />
      <Space direction={"vertical"} style={{ width: "100%" }}>
        {clients.map(({ id }) => (
          <BillingComponent key={id} clientId={id} />
        ))}
      </Space>
    </>
  );
}
