import * as Sentry from "@sentry/react";
import K from "../utilities/constants";

export const initSentry = () => {
  Sentry.init({
    dsn: K.Sentry.Dsn,
    environment: K.Server,
    integrations: [
      Sentry.breadcrumbsIntegration({ console: false }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
      Sentry.browserTracingIntegration(),
    ],
    sampleRate: K.Sentry.SampleRate,
    tracesSampleRate: K.Sentry.TracesSampleRate,
    replaysSessionSampleRate: K.Sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: K.Sentry.replaysOnErrorSampleRate,
  });
};
