import { AgGridReact } from "ag-grid-react";
import { Button, Col, message, PageHeader, Row, Select, Space } from "antd";
import "assets/fonticons/style.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommissionPlan from "redux/models/commissionPlan";
import User from "redux/models/user";
import {
  selectGlobalSelector,
  setGlobalRecruiters,
} from "redux/slices/globalSelectorSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { convertIntoDashUSFormat } from "utilities/dateUtility";
import { removeUnderScore } from "utilities/generalUtility";
import { displayDollar, filterColumnListing } from "utilities/tableUtility";
import LayoutCss from "layout/layout.module.scss";
import { isEmpty } from "lodash";
import CommissionStatmentExport from "./commissionStatmentExport";
import { Collapse } from "../../../common/components/collapse/collapse";

export default function CommissionStatement() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userSlice = useSelector(selectUser);
  const globalSelector = useSelector(selectGlobalSelector);
  const userDetails = useSelector(selectUser).details;
  const [exportData, setExportData] = useState({
    isModalVisible: false,
  });

  const deliveryDateRenderer = (params) =>
    convertIntoDashUSFormat(params.value, false);

  const tooltipDeliveryDateRenderer = (params) => (
    <div className="client-list-tooltip">
      {convertIntoDashUSFormat(params.value, false)}
    </div>
  );

  const amountRenderer = (params) => displayDollar(+params.value);

  const tooltipAmountRenderer = (params) => (
    <div className="client-list-tooltip"> {displayDollar(+params.value)}</div>
  );

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      tooltipField: "status",
    },
    {
      headerName: "Due Date",
      field: "deliveryDate",
      sortable: true,
      tooltipField: "deliveryDate",
      tooltipComponent: tooltipDeliveryDateRenderer,
      cellRenderer: deliveryDateRenderer,
    },
    {
      headerName: "Total Billed Amount ",
      field: "thresholdMakerSum",
      sortable: true,
      resizable: false,
      tooltipField: "thresholdMakerSum",
      tooltipComponent: tooltipAmountRenderer,
      cellRenderer: amountRenderer,
    },
    {
      headerName: "Payout",
      field: "amount",
      sortable: true,
      resizable: false,
      tooltipField: "amount",
      tooltipComponent: tooltipAmountRenderer,
      cellRenderer: amountRenderer,
    },
  ]);
  const [pageStates, setPageStates] = useState({
    listing: [],
    activeKey: [],
  });

  const onChange = (key) => {
    setPageStates((prev) => ({
      ...prev,
      activeKey: key,
    }));
  };
  const onRecruiterChange = (value) => {
    dispatch(setGlobalRecruiters(value));
  };

  const rowClickedHandler = (params) => {
    history.push(`/billings/commission-statement/detail/${params.data.id}`);
  };

  const updateRowData = (commissionStatement) => {
    return commissionStatement.map((item) => ({
      ...item,
      status: removeUnderScore(item.status),
      amount: item.status === "NOT_ELIGIBLE" ? 0 : item.amount,
    }));
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const columnState = event.columnApi.getColumnState();
    setColumnDefs((prev) =>
      prev.map((el, i) => ({
        ...el,
        hide: columnState[i].hide,
        pinned: columnState[i].pinned,
      })),
    );
    const cols = filterColumnListing(columnDefs, columnState);
    try {
      await User.saveTableVisibleColumn({
        usersId: userDetails.id,
        configJson: JSON.stringify(cols),
        tableName: K.AgGridTable.Keys.CommissionStatementVisibleColumn,
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.CommissionStatementVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "deliveryDate")
          return {
            ...item,
            cellRenderer: deliveryDateRenderer,
            tooltipComponent: tooltipDeliveryDateRenderer,
          };
        if (["amount", "thresholdMakerSum"].includes(item.field))
          return {
            ...item,
            resizable: false,
            cellRenderer: amountRenderer,
            tooltipComponent: tooltipAmountRenderer,
          };
        return item;
      });
      setColumnDefs(parsed);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getColumnsConfigrations();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (globalSelector.selectedRecruiters.length === 0) return;
        const res = await CommissionPlan.getCommissionStatements(
          globalSelector.selectedRecruiters,
        );
        setPageStates((prev) => ({
          ...prev,
          listing: res,
          activeKey: [...Array(res.length)].map((_, i) => i),
        }));
      } catch (err) {
        console.error(err);
      }
    })();
  }, [globalSelector.selectedRecruiters]);

  return (
    <>
      <PageHeader
        className={`${LayoutCss.appPageHeader} ${LayoutCss.pageHeader}`}
        ghost={false}
        title="Commission Statement"
        extra={
          <>
            <Button
              className="hover-underlined"
              type="link"
              onClick={() =>
                setExportData((prev) => {
                  return { isModalVisible: true };
                })
              }
            >
              {" "}
              Export to CSV
            </Button>
            <Select
              showArrow
              optionFilterProp="label"
              mode="multiple"
              showSearch
              allowClear
              maxTagCount={1}
              maxTagPlaceholder={(omittedValues) => {
                return (
                  <span className="custom-max-tag-count">
                    +{omittedValues.length}
                  </span>
                );
              }}
              placeholder="Select Recruiters"
              className="commision-select"
              defaultValue={globalSelector.selectedRecruiters}
              options={userSlice.recruiters.map((item) => ({
                value: item.id,
                label: item.name,
                disabled:
                  globalSelector.selectedRecruiters.length >= 10 &&
                  !globalSelector.selectedRecruiters.includes(item.id),
              }))}
              onChange={onRecruiterChange}
            />
          </>
        }
      />
      {isEmpty(globalSelector.selectedRecruiters) ? (
        <Row justify="center" align="middle" style={{ marginTop: "100px" }}>
          <Col>
            <div style={{ fontSize: "20px", textAlign: "center" }}>
              Please select recruiters to view commission statements
            </div>
          </Col>
        </Row>
      ) : (
        <Space direction={"vertical"} style={{ width: "100%" }}>
          {pageStates.listing.map((item, i) => (
            <Collapse expandIconPosition="end" onChange={onChange} key={i}>
              <Collapse.Panel header={item.name} key={i}>
                <div
                  className="ag-theme-alpine s2-theme-style"
                  style={{
                    height: 370,
                  }}
                >
                  <AgGridReact
                    rowData={updateRowData(item.commissionStatements)}
                    columnDefs={columnDefs}
                    onRowClicked={rowClickedHandler}
                    defaultColDef={K.AgGridTable.DefaultColDef}
                    className={`commissionTable ${LayoutCss.appListingCardCommissionTable}`}
                    scroll={{ x: 400 }}
                    onColumnPinned={onColumnVisible}
                    onColumnVisible={onColumnVisible}
                    pagination
                    paginationPageSize={6}
                  />
                </div>
              </Collapse.Panel>
            </Collapse>
          ))}
        </Space>
      )}
      <CommissionStatmentExport
        exportData={exportData}
        setExportData={setExportData}
        globalSelectedRecruiter={globalSelector.selectedRecruiters}
      />
    </>
  );
}
