import { Button, Card, Col, Input, Row, Space } from "antd";
import { ServerSideDataGrid } from "../../../../common/components/serverSideDataGrid/serverSideDataGrid";
import { Columns, DefaultColDef } from "./columns";
import { useCallback } from "react";
import User from "../../../../redux/models/user";
import K from "../../../../utilities/constants";
import { useSelector } from "react-redux";
import { selectSelectedClientIds } from "../../../../redux/slices/globalSelectorSlice";
import {
  selectUserId,
  selectUserRoles,
} from "../../../../redux/slices/userSlice";
import useSearchAndFilter from "../../../../common/customHook/useSearchAndFilter";
import { debounce } from "lodash";
import Client from "../../../../redux/models/client";
import { Link, useHistory } from "react-router-dom";
import { isPermissionPresent } from "../../../../utilities/generalUtility";
import { PlusOutlined } from "@ant-design/icons";

const searchFilterPrefix = "global_clients";
const getSearchKey = "global_clients_search";

export const ClientsContainer = () => {
  const history = useHistory();

  const clients = useSelector(selectSelectedClientIds);
  const userId = useSelector(selectUserId);
  const userRoles = useSelector(selectUserRoles);

  const [setter, getter] = useSearchAndFilter();

  const search = getter(getSearchKey);

  const onSearchChange = useCallback(
    debounce((e) => {
      setter({ search: e.target.value || null }, searchFilterPrefix);
    }, 500),
    [setter],
  );

  const getServerSideData = useCallback(
    (options) => {
      return Client.v2.get({
        ...options,
        client: clients,
        search: search || undefined,
      });
    },
    [clients, search],
  );

  const getConfig = useCallback(() => {
    return User.getConfigrations(K.AgGridTable.Keys.ClientVisibleColumn);
  }, []);

  const saveColumnConfig = useCallback(
    (config) => {
      return User.saveColumnSort({
        usersId: userId,
        configJson: JSON.stringify(config),
        tableName: K.AgGridTable.Keys.ClientVisibleColumn,
      });
    },
    [userId],
  );

  const onRowClicked = (e) => {
    history.push(`/clients/${e.data.id}?tab=overview`);
  };

  return (
    <Card bodyStyle={{ padding: 16 }}>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Row justify={"space-between"} gutter={[8, 8]}>
          <Col style={{ flex: 1 }}>
            <Row gutter={[8, 8]}>
              <Col style={{ width: "100%", maxWidth: 264 }}>
                <Input.Search
                  allowClear
                  defaultValue={search}
                  placeholder="Search"
                  onChange={onSearchChange}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            {isPermissionPresent(K.Permissions.CreateClient, userRoles) && (
              <Link to="/clients/create">
                <Button
                  type="primary"
                  icon={<PlusOutlined style={{ fontSize: "0.8em" }} />}
                >
                  Client
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        <ServerSideDataGrid
          getData={getServerSideData}
          getColumnConfig={getConfig}
          saveColumnConfig={saveColumnConfig}
          defaultColumnConfig={Columns}
          defaultColDef={DefaultColDef}
          rowStyle={{ cursor: "pointer" }}
          onRowClicked={onRowClicked}
        />
      </Space>
    </Card>
  );
};
