import { Button, Card, Input, PageHeader, Select, message } from "antd";
import LookupTableComponent from "common/tableComponents/lookupTable";
import tabsConfig from "config/tabsConfig";
import LayoutCss from "layout/layout.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LookupTable from "redux/models/lookupTable";
import { selectConfigration } from "redux/slices/configrationSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { isPermissionPresent } from "utilities/generalUtility";
import { onFilterTextBoxChanged } from "utilities/tableUtility";
import LookupTableModal from "./lookupTableModal";
import { convertIntoDashUSFormat } from "utilities/dateUtility";
import useSearchAndFilter from "common/customHook/useSearchAndFilter";

const { Search } = Input;

export default function LookupTableWidget({ clientId = null }) {
  const gridRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const lookData = useSelector(selectConfigration).lookup;
  const userSlice = useSelector(selectUser);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] = useState({});

  let searchPrefix = `LookUp`;
  let getSearchKey = `LookUp_search`;

  const [setter, getter] = useSearchAndFilter();

  const actionRef = useRef({ isEditable: false });
  const isVisibleRender = (params) => {
    return params.data.isVisible === 0 ? "Yes" : "No";
  };
  const tooltipIsVisibleRender = (params) => (
    <div className="client-list-tooltip">
      {params.data.isVisible === 0 ? "Yes" : "No"}
    </div>
  );

  const userNameRender = (params) => {
    return params.data.userName ? params.data.userName : "N/A";
  };

  const tooltipUserNameRender = (params) => {
    return (
      <div className="client-list-tooltip">
        {params.data.userName ? params.data.userName : "N/A"}
      </div>
    );
  };

  const updatedAtRender = (params) => {
    return convertIntoDashUSFormat(params.data.updatedAt, true);
  };
  const tooltipUpdatedAtRender = (params) => (
    <div className="client-list-tooltip">
      {convertIntoDashUSFormat(params.data.updatedAt, true)}
    </div>
  );

  useEffect(() => {
    const currentKey = params.get("tab");
    if (!(currentKey in tabsConfig)) {
      history.push({ search: "?tab=source" });
    }
    currentKey && getLookupData(tabsConfig[currentKey]);
  }, [params.get("tab")]);

  const getLookupData = async (tab) => {
    try {
      await dispatch(LookupTable.getData(tab.reduxKey, tab.apiEndPoint));
    } catch (error) {
      console.error(error);
    }
  };

  const addNewRecord = () => {
    setEditData({});
    setIsModalVisible(true);
  };
  const editRecord = (params) => {
    setEditData(params.data);
    setIsModalVisible(true);
  };

  const actionColumnRenderer = (params) => (
    <>
      {isPermissionPresent(K.Permissions.EditLookup, userSlice.roles) && (
        <Button
          type="link"
          className={LayoutCss.appListingCardActions}
          onClick={() => editRecord(params)}
        >
          Edit
        </Button>
      )}
    </>
  );

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      tooltipField: "name",
      sort: "asc",
    },
    {
      headerName: "Archived",
      field: "isVisible",
      sortable: true,
      tooltipField: "isVisible",
      tooltipComponent: tooltipIsVisibleRender,
      cellRenderer: isVisibleRender,
    },
    {
      headerName: "Updated By",
      field: "userName",
      sortable: true,
      tooltipField: "userName",
      cellRenderer: userNameRender,
      tooltipComponent: tooltipUserNameRender,
    },

    {
      headerName: "Updated At",
      field: "updatedAt",
      sortable: true,
      tooltipField: "updatedAt",
      cellRenderer: updatedAtRender,
      tooltipComponent: tooltipUpdatedAtRender,
    },
    {
      headerName: "Actions",
      field: "actions",
      resizable: false,
      sortable: false,
      pinned: "right",
      suppressMovable: true,
      lockPinned: true,
      lockVisible: true,
      width: 150,
      headerClass: "text-center",
      cellStyle: { textAlign: "center" },
      cellRenderer: actionColumnRenderer,
    },
  ]);
  const onChange = (key) => {
    params.set("tab", key);
    history.push({ search: params.toString() });
  };

  const searchHandler = useCallback((event) => {
    onFilterTextBoxChanged(event.target.value, gridRef);
    setter({ search: event.target.value }, searchPrefix);
  }, []);

  return (
    <>
      <PageHeader
        ghost={false}
        className={"LookupTablePageHeader " + LayoutCss.appPageHeader}
        title="Lookup Table"
        extra={
          <Select
            showSearch
            allowClear
            optionFilterProp="label"
            onChange={onChange}
            value={params.get("tab")}
            options={Object.keys(tabsConfig).map((key) => ({
              value: key,
              label: tabsConfig[key].name,
            }))}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          />
        }
      />

      <Card
        title={tabsConfig[params.get("tab")]?.name}
        className={"appCard " + LayoutCss.appCard}
        extra={
          <div>
            {" "}
            <div className={LayoutCss.appListingCardJobTable}>
              <Search
                allowClear
                placeholder="Search"
                value={getter(getSearchKey)}
                onChange={searchHandler}
                className={LayoutCss.appListingCardSearchBar}
              />
              {isPermissionPresent(
                K.Permissions.CreateLookup,
                userSlice.roles,
              ) && (
                <Button
                  type="primary"
                  onClick={addNewRecord}
                  className={LayoutCss.appListingCardLookUpButton}
                >
                  Add New
                </Button>
              )}
            </div>
          </div>
        }
      >
        <LookupTableComponent
          rowData={lookData[tabsConfig[params.get("tab")]?.reduxKey]}
          columnDefs={columnDefs}
          setColumnDefs={setColumnDefs}
          actionColumnRenderer={actionColumnRenderer}
          gridRef={gridRef}
          actionRef={actionRef}
          isVisibleRender={isVisibleRender}
          isVisibleTooltipRender={tooltipIsVisibleRender}
          userNameRender={userNameRender}
          tooltipUserNameRender={tooltipUserNameRender}
          updatedAtRender={updatedAtRender}
          tooltipUpdatedAtRender={tooltipUpdatedAtRender}
          getSearch={getter(getSearchKey)}
        />
      </Card>
      <LookupTableModal
        isVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        editData={editData}
        setEditData={setEditData}
        title={tabsConfig[params.get("tab")]?.name}
      />
    </>
  );
}
