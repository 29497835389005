import { message } from "antd";
import BillingApproval from "../../../../redux/models/billingApproval";

export const calculateTableHeight = (bills) => {
  const count = bills ? Math.min(bills.length, 20) : 0;
  return count * 42 + 112;
};

export const onFilterChanged = (gridApi, setTableHeight) => {
  const tableRows = gridApi.getRenderedNodes().map((node) => node.data);
  setTableHeight((prev) => calculateTableHeight(tableRows));
};

export const getBillHistory = async (payload) => {
  try {
    return await BillingApproval.getSubmittedBillHistory(payload);
  } catch (err) {
    console.error(err);
  }
};

export const differentCurrencyWarning = () => {
  message.warning("Selected currency types should be same.");
};

export const sameMonthWarning = () => {
  message.warning(
    "Your client may be eligible for invoicing a volume-based discount. Please do not invoice until all hires are identified at the end of the month.",
  );
};
