import { message, Tooltip } from "antd";
import { useState } from "react";
import { DeleteJobModal } from "./deleteJobModal";
import Job from "../../../../../../redux/models/job";
import { DeleteOutlined } from "@ant-design/icons";

export const DeleteJobButton = ({ clientId, job, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    try {
      setLoading(true);

      await Job.deleteJob(job.id);

      setOpen(false);
      onSuccess?.();
      message.success("Job deleted successfully");
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title={"Delete"}>
        <DeleteOutlined
          style={{ color: "red" }}
          onClick={() => setOpen(true)}
        />
      </Tooltip>

      {open && (
        <DeleteJobModal
          clientId={clientId}
          job={job}
          open={open}
          loading={loading}
          onCancel={() => setOpen(false)}
          onFinish={onFinish}
        />
      )}
    </>
  );
};
