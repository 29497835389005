import { isPermissionPresent } from "../../../../../utilities/generalUtility";
import { Space, Tag } from "antd";
import {
  displayDollar,
  stringSorting,
  valueFormatter,
} from "../../../../../utilities/tableUtility";
import { convertIntoDashUSFormat } from "../../../../../utilities/dateUtility";
import K from "../../../../../utilities/constants";
import { tooltipTagRenderer } from "../../../../billingCommissions/submitToBilling/tableColumns";
import { EditJobButton } from "./editJobButton/editJobButton";
import { DeleteJobButton } from "./deleteJobButton/deleteJobButton";

const actionColumnRenderer =
  (clientId, roles, onJobUpdated, onJobDeleted) => (params) => (
    <Space>
      <EditJobButton
        clientId={clientId}
        job={params.data}
        onSuccess={onJobUpdated}
      />

      {isPermissionPresent(K.Permissions.DeleteJob, roles) && (
        <DeleteJobButton
          clientId={clientId}
          job={params.data}
          onSuccess={() => onJobDeleted(params.data)}
        />
      )}
    </Space>
  );

const payRenderer = (params) => displayDollar(params.value);
const changeFormat = (params) => convertIntoDashUSFormat(params.value, false);
const tagRenderer = (params, key, color) =>
  params.data[key].map((item, i) => (
    <Tag key={i} color={color}>
      {item.name}{" "}
    </Tag>
  ));
const jobStatus = (params) => (params.data.isCancel === 1 ? "Yes" : "No");
const workFormHome = (params) => (params.data.isWorkFromHome ? "Yes" : "No");
const atsId = (params) => params.data.extAtsId ?? K.NullPlaceholder;
const jobDepartment = (params) => params.data.jobDepartment?.name ?? "N/A";
const jobGroup = (params) => params.data.jobGroup?.name ?? "N/A";

const tooltipJobStatus = (params) => (
  <div className="client-list-tooltip">
    {params.data.isCancel === 1 ? "Yes" : "No"}
  </div>
);
const tooltipWorkFormHome = (params) => (
  <div className="client-list-tooltip">
    {params.data.isWorkFromHome === 1 ? "Yes" : "No"}
  </div>
);
const tooltipChangeFormat = (params) => (
  <div className="client-list-tooltip">
    {convertIntoDashUSFormat(params.value, false)}
  </div>
);
const tooltipPayRenderer = (params) => (
  <div className="client-list-tooltip"> {displayDollar(params.value)}</div>
);
const tooltipJobDepartment = (params) => (
  <div className="client-list-tooltip">
    {params.data.jobDepartment?.name ?? "N/A"}
  </div>
);
const tooltipJobGroup = (params) => (
  <div className="client-list-tooltip">
    {params.data.jobGroup?.name ?? "N/A"}
  </div>
);
const tooltipAtsId = (params) => {
  return (
    <div className="client-list-tooltip">
      {params.data.extAtsId ?? K.NullPlaceholder}
    </div>
  );
};

export const getColumns = (clientId, roles, onJobUpdated, onJobDeleted) => [
  {
    headerName: "ID",
    field: "id",
    sortable: true,
    sort: "asc",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    lockVisible: true,
  },
  {
    headerName: "Recruiter",
    field: "jobRecruiters",
    sortable: true,
    tooltipField: "jobRecruiters",
    tooltipComponent: (params) => tooltipTagRenderer(params, "jobRecruiters"),
    cellRenderer: (params) => tagRenderer(params, "jobRecruiters", "#0070C0"),
    valueGetter: (params) =>
      params.data.jobRecruiters.map((r) => r.name).join(", "),
  },
  {
    headerName: "Job Title",
    field: "title",
    sortable: true,
    tooltipField: "title",
  },
  //TODO:Need to change the total opening to current openings
  {
    headerName: "Current Openings",
    field: "totalOpenings",
    sortable: true,
    tooltipField: "totalOpenings",
  },
  {
    headerName: "Job Location",
    field: "jobLocation.name",
    sortable: true,
    tooltipField: "jobLocation.name",
  },
  {
    headerName: "Job Department",
    field: "jobDepartment",
    sortable: true,
    tooltipField: "jobDepartment",
    getQuickFilterText: jobDepartment,
    comparator: (a, b, nodeA, nodeB, isDescending) =>
      stringSorting(a, b, "name"),
    tooltipComponent: tooltipJobDepartment,
    cellRenderer: jobDepartment,
  },
  {
    headerName: "Job Group",
    field: "jobGroup",
    sortable: true,
    tooltipField: "jobGroup",
    getQuickFilterText: jobGroup,
    comparator: (a, b, nodeA, nodeB, isDescending) =>
      stringSorting(a, b, "name"),
    tooltipComponent: tooltipJobGroup,
    cellRenderer: jobGroup,
  },
  {
    headerName: "Job Hiring Manager",
    field: "jobHiringManagers",
    sortable: true,
    tooltipField: "jobHiringManagers",
    tooltipComponent: (params) =>
      tooltipTagRenderer(params, "jobHiringManagers"),
    cellRenderer: (params) =>
      tagRenderer(params, "jobHiringManagers", "#BDD7EE"),
    valueGetter: (params) =>
      params.data.jobHiringManagers.map((r) => r.name).join(", "),
  },
  {
    headerName: "Recruiting Start Date",
    field: "recruitingStartDate",
    sortable: true,
    suppressMovable: false,
    tooltipField: "recruitingStartDate",
    tooltipComponent: tooltipChangeFormat,
    cellRenderer: changeFormat,
  },
  {
    headerName: "Max Pay",
    field: "maxPay",
    sortable: true,
    suppressMovable: false,
    tooltipField: "maxPay",
    tooltipComponent: tooltipPayRenderer,
    cellRenderer: payRenderer,
  },
  {
    headerName: "Min Pay",
    field: "minPay",
    sortable: true,
    suppressMovable: false,
    tooltipField: "minPay",
    tooltipComponent: tooltipPayRenderer,
    cellRenderer: payRenderer,
  },
  {
    headerName: "Tier",
    field: "jobTier.name",
    sortable: true,
    tooltipField: "jobTier.name",
  },
  {
    headerName: "ATS ID",
    field: "extAtsId",
    sortable: true,
    tooltipField: "extAtsId",
    tooltipComponent: tooltipAtsId,
    cellRenderer: atsId,
  },
  {
    headerName: "Job Status",
    field: "jobStatus.name",
    sortable: true,
    tooltipField: "jobStatus.name",
  },
  {
    headerName: "Cancelled?",
    field: "isCancel",
    sortable: true,
    tooltipField: "isCancel",
    tooltipComponent: tooltipJobStatus,
    cellRenderer: jobStatus,
  },
  {
    headerName: "WFH?",
    field: "isWorkFromHome",
    sortable: true,
    tooltipField: "isWorkFromHome",
    tooltipComponent: tooltipWorkFormHome,
    cellRenderer: workFormHome,
  },
  {
    headerName: "Actions",
    field: "actions",
    lockVisible: true,
    pinned: "right",
    suppressMovable: true,
    lockPinned: true,
    width: 150,
    cellRenderer: actionColumnRenderer(
      clientId,
      roles,
      onJobUpdated,
      onJobDeleted,
    ),
  },
];

export const DefaultColDef = {
  ...K.AgGridTable.DefaultColDef,
  valueFormatter: valueFormatter(),
  sortable: true,
};
