import DeleteJob from "../../deleteJob";
import { Form, Modal } from "antd";
import { useState } from "react";

export const DeleteJobModal = ({
  clientId,
  job,
  open,
  loading,
  onCancel,
  ...props
}) => {
  const [form] = Form.useForm();

  const [modalState, setModalState] = useState({ deleteResponse: null });

  const onFinish = async () => {
    try {
      await props?.onFinish();
    } catch (e) {
      console.error(e);
      setModalState((prev) => ({ ...prev, deleteResponse: e.message }));
    }
  };

  return (
    <Modal
      centered
      width={568}
      okText="Delete Job"
      title="Are you sure?"
      open={open}
      closeIcon={<i className="icon-closeable"></i>}
      okButtonProps={{ loading }}
      className="s2-theme-style modal-overflow-auto"
      onOk={form.submit}
      onCancel={onCancel}
    >
      <DeleteJob
        form={form}
        clientId={clientId}
        modalState={modalState}
        record={job}
        deleteJob={onFinish}
      />
    </Modal>
  );
};
