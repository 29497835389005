import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Switch,
  Typography,
} from "antd";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import ScheduledBillings from "redux/models/scheduledBillings";
import K from "utilities/constants";
import { convertDateStringToMoment } from "utilities/dateUtility";
import moment from "moment";

const { Title } = Typography;

export default function ScheduledBilling({ candidateActivity, styles }) {
  const [form] = Form.useForm();
  const [rowData, setRowData] = useState([]);
  const [editData, setEditData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsModalVisible] = useState(false);

  const afterModalClose = () => {
    form.resetFields();
    setEditData({});
  };

  const getScheduledBilling = async () => {
    try {
      const res = await ScheduledBillings.getScheduledBillingByCandidateId(
        candidateActivity.id,
      );
      setRowData(res);
    } catch (err) {
      console.error(err);
    }
  };

  const editRecord = (data) => {
    setIsModalVisible(true);
    setEditData(data);
  };

  const onFinish = async (item) => {
    setIsLoading(true);
    const body = {
      dueDate: convertDateStringToMoment(item.dueDate).format(
        K.DateFormat.Response,
      ),
      duplicateAmount: item.duplicateAmount,
      isActive: item.isActive === false ? 0 : 1,
    };
    try {
      const res = await ScheduledBillings.updateJobScheduledBilling(
        body,
        editData.id,
      );
      const updatedRowData = rowData.map((item) => {
        return item.id === res.data.id ? res.data : item;
      });

      setIsLoading(false);
      setIsModalVisible(false);
      setRowData(updatedRowData);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        duplicateAmount: editData?.originalDuplicateAmount,
        dueDate: moment(editData?.dueDate),
        isActive: editData?.isActive === "Yes",
      });
    }
  }, [form, editData]);

  useEffect(() => {
    getScheduledBilling();
  }, [candidateActivity]);

  return (
    <>
      <Scrollbars style={{ height: 400 }}>
        {rowData.length ? (
          rowData.map((item, index) => (
            <div className={styles.invoiceWrap} key={index}>
              <Button
                className={styles.editBtn}
                type="link"
                onClick={() => editRecord(item)}
                icon={<i className="icon-edit"></i>}
              />
              <div className={styles.invoiceDetail}>
                <div className={styles.invoiceDate}>
                  <Title level={5}>
                    Due Date: <span>{item.dueDate} </span>
                  </Title>
                  <Title level={5}>
                    Currency: <span>{item.currency}</span>
                  </Title>
                </div>
                <Divider className={styles.invoiceDivider} />
                <div className={styles.invoiceDate}>
                  <Title title={item.billingTypeName} level={5}>
                    Billing Type: <span>{item.billingTypeName}</span>
                  </Title>
                  <Title level={5}>
                    Amount: <span>{item.duplicateAmount}</span>
                  </Title>
                </div>
                <Divider className={styles.invoiceDivider} />
                <div className={styles.invoiceDate}>
                  <Title title={item.candidateName} level={5}>
                    Candidate Name: <span>{item.candidateName}</span>
                  </Title>
                  <Title title={item.jobTitle} level={5}>
                    Job Title: <span> {item.jobTitle}</span>
                  </Title>
                </div>
                <Divider className={styles.invoiceDivider} />
                <div className={styles.invoiceDate}>
                  <Title title={item.isCommissionable} level={5}>
                    Commissionable? <span> {item.isCommissionable}</span>
                  </Title>
                  <Title title={item.isActive} level={5}>
                    Active? <span>{item.isActive} </span>
                  </Title>
                </div>
              </div>
            </div>
          ))
        ) : (
          <EmptyPlaceholder />
        )}
      </Scrollbars>

      <Modal
        forceRender
        centered
        open={isVisible}
        closeIcon={<i className="icon-closeable"></i>}
        okText="Update"
        className="s2-theme-style"
        title="Edit Scheduled Billing"
        wrapClassName="vertical-center-modal"
        okButtonProps={{ loading: isLoading }}
        onOk={() => form.submit()}
        onCancel={() => setIsModalVisible(false)}
        afterClose={afterModalClose}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Due Date"
            name="dueDate"
            rules={[{ required: true, message: "Due Date is required." }]}
          >
            <DatePicker
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              format={K.DateFormat.DashUSFormat}
            />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="duplicateAmount"
            rules={[{ required: true, message: "Amount is required." }]}
          >
            <Input placeholder="Enter amount" />
          </Form.Item>
          <Form.Item label="Active?" name="isActive" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
