import { Button } from "antd";
import ExportToCSV from "../../exportToCSV";
import { useState } from "react";

export const ExportButton = ({
  clientId,
  jobIds,
  jobStatus,
  disabled,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const onSuccess = () => {
    setOpen(false);
    props.onSuccess?.();
  };

  return (
    <>
      <Button type="primary" disabled={disabled} onClick={() => setOpen(true)}>
        Export
      </Button>

      {open && (
        <ExportToCSV
          open={open}
          jobIds={jobIds}
          clientId={clientId}
          jobStatus={jobStatus}
          onSuccess={onSuccess}
          onCancel={() => setOpen(false)}
        />
      )}
    </>
  );
};
