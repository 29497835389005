import { Button } from "antd";
import LayoutCss from "../../../layout/layout.module.scss";

export const columns = (onEdit) => [
  {
    headerName: "ID",
    field: "id",
    sortable: true,
    lockVisible: true,
  },
  {
    headerName: "Candidate Name",
    field: "candidateName",
    sortable: true,
    tooltipField: "candidateName",
  },
  {
    headerName: "Billing Type Name",
    field: "billingTypeName",
    sortable: true,
    tooltipField: "billingTypeName",
  },
  {
    headerName: "Job Title",
    field: "jobTitle",
    sortable: true,
    tooltipField: "jobTitle",
  },
  {
    headerName: "Currency",
    field: "currency",
    sortable: true,
    tooltipField: "currency",
  },
  {
    headerName: "Billed Amount",
    field: "duplicateAmount",
    sortable: true,
    tooltipField: "duplicateAmount",
  },
  {
    headerName: "Due Date",
    field: "dueDate",
    sortable: true,
    tooltipField: "dueDate",
  },
  {
    headerName: "Commissionable?",
    field: "isCommissionable",
    sortable: true,
    tooltipField: "isCommissionable",
  },
  {
    headerName: "Active?",
    field: "isActive",
    sortable: true,
    tooltipField: "isActive",
  },
  {
    headerName: "Repeat",
    field: "isInfinite",
    sortable: true,
    minWidth: 200,
    tooltipField: "isInfinite",
  },
  {
    headerName: "Actions",
    field: "actions",
    sortable: false,
    resizable: false,
    headerClass: "text-center",
    lockVisible: true,
    pinned: "right",
    suppressMovable: true,
    lockPinned: true,
    width: 150,
    cellStyle: { textAlign: "center" },
    cellRenderer: (params) => (
      <>
        <Button
          type="link"
          style={{ color: "#1890ff" }}
          className={LayoutCss.appListingCardActions}
          onClick={() => onEdit(params.data)}
        >
          Edit
        </Button>
      </>
    ),
  },
];
