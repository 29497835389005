import { Button, Divider, InputNumber, Typography } from "antd";
import {
  convertIntoDashUSFormat,
  convertIntoUTCDashUSFormat,
} from "utilities/dateUtility";
import { displayDollar, displayPercentage } from "utilities/tableUtility";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars-2";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";

const { Paragraph, Title } = Typography;

export default function BillingDetails({
  pageStates,
  toggleEdit,
  duplicateAmount,
  editAmount,
  isConverting,
  inputRef,
  convert,
}) {
  return (
    <>
      <span className="clientBillingType">
        {pageStates.selectedRecord?.billingTypeName}
      </span>
      <div className="clientBillingWrap">
        <div className="clientBillingTitle">
          <div className="clientBilling">
            <Title level={4}>ID:</Title>
            <Paragraph>{pageStates.selectedRecord?.id}</Paragraph>
          </div>
          {!pageStates.isClientBillingRow ? (
            <>
              <div className="clientBilling">
                <Title level={4}>Candidate Name:</Title>
                <Paragraph>
                  {pageStates.selectedRecord?.candidateName}
                </Paragraph>
              </div>
              <div className="clientBilling">
                <Title level={4}>Job Title:</Title>
                <Paragraph>{pageStates.selectedRecord?.jobTitle}</Paragraph>
              </div>
            </>
          ) : null}
          <div className="clientBilling">
            <Title level={4}>Create Date:</Title>
            <Paragraph>
              {pageStates.selectedRecord?.isManual
                ? convertIntoDashUSFormat(
                    pageStates.selectedRecord?.createdAt,
                    false,
                  )
                : convertIntoUTCDashUSFormat(
                    pageStates.selectedRecord?.createdAt,
                    false,
                  )}
            </Paragraph>
          </div>
          <div className="clientBilling">
            <Title level={4}>Original Amount:</Title>
            <Paragraph>
              {[
                displayDollar(+pageStates.selectedRecord?.amount.toFixed(2)),
                `(${pageStates.selectedRecord?.currency})`,
              ].join(" ")}
            </Paragraph>
          </div>
          <div className="clientBilling billed-amount">
            {pageStates.isEditMode ? (
              <>
                <div className="billingField">
                  <Title level={4} className="orignalAmount">
                    Billed Amount:
                  </Title>
                  <InputNumber ref={inputRef} defaultValue={duplicateAmount} />
                  <Button
                    type="link"
                    onClick={toggleEdit}
                    icon={<CloseCircleOutlined className="closeIcon" />}
                  />
                  <Button
                    type="link"
                    onClick={editAmount}
                    icon={<CheckCircleOutlined className="checkIcon" />}
                  />
                </div>
              </>
            ) : (
              <div>
                <Title level={4} className="orignalAmount">
                  Billed Amount:{" "}
                  <span className="cbOrignalAmount">
                    {[
                      displayDollar(+duplicateAmount.toFixed(2)),
                      `(${pageStates.selectedRecord?.convertToCurrency})`,
                    ].join(" ")}
                    {!pageStates.isReadOnly && (
                      <Button
                        type="link"
                        onClick={toggleEdit}
                        icon={<i className="icon-edit"></i>}
                      />
                    )}
                  </span>
                </Title>
              </div>
            )}
            <Button
              disabled={
                pageStates.selectedRecord?.convertToCurrency === "USD" ||
                !convert
              }
              type="primary"
              className="editAmountBtn"
              onClick={convert}
              loading={isConverting}
            >
              Convert Currency
            </Button>
          </div>
        </div>
      </div>

      {pageStates.selectedRecord?.issueCreditReason && (
        <div className="clientBillingTitle">
          <div className="">
            <Title level={5} className="clientBilling orignalAmount">
              Issue Credit Reason:
            </Title>
            <Paragraph className="clientBilling clientOrignalAmount">
              {pageStates.selectedRecord?.issueCreditReason}
            </Paragraph>
          </div>
        </div>
      )}

      <Divider className="divider" />
      <Title level={5} className="clientHistoryTitle">
        History
      </Title>
      <div className="clientHistoryWrap">
        <Scrollbars style={{ height: 460 }}>
          {pageStates.conversionHistory.length ? (
            pageStates.conversionHistory.map((item) => (
              <div key={item.id} className="clientHistoryDetail">
                <div className="clientAmountHistory">
                  <div className="clientName">
                    <Title level={5}>
                      {item.edit || item.discount >= 0
                        ? "Updated "
                        : "Converted "}
                      by:
                    </Title>
                    <Paragraph>{item.user.name}</Paragraph>
                  </div>
                  <div className="clientDate">
                    <Title level={5}>Updated at:</Title>
                    <Paragraph>
                      {convertIntoDashUSFormat(item.createdAt)}
                    </Paragraph>
                  </div>
                </div>
                <Divider className="historyDivider" />
                <div className="clientAmount">
                  <Title level={5}>
                    Billed Amount:{" "}
                    {displayDollar(item.billingAmount) + ` (${item.code})`}
                  </Title>
                  {!item.edit && item.conversionRate && (
                    <Paragraph>
                      Conversion Rate: {item.conversionRate?.toFixed(3)}
                    </Paragraph>
                  )}
                  {item.discount >= 0 && (
                    <>
                      <Paragraph>
                        Discount Rate:
                        {displayPercentage(item.discountPercentage)}
                      </Paragraph>
                      <Paragraph>
                        Discounted Amount: {displayDollar(item.discount)}
                      </Paragraph>
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <EmptyPlaceholder />
          )}
        </Scrollbars>
      </div>
    </>
  );
}
