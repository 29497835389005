import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  InputNumber,
  message,
  Row,
  Typography,
} from "antd";
import "./jobSettingsModal.scss";
import Job from "redux/models/job";
import Scrollbars from "react-custom-scrollbars-2";
import EmptyPlaceholder from "common/components/emptyPlaceholder/emptyPlaceholder";
import K from "utilities/constants";
import { customFormatSettingTime } from "utilities/dateUtility";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";

const { Paragraph, Title } = Typography;

export default function JobSettingModal({
  jobId,
  jobData,
  vacancies,
  setJobData,
  setModalVisible,
}) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const res = await Job.updateSettings({
        ...values,
        jobSourcesId: +jobId,
        jobOriginalOpenings: jobData.jobDetails.vacancies.totalOpenings,
      });

      setJobData((prev) => {
        console.log(prev);
        return {
          ...prev,
          jobDetails: {
            ...prev.jobDetails,
            vacancies: res.setting,
          },
          settings: {
            history: res.history,
            setting: res.setting,
          },
        };
      });

      setModalVisible((prev) => {
        return { ...prev, isSettingModalVisible: false };
      });

      form.resetFields();
      message.success("Settings Updated.");
    } catch (err) {
      console.error(err);
      message.error("Failed to update settings.");
    } finally {
      setLoading(false);
    }
  };

  const validateOpeningsToBill = (value) => {
    const diff =
      vacancies.setting.currentOpenings - form.getFieldValue("currentOpenings");
    if (value <= diff) {
      return Promise.resolve();
    }
    return Promise.reject(`Value should be less than or equal to ${diff}`);
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Card className="opening-card" loading={formLoading}>
        <div className="title-wrap">
          <h5 className="VacancyTitle">Openings</h5>
          <h5 level={5} className="total-opening">
            Current:{vacancies?.setting?.currentOpenings}
          </h5>
        </div>
        <Row gutter={16}>
          <Col xs={24} sm={14}>
            <Form.Item
              name="currentOpenings"
              rules={[
                {
                  required: true,
                  message: "Please enter number of current openings.",
                },
              ]}
            >
              <InputNumber
                className="vacancyNumberZero"
                placeholder="No of New Openings"
                min={0}
                disabled={!jobData.jobDetails?.vacancies?.isActive ?? true}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.currentOpenings !== curValues.currentOpenings
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("currentOpenings") <
                  vacancies?.setting?.currentOpenings && (
                  <Form.Item
                    label="How many orders are eligible for billing?"
                    name="jobCancelledOpenings"
                    initialValue={0}
                    dependencies={["currentOpenings"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter number of eligible orders.",
                      },
                      () => ({
                        validator(_, value) {
                          return validateOpeningsToBill(value);
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className="vacancyNumberZero"
                      placeholder="0"
                      min={0}
                    />
                  </Form.Item>
                )
              }
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="reasons"
              rules={[
                {
                  required: true,
                  message: "Please enter suitable reason.",
                },
              ]}
            >
              <TextArea
                placeholder="Reason"
                disabled={!jobData.jobDetails?.vacancies?.isActive ?? true}
                autoSize={{
                  minRows: 5,
                  maxRows: 6,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="add-position-wrap">
          <Button
            loading={loading}
            disabled={!jobData.jobDetails?.vacancies?.isActive ?? true}
            onClick={() => form.submit()}
          >
            Confirm
          </Button>
        </div>
      </Card>
      <Card className="opening-card" loading={formLoading}>
        <div className="title-wrap details-wrap">
          <h2 className="VacancyTitle">Opening History</h2>
        </div>

        <div className="settingHistoryWrap ">
          <Scrollbars style={{ height: 270 }}>
            {vacancies?.history?.length ? (
              vacancies.history.map((item, i, source) => (
                <div
                  key={item.id}
                  className="settingHistoryDetail history-detail-card"
                >
                  <div className="settingAmountHistory">
                    <div className="clientName history-detail">
                      <Title level={5}>Previous Openings:</Title>
                      <Paragraph>{item.previousOpening}</Paragraph>
                    </div>
                    <div className="clientName history-detail">
                      <Title level={5}>Current Openings:</Title>
                      <Paragraph>{item.currentOpenings}</Paragraph>
                    </div>
                  </div>

                  <div className="clientAmount history-detail">
                    <Title level={5} className="reason">
                      Reason:<span> {item.reasons ?? K.NullPlaceholder}</span>
                    </Title>
                  </div>
                  <Divider className="historyDivider" />
                  <div className="settingAmountHistory">
                    <div className="clientName history-detail">
                      <Title level={5}>Updated By:</Title>
                      <Paragraph>{item.user?.name}</Paragraph>
                    </div>
                    <div className="clientName history-detail">
                      <Title level={5}>Updated Date:</Title>
                      <Paragraph>
                        {" "}
                        {i === source.length - 1
                          ? customFormatSettingTime(item.openDate, false)
                          : customFormatSettingTime(item.createdAt, true)}
                      </Paragraph>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <EmptyPlaceholder />
            )}
          </Scrollbars>
        </div>
      </Card>
    </Form>
  );
}
