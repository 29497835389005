import { isNonProdEnvironment } from "../utilities/systemUtility";
import K from "../utilities/constants";
import { Alert } from "antd";

export const EnvironmentAlert = () => {
  if (!isNonProdEnvironment()) {
    return null;
  }

  let alertStyles = {};
  let msg = null;
  if (K.Server === K.Environment.STAGING) {
    msg = "Staging";
    alertStyles = {
      backgroundColor: "#3AC73A",
    };
  } else if (K.Server === K.Environment.QA) {
    msg = "QA";
    alertStyles = {
      backgroundColor: "#ff6600",
    };
  } else if (K.Server === K.Environment.DEVELOPMENT) {
    msg = "Development";
    alertStyles = {
      backgroundColor: "#00d9ff",
    };
  }

  return (
    <Alert
      message={msg}
      banner
      showIcon={false}
      className="env-header"
      style={alertStyles}
    />
  );
};
