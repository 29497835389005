import { AgGridReact } from "ag-grid-react";
import { Button, message, Modal, Tag } from "antd";
import { useForm } from "antd/es/form/Form";
import { tooltipTagRenderer } from "features/billingCommissions/submitToBilling/tableColumns";
import CreateClass from "features/clients/details/classes/createClass";
import JobForm from "features/clients/details/jobs/jobForm";
import DeleteJob from "features/clients/details/jobs/deleteJob";
import LayoutCss from "layout/layout.module.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Job from "redux/models/job";
import User from "redux/models/user";
import { selectConfigration } from "redux/slices/configrationSlice";
import { selectUser } from "redux/slices/userSlice";
import K from "utilities/constants";
import { convertIntoDashUSFormat } from "utilities/dateUtility";
import { isPermissionPresent } from "utilities/generalUtility";
import { filterColumnListing, stringSorting } from "utilities/tableUtility";

export default function ClassTable({
  rowData,
  gridRef,
  onRowClicked,
  setClientClass,
  setExportData,
  getClientClassList,
  doesExternalFilterPass,
  isExternalFilterPresent,
  getJobUdfs,
  getterSearch,
}) {
  const [form] = useForm();
  const [deleteForm] = useForm();
  const { id } = useParams();
  const userSlice = useSelector(selectUser);
  const userDetails = useSelector(selectUser).details;
  const [modalState, setModalState] = useState({
    editData: null,
    isCreateClassModalVisible: false,
    isDeleteClassModalVisible: false,
    deleteResponse: null,
    isUploadModalVisible: false,
    isLoadingClassModal: false,
    isLoadingUploadModal: false,
  });
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const lookupTables = useSelector(selectConfigration).lookup;

  const actionColumnRenderer = (params) =>
    isPermissionPresent(K.Permissions.EditClass, userSlice.roles) && (
      <div className="d-flex " style={{ justifyContent: "center" }}>
        <Button
          type="link"
          className={LayoutCss.appListingCardActions}
          onClick={(e) => {
            editRecord(params.data.id);
          }}
        >
          Edit
        </Button>
      </div>
    );

  const titleRenderer = (params) => params.data.title ?? "N/A";

  const classDateReceivedRenderer = (params) =>
    convertIntoDashUSFormat(params.data.classDateReceived, false);

  const classStartDateRenderer = (params) =>
    convertIntoDashUSFormat(params.data.classStartDate, false);

  const classSizeRenderer = (params) => params.data.classSize ?? 0;

  const notesRenderer = (params) => params.data.notes ?? "N/A";

  const singleTagRenderer = (params, key, color) =>
    params.data[key]?.name ? (
      <Tag color={color}>{params.data[key]?.name} </Tag>
    ) : (
      "N/A"
    );

  const singleTagSearchRenderer = (params, key, color) =>
    params.data[key]?.name ?? "N/A";

  const tooltipClassDateReceived = (params) => (
    <div className="client-list-tooltip">
      {convertIntoDashUSFormat(params.data.classDateReceived, false)}
    </div>
  );
  const tooltipClassStartDate = (params) => (
    <div className="client-list-tooltip">
      {convertIntoDashUSFormat(params.data.classStartDate, false)}
    </div>
  );

  const tooltipClassSize = (params) => (
    <div className="client-list-tooltip">{params.data.classSize ?? 0}</div>
  );

  const tooltipNotesRenderer = (params) => (
    <div className="client-list-tooltip">{params.data.notes ?? "N/A"}</div>
  );

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: "Class Title",
      field: "title",
      sortable: true,
      tooltipField: "title",
      cellRenderer: titleRenderer,
    },
    {
      headerName: "Class Status",
      field: "classStatus",
      sortable: true,
      tooltipField: "classStatus",
    },
    {
      headerName: "Class Date Received",
      field: "classDateReceived",
      sortable: true,
      tooltipField: "classDateReceived",
      cellRenderer: classDateReceivedRenderer,
      tooltipComponent: tooltipClassDateReceived,
    },
    {
      headerName: "Class Start Date",
      field: "classStartDate",
      sortable: true,
      tooltipField: "classStartDate",
      cellRenderer: classStartDateRenderer,
      tooltipComponent: tooltipClassStartDate,
    },
    {
      headerName: "Regional Director",
      field: "regionalDirector",
      sortable: true,
      tooltipField: "regionalDirector",
      getQuickFilterText: (params) =>
        singleTagSearchRenderer(params, "regionalDirector", "#0070C0"),
      comparator: (a, b, nodeA, nodeB, isDescending) =>
        stringSorting(a, b, "name"),
      cellRenderer: (params) =>
        singleTagRenderer(params, "regionalDirector", "#0070C0"),
      tooltipComponent: (params) => tooltipTagRenderer(params, null),
    },
    {
      headerName: "Human Resource Manager",
      field: "humanResource",
      sortable: true,
      tooltipField: "humanResource",
      getQuickFilterText: (params) =>
        singleTagSearchRenderer(params, "humanResource", "#0070C0"),
      comparator: (a, b, nodeA, nodeB, isDescending) =>
        stringSorting(a, b, "name"),
      cellRenderer: (params) =>
        singleTagRenderer(params, "humanResource", "#0070C0"),
      tooltipComponent: (params) => tooltipTagRenderer(params, null),
    },
    {
      headerName: "Internal Manager",
      field: "internalManager",
      sortable: true,
      tooltipField: "internalManager",
      getQuickFilterText: (params) =>
        singleTagSearchRenderer(params, "internalManager", "#0070C0"),
      comparator: (a, b, nodeA, nodeB, isDescending) =>
        stringSorting(a, b, "name"),
      cellRenderer: (params) =>
        singleTagRenderer(params, "internalManager", "#0070C0"),
      tooltipComponent: (params) => tooltipTagRenderer(params, null),
    },
    {
      headerName: "Current Openings",
      field: "classSize",
      sortable: true,
      tooltipField: "classSize",
      cellRenderer: classSizeRenderer,
      tooltipComponent: tooltipClassSize,
    },
    {
      headerName: "Notes",
      field: "notes",
      sortable: true,
      tooltipField: "notes",
      cellRenderer: notesRenderer,
      tooltipComponent: tooltipNotesRenderer,
    },

    ...(isPermissionPresent(K.Permissions.EditClass, userSlice.roles)
      ? [
          {
            headerName: "Actions",
            field: "actions",
            sortable: true,
            tooltipField: "actions",
            tooltipComponent: actionColumnRenderer,
            cellRenderer: actionColumnRenderer,
          },
        ]
      : []),
  ]);

  const editRecord = async (recordId) => {
    // await getJobUdfs(recordId);
    setModalState((prev) => {
      return {
        ...prev,
        isCreateClassModalVisible: true,
        editJobId: recordId,
      };
    });
  };

  // const deleteRecord = (recordId) => {
  //   setModalState((prev) => {
  //     return {
  //       ...prev,
  //       isDeleteClassModalVisible: true,
  //     };
  //   });
  // };

  const cancelHandler = () => {
    form.resetFields();
    setModalState((prev) => {
      return { ...prev, isCreateClassModalVisible: false };
    });
  };
  const deleteCancelHandler = () => {
    form.resetFields();
    setModalState((prev) => {
      return {
        ...prev,
        isDeleteClassModalVisible: false,
        deleteResponse: null,
      };
    });
  };

  const afterModalClose = () => {
    form.resetFields();
  };

  //?save cloumn Sorting
  const updateColumns = async (event) => {
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    if (shouldUpdate)
      try {
        await User.saveColumnSort({
          usersId: userDetails.id,
          tableName: K.AgGridTable.Keys.ClientClassVisibleColumn,
          configJson: JSON.stringify(cols),
        });
        message.success("Columns order saved successfully");
      } catch (err) {
        console.error(err);
      }
    else setShouldUpdate(true);
  };

  const onColumnVisible = async (event) => {
    if (event.source === "gridOptionsChanged") return;
    const cols = filterColumnListing(
      columnDefs,
      event.columnApi.getColumnState(),
    );
    try {
      await User.saveTableVisibleColumn({
        usersId: userDetails.id,
        tableName: K.AgGridTable.Keys.ClientClassVisibleColumn,
        configJson: JSON.stringify(cols),
      });
      message.success("Column configration saved");
    } catch (err) {
      console.error(err);
    }
  };

  //*this method is save row clicked data into te edit data and edit data is used in both delete and edit job
  const onCellClicked = (params) => {
    if (params.colDef.field !== "actions") {
      onRowClicked(params);
    } else {
      setModalState((prev) => {
        return {
          ...prev,
          editData: rowData.find((item) => item.id === params.data.id),
          isCreateClassModalVisible: true,
        };
      });
    }
    // if (params.colDef.field === "actions") {
    //   console.log(params);
    //   editRecord(params.data.id);
    // } else {
    //   onRowClicked(params);
    // }
  };

  const getColumnsConfigrations = async () => {
    try {
      const res = await User.getConfigrations(
        K.AgGridTable.Keys.ClientClassVisibleColumn,
      );
      const parsed = JSON.parse(res.config).map((item) => {
        if (item.field === "id")
          return {
            ...item,
          };
        else if (item.field === "classDateReceived")
          return {
            ...item,
            cellRenderer: classDateReceivedRenderer,
            tooltipComponent: tooltipClassDateReceived,
          };
        else if (item.field === "classStartDate")
          return {
            ...item,
            cellRenderer: classStartDateRenderer,
            tooltipComponent: tooltipClassStartDate,
          };
        else if (item.field === "internalManager")
          return {
            ...item,
            getQuickFilterText: (params) =>
              singleTagSearchRenderer(params, "internalManager", "#0070C0"),
            comparator: (a, b, nodeA, nodeB, isDescending) =>
              stringSorting(a, b, "name"),
            cellRenderer: (params) =>
              singleTagRenderer(params, "internalManager", "#0070C0"),
            tooltipComponent: (params) => tooltipTagRenderer(params, null),
          };
        else if (item.field === "humanResource")
          return {
            ...item,
            getQuickFilterText: (params) =>
              singleTagSearchRenderer(params, "humanResource", "#0070C0"),
            comparator: (a, b, nodeA, nodeB, isDescending) =>
              stringSorting(a, b, "name"),
            cellRenderer: (params) =>
              singleTagRenderer(params, "humanResource", "#0070C0"),
            tooltipComponent: (params) => tooltipTagRenderer(params, null),
          };
        else if (item.field === "regionalDirector")
          return {
            ...item,
            getQuickFilterText: (params) =>
              singleTagSearchRenderer(params, "regionalDirector", "#0070C0"),
            comparator: (a, b, nodeA, nodeB, isDescending) =>
              stringSorting(a, b, "name"),
            cellRenderer: (params) =>
              singleTagRenderer(params, "regionalDirector", "#0070C0"),
            tooltipComponent: (params) => tooltipTagRenderer(params, null),
          };
        // else if (item.field === "actions") {
        //   return {
        //     ...item,
        //     cellRenderer: actionColumnRenderer,
        //     tooltipComponent: actionColumnRenderer,
        //   };
        // }

        return item;
      });
      const actionColumn = isPermissionPresent(
        K.Permissions.EditClass,
        userSlice.roles,
      )
        ? [
            {
              headerName: "Actions",
              field: "actions",
              sortable: true,
              tooltipField: "actions",
              tooltipComponent: actionColumnRenderer,
              cellRenderer: actionColumnRenderer,
            },
          ]
        : [];

      setColumnDefs([...parsed, ...actionColumn]);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteJob = async () => {
    try {
      await Job.deleteJob(modalState.editData.id);

      setModalState((prev) => {
        return {
          ...prev,
          isDeleteClassModalVisible: false,
          deleteResponse: null,
        };
      });
      setClientClass((prev) =>
        prev.filter(({ id }) => id !== modalState.editData.id),
      );
      message.success("Class deleted successfully");
    } catch (error) {
      setModalState((prev) => {
        return {
          ...prev,
          deleteResponse: error.message,
        };
      });
      console.log(error);
    }
  };

  useEffect(() => {
    getColumnsConfigrations();
  }, []);

  return (
    <>
      <div
        className="ag-theme-alpine s2-theme-style"
        style={{
          height: 675,
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs.filter((item) => Object.keys(item).length > 0)}
          defaultColDef={K.AgGridTable.DefaultColDef}
          onColumnMoved={updateColumns}
          onCellClicked={onCellClicked}
          onColumnPinned={onColumnVisible}
          onColumnVisible={onColumnVisible}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          {...K.AgGridTable.DefaultProps}
          quickFilterText={getterSearch}
        />
      </div>

      {/* <Modal
        centered
        width={568}
        okText="Update"
        title="Edit Job"
        className="s2-theme-style modal-overflow-auto"
        wrapClassName="vertical-center-modal"
        open={modalState.isCreateJobModalVisible}
        closeIcon={<i className="icon-closeable"></i>}
        okButtonProps={{ loading: modalState.isLoadingJobModal }}
        onOk={form.submit}
        onCancel={cancelHandler}
        afterClose={afterModalClose}
        destroyOnClose
      >
        <JobForm
          form={form}
          clientId={id}
          lookupTables={lookupTables}
          editJobId={modalState.editJobId}
          editData={modalState.editData}
          setModalState={setModalState}
          setClientJobs={setClientJobs}
          getClientClassList={getClientList}
          getJobUdfs={getJobUdfs}
        />
      </Modal> */}

      <Modal
        centered
        width={632}
        open={modalState.isCreateClassModalVisible}
        okText="Update"
        title="Edit Class"
        onOk={form.submit}
        okButtonProps={{ loading: modalState.isLoadingClassModal }}
        onCancel={cancelHandler}
        closeIcon={<i className="icon-closeable"></i>}
        className="s2-theme-style jobsList modal-overflow-auto"
        afterClose={afterModalClose}
      >
        <CreateClass
          form={form}
          clientId={id}
          editData={{ ...modalState.editData }}
          lookupTables={lookupTables}
          setModalState={setModalState}
          getClientClassList={getClientClassList}
          // getJobUdfs={getJobUdfs}
        />
      </Modal>
      <Modal
        centered
        width={568}
        okText="Delete Job"
        title="Are you sure?"
        className="s2-theme-style modal-overflow-auto"
        wrapClassName="vertical-center-modal"
        open={modalState.isDeleteClassModalVisible}
        closeIcon={<i className="icon-closeable"></i>}
        okButtonProps={{
          loading: modalState.isLoadingJobModal,
        }}
        onOk={deleteForm.submit}
        onCancel={deleteCancelHandler}
        afterClose={afterModalClose}
        destroyOnClose
      >
        <DeleteJob
          form={deleteForm}
          clientId={id}
          modalState={modalState}
          record={modalState.editData}
          setModalState={setModalState}
          deleteJob={deleteJob}
        />
      </Modal>
    </>
  );
}
